import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { AdmPermissionDto, AdmUser } from 'src/app/data/models/adm';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { AdmUsersService } from 'src/app/services/core/adm-users.service';
import { CurrentUserService } from 'src/app/services/others/current-user.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { environment } from 'src/environments/environment';

/**
 * Navbar Component
 * @var  {AdmUser} user  AdmUser type varible to show the user information.
 * @var  {string}  role  variable is used to show the user role.
 * @var  {AdmPermissionDto} permissions variable used to display navigation menus based on user permissions
 * @var  {number} view variable used to filter the module to display - accepted values are listed in {@link PermissionsEnum}
 */

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  profile = environment.profile; 

  constructor(
    private currentAdmUser: CurrentUserService,
    private admPermissionService: AdmPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService
  ) {
  }

  user: AdmUser | undefined;
  initials = '';
  permissions !: AdmPermissionDto[];
  @Input() view !: number;
  isNavbarActive = false;
  isBackTwice: boolean = false;
  isNotFound: boolean = false;

  private admUserServiceSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.getMeProfile();
    this.getPermissionsView(this.view);

    this.isNotFound = false;
    this.isBackTwice = false;
    this.isNotFound = (this.route.routeConfig && this.route.routeConfig.path == '**') ?? false;
    if (!this.isNotFound) {
      const parentRout = this.route.routeConfig?.path === ''
        ? this.route.parent?.parent
        : this.route.parent;

      this.isBackTwice = this.checkRoute(parentRout ?? null, true);
    }
  }

    getPermissionsView(view: number) {
    let permissionsList = this.admPermissionService.getMyPermissions();
    for (const element of permissionsList) {
      if (element.internalId == view) {
        this.permissions = element.childrenPermissions;
        break;
      }
    }
  }

  return() {
    const url = this.router.url;
    if (url == '/home') {
      return;
    }

    if (this.isNotFound) {
      let urlRegex = url.match(/\/([\w_]+)/);
      const newUrl = urlRegex ? urlRegex[1] ?? 'home' : 'home';
      const exists = this.router.config.some(conf => {
        return conf.path == newUrl
          && (conf.component || conf.children?.some(conf1 => conf1.path == '' && conf1.component));
      });

      void this.router.navigate([exists ? newUrl : '/home']);
      return;
    }

    if (this.isBackTwice) {
      void this.router.navigate(['../../'], { relativeTo: this.route });
      return;
    }

    if (url.lastIndexOf('/') !== 0) {
      void this.router.navigate(['../'], { relativeTo: this.route });
    } else if (url !== '/home') {
      void this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    this.admUserServiceSubscription?.unsubscribe();
  }

  getMeProfile() {
    this.admUserServiceSubscription = this.currentAdmUser.currentData.subscribe(
      currentUser => {
        this.user = currentUser
        this.initials = this.currentAdmUser.getInitials(this.user);
      }
    );
  }

  showNavbar() {
    if (this.isNavbarActive) {
      this.isNavbarActive = false;
    } else {
      this.isNavbarActive = true
    }
  }

  get isHome() {
    return this.router.url == '/home';
  }

  checkRoute(currentRoute: ActivatedRoute | null, first: boolean): boolean {
    if (!currentRoute) {
      return false;
    }

    const config = currentRoute.snapshot.routeConfig;
    if (config && !config.component) {
      const child = (config.children ?? []).find(c => c.path == '');
      if (!child) {
        return this.router.url.lastIndexOf('/') != 0;
      }

      if (!child && first) {
        return this.checkRoute(currentRoute.parent, false);
      }
    }

    return false;
  }

  logout() {
    this.currentUserService.logout();
  }

}
