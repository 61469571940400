import { Component, Input } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-two-columns',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss']
})
export class TwoColumnsComponent {
  
  @Input()
  subtitle = '';
  profile = environment.profile;

}
