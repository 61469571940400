import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterLink } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ActionComponent } from 'src/app/app-commons/components/action/action.component';
import { BodyComponent } from 'src/app/app-commons/components/body/body.component';
import { DraggableTreeComponent } from 'src/app/app-commons/components/draggable-tree/draggable-tree.component';
import { EmptyPanelComponent } from 'src/app/app-commons/components/empty-panel/empty-panel.component';
import { ExportComponent } from 'src/app/app-commons/components/export/export.component';
import { FooterComponent } from 'src/app/app-commons/components/footer/footer.component';
import { HeaderComponent } from 'src/app/app-commons/components/header/header.component';
import { ImageSelectorWithCutComponent } from 'src/app/app-commons/components/image-selector-with-cut/image-selector-with-cut.component';
import { ImageSelectorComponent } from 'src/app/app-commons/components/image-selector/image-selector.component';
import { ListsComponent } from 'src/app/app-commons/components/lists/lists.component';
import { LoadingIndicatorComponent } from 'src/app/app-commons/components/loading-indicator/loading-indicator.component';
import { MaterialIconComponent } from 'src/app/app-commons/components/material-icon/material-icon.component';
import { ModalComponent } from 'src/app/app-commons/components/modal/modal.component';
import { NavbarComponent } from 'src/app/app-commons/components/navbar/navbar.component';
import { NotFoundComponent } from 'src/app/app-commons/components/not-found/not-found.component';
import { PaginationComponent } from 'src/app/app-commons/components/pagination/pagination.component';
import { PasswordStrengthComponent } from 'src/app/app-commons/components/password-strength/password-strength.component';
import { PickListComponent } from 'src/app/app-commons/components/pick-list/pick-list.component';
import { RequiredComponent } from 'src/app/app-commons/components/required/required.component';
import { SearchFilterComponent } from 'src/app/app-commons/components/search-filter/search-filter.component';
import { StatusBadgeComponent } from 'src/app/app-commons/components/status-badge/status-badge.component';
import { ToSelectComponent } from 'src/app/app-commons/components/to-select/to-select.component';
import { ToasterComponent } from 'src/app/app-commons/components/toaster/toaster.component';
import { TopBarComponent } from 'src/app/app-commons/components/top-bar/top-bar.component';
import { ViewFieldComponent } from 'src/app/app-commons/components/view-field/view-field.component';
import { ViewListsComponent } from 'src/app/app-commons/components/view-lists/view-lists.component';
import { TwoColumnsComponent } from 'src/app/app-commons/layouts/two-columns/two-columns.component';
import { ConfirmComponent } from 'src/app/app-commons/modals/confirm/confirm.component';
import { CheckPermissionDirective } from 'src/app/app-commons/security/check-permission.directive';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ActiveInactiveSwitchComponent } from './components/active-inactive-switch/active-inactive-switch.component';
import { AlertListComponent } from './components/alert-list/alert-list.component';
import { ApprovalListComponent } from './components/approval-list/approval-list.component';
import { ApprovalViewComponent } from './components/approval-view/approval-view.component';
import { MapComponent } from './components/map/map.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { NgVar } from './directives/ng-var.directive';
import { DecimalCustomPipe } from './components/pipe/decimal-custom.pipe';
import { WebcamComponent } from './components/webcam/webcam.component';
import { WebcamModule } from 'ngx-webcam';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CardPanelComponent } from './components/card-panel/card-panel.component';
import { MultiImageSelectorComponent } from './components/multi-image-selector/multi-image-selector.component';
import { LocationMapComponent } from './modals/location-map/location-map.component';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';

@NgModule({
  declarations: [
    ActionComponent,
    BodyComponent,
    CheckPermissionDirective,
    InputTrimDirective,
    NgVar,
    ConfirmComponent,
    DraggableTreeComponent,
    EmptyPanelComponent,
    ExportComponent,
    FooterComponent,
    HeaderComponent,
    ImageSelectorComponent,
    ImageSelectorWithCutComponent,
    ListsComponent,
    LoadingIndicatorComponent,
    MaterialIconComponent,
    ModalComponent,
    NavbarComponent,
    PaginationComponent,
    PasswordStrengthComponent,
    PickListComponent,
    RequiredComponent,
    SearchFilterComponent,
    StatusBadgeComponent,
    ToSelectComponent,
    ToasterComponent,
    TopBarComponent,
    NotFoundComponent,
    ViewFieldComponent,
    ViewListsComponent,
    TwoColumnsComponent,
    ApprovalListComponent,
    ApprovalViewComponent,
    MapComponent,
    LocationMapComponent,
    AlertListComponent,
    DecimalCustomPipe,
    WebcamComponent,
    SignaturePadComponent,
    CardPanelComponent,
    MultiImageSelectorComponent,
    ActiveInactiveSwitchComponent,
    CurrencyFormatterDirective
  ],
  exports: [
    ActionComponent,
    BodyComponent,
    ConfirmComponent,
    CheckPermissionDirective,
    CurrencyFormatterDirective,
    InputTrimDirective,
    NgVar,
    DraggableTreeComponent,
    EmptyPanelComponent,
    ExportComponent,
    FooterComponent,
    HeaderComponent,
    ImageSelectorComponent,
    ImageSelectorComponent,
    ImageSelectorWithCutComponent,
    ListsComponent,
    LoadingIndicatorComponent,
    MaterialIconComponent,
    ModalComponent,
    NavbarComponent,
    PaginationComponent,
    PasswordStrengthComponent,
    PickListComponent,
    RequiredComponent,
    SearchFilterComponent,
    StatusBadgeComponent,
    ToSelectComponent,
    ToasterComponent,
    TopBarComponent,
    ViewFieldComponent,
    ViewListsComponent,
    NotFoundComponent,
    TwoColumnsComponent,
    ApprovalListComponent,
    ApprovalViewComponent,
    MapComponent,
    LocationMapComponent,
    AlertListComponent,
    DecimalCustomPipe,
    WebcamComponent,
    SignaturePadComponent,
    CardPanelComponent,
    MultiImageSelectorComponent,
    ActiveInactiveSwitchComponent,
    CardPanelComponent
  ],
  imports: [
    TranslocoRootModule,
    CommonModule,
    FormsModule,
    ImageCropperModule,
    NgbModule,
    NgSelectModule,
    UiSwitchModule,
    RouterLink,
    DragDropModule,
    DigitOnlyModule,
    BreadcrumbModule,
    CdkTreeModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    WebcamModule
  ]
})
export class AppCommonsModule {
}
