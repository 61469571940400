import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(
    protected modalService: NgbModal
  ) { }

  @Input() backdrop: boolean | 'static' = true;
  @Input() modalTitle = 'Nuevo modal';
  @Input() modalSize = 'lg';
  @Input() acceptBtnText = 'accept'
  @Input() modalScrollable = true;
  @Input() modalCentered = false;
  @Input() showCancelButton = true;
  @Input() showAcceptButton = true;
  @Input() showExtraButton = false;
  @Input() extraBtnTxt = '';
  @Input() valid = true;
  @Input() setModalTitle: string | undefined

  @Input() cancelBtnTxt = 'cancel'

  @ViewChild('content') content!: ElementRef;
  closeResult = '';
  @Output() modalCallback = new EventEmitter<boolean>();
  modalRef!: NgbModalRef;

  entity: any;
  @Output() modalCallbackWithEntity = new EventEmitter<any>();

  @Output() extraBtnEvent = new EventEmitter<any>();

  cancel(): void {
    this.modalCallback.emit(false);
    this.close();
  }

  accept(): void {
    if(this.valid){
      this.modalCallbackWithEntity.emit(this.entity);
      this.modalCallback.emit(true);
      this.close();
    }
  }

  open(): void {
    this.modalRef = this.modalService.open(this.content, { size: this.modalSize, scrollable: this.modalScrollable, centered: this.modalCentered, backdrop: this.backdrop });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openWithEntity(entity: any, customModalTitle?: string): void {
    if(customModalTitle){
      this.modalTitle = customModalTitle
    }
    this.entity = entity
    this.modalRef = this.modalService.open(this.content, { size: this.modalSize, scrollable: this.modalScrollable, centered: this.modalCentered, backdrop: this.backdrop });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  protected close(): void {
    this.modalRef.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  extraAction(){
    this.extraBtnEvent.emit();
    this.close();
  }

}
