export const TypologiesEnum = {
    STATUS: 48,
        ACTIVE: 49,
        INACTIVE: 50,
        DELETE: 51,
        LOCKED: 300,
        IN_PROCESS_OF_AUTHORIZATION: 301,
        REJECTED: 302,
        DRAFT: 2172,
    POST_TYPE: 1400,
      PT_EVENT: 1401,
      PT_NEWS: 1402,
    TAX_REGIME : 1008,
    SOCIETY_TYPE : 1054,
    PAYMENT_METHOD_TYPE:1095,
    COUNTRY : 52,
    GT_COUNTRY:142,
    ORG_CONTACT_TYPE : 1235,
    ORG_CONTACT_PURPOSE:1250,
    DAYS : 978,
    TIMEZONE: 350,
    GENDER: 22,
    PERSON_CONTACT_TYPE: 1265,
      PCT_TELEPHONE:1266,
    PERSON_CONTACT_PURPOSE: 1280,
      PCP_EMERGENCY:1282,
    PERSON_ADDRESS_TYPE:1220,
    PERSONAL_TITLE:43,
      PAT_FISCAL_ADDRESS:1228,
    TP_SPACE: 1360,
      PST_ADMINISTRATIVE: 1361,
      PST_OPERATIVE: 1362,
    PROVIDER_PERMISSIONS: 1600,
      PP_CAN_HAVE_BRANCHES:1601,
      PP_CAN_HAVE_MORE_THAN_ONE_PROFESSIONAL:1602,
    PROVIDER_FIELD: 1510,
      PF_GENERAL: 1975,
        PF_STATUS: 1976,
      PF_GENERAL_ORGANIZATION: 1696,
        PF_SOCIETY_TYPE:1511,
        PF_TRADE_NAME:1512,
        PF_SOCIAL_REASON:1513,
        PF_CONSTITUTION_DATE:1514,
        PF_COUNTRY:1515,
        PF_LEGAL_REPRESENTATIVE:1516,
        PF_LEGAL_REPRESENTATIVE_NIT:1517,
        PF_CONTACT_PERSON:1518,
        PF_APPLY_WITHHOLDING:1519,
        PF_EXEMPT_FROM_IVA:1520,
        PF_TAX_IDENTIFIER:1521,
        PF_TAX_REGIME:1522,
        PF_FISCAL_ADDRESS:1523,
        PF_STATE:1524,
        PF_CITY:1525,
        PF_ZIP_CODE:1526,
        PF_MEDIA_CONTACT_LIST:1527,
        PF_PAYMENT_METHOD:1528,
        PF_PAYMENT_COINS:1529,
        PF_PAYMENT_CONDITIONS:1530,
        PF_BANKS_LIST:1531,
        PF_FRANCHISE:1532,
        PF_CATEGORY:1533,
        PF_PRICES_LIST:1534,
        PF_PRODUCTS_OR_SERVICES:1535,
        PF_ATTACHMENTS_LIST:1536,
        PF_COORDINATES:1559,
      APPOINTMENT_STATUS: 1603,
        AS_ASSIGNED:1604,
        AS_PENDING_INVOICE:1605,
        AS_INVOICED:1606,
        AS_UNCOMPLETED:1609,
      PF_GENERAL_PERSON: 1697,
        PF_PERSON_APPLY_WITHHOLDING:1620,
        PF_PERSON_EXEMPT_FROM_IVA:1621,
        PF_PERSON_TAX_IDENTIFIER:1622,
        PF_PERSON_TAX_REGIME:1623,
        PF_PERSON_FISCAL_ADDRESS:1624,
        PF_PERSON_CITY:1625,
        PF_PERSON_STATE:1644,
        PF_PERSON_ZIP_CODE:1626,
        PF_PERSON_MEDIA_CONTACT_LIST:1627,
        PF_PERSON_PAYMENT_METHOD:1628,
        PF_PERSON_PAYMENT_COINS:1629,
        PF_PERSON_PAYMENT_CONDITIONS:1630,
        PF_PERSON_PROVIDER_BANK_ACCOUNT_LIST:1631,
        PF_PERSON_CATEGORY:1632,
        PF_PERSON_PRICES_LIST:1633,
        PF_PERSON_PRODUCTS_OR_SERVICES:1634,
        PF_PERSON_ATTACHMENTS_LIST:1635,
        PF_PERSON_COORDINATES:1560,
        PF_PERSON_TITLE:1636,
        PF_PERSON_FIRST_NAME:1637,
        PF_PERSON_SECOND_NAME:1638,
        PF_PERSON_SURNAMES:1639,
        PF_PERSON_MARRIED_NAME:1640,
        PF_PERSON_PARTNER_NAME:1641,
        PF_PERSON_CONTACT_PERSON:1642,
        PF_PERSON_OBSERVATIONS:1643,
      PF_UM_ORGANIZATION: 1698,
        PFUMO_PROFESSIONAL_IN_CHARGE_COLLEGIATE:1537,
        PFUMO_PROFESSIONAL_IN_CHARGE_NAME:1538,
        PFUMO_PROFESSIONAL_IN_CHARGE_IDENTIFICATION:1539,
        PFUMO_PROFESSIONAL_IN_CHARGE_RENAS:1540,
        PFUMO_PROFESSIONAL_IN_CHARGE_SIGNATURE_AND_SEAL:1541,
        PFUMO_PROFESSIONAL_IN_CHARGE_TITLE:1542,
        PFUMO_PROFESSIONAL_IN_CHARGE_SPECIALTY_TITLE:1543,
        PFUMO_PROFESSIONAL_IN_CHARGE_HEALTH_CARD:1544,
        PFUMO_SANITARY_LICENSE:1545,
        PFUMO_MEDICATIONS_MANAGEMENT_LICENSE:1546,
        PFUMO_ENVIRONMENTAL_LICENSE:1547,
        PFUMO_CUSTOMER_SERVICE:1548,
        PFUMO_TWENTY_FOUR_HRS:1550,
        PFUMO_SURGERIES:1551,
        PFUMO_INTENSIVE_AND_MORE_COMPLEX_SERVICES:1552,
        PFUMO_PHARMACY_LINKED_TO_FRANCHISE_LAW:1553,
        PFUMO_DAYS_AND_SCHEDULE_OF_ATTENTION:1554,
        PFUMO_BEDDING:1555,
        PFUMO_EXTERNAL_CONSULTATION:1556,
        PFUMO_EMERGENCIES:1557,
        PFUMO_HOME_CONSULTATION:1558,
      PF_UM_PERSON: 1699,
        PFUMP_PROFESSIONAL_IN_CHARGE_SPECIALTY_TITLE:1651,
        PFUMP_TWENTY_FOUR_HRS:1653,
        PFUMP_DAYS_AND_SCHEDULE_OF_ATTENTION:1654,
        PFUMP_HOME_CONSULTATION:1655,
        PFUMP_PROFESSIONAL_COLLEGIATE:1656,
        PFUMP_PROFESSIONAL_NAME:1657,
        PFUMP_PROFESSIONAL_IDENTIFICATION:1658,
        PFUMP_PROFESSIONAL_RENAS:1659,
        PFUMP_PROFESSIONAL_SIGNATURE_AND_SEAL:1660,
        PFUMP_PROFESSIONAL_HEALTH_CARD:1661,
        PFUMP_TITLE:1662,
    PROVIDER_FIELD_STATUS:1500,
      PFS_SELECTED:1501,
      PFS_NOT_SELECTED:1502,
      PFS_DEFAULT:1503,
    PROVIDER_TYPE:1362,
      PT_HOSPITAL:1700,
        PS_HOSPITAL:1701,
      PT_SANATORIUM:1702,
        PS_SANATORIUM:1703,
      PT_CLINIC:1704,
        PS_MEDICAL:1705,
        PS_DENTAL:1706,
        PS_OPTOMETRIST:1707,
        PS_NUTRITION:1708,
        PS_PHYSICAL_THERAPY:1709,
      PT_PROFESSIONAL:1710,
        PS_EXTERNAL_TELEMEDICAL:1711,
        PS_MEDICAL_SPECIALIST:1712,
        PS_PSYCOLOGIST:1713,
        PS_NUTRITIONIST:1714,
      PT_TECHNICAL:1715,
        PS_RADIOLOGIST:1716,
        PS_PHYSICAL_THERAPIST:1717,
        PS_RESPIRATORY:1718,
        PS_OPTOMETRIST_T:1719,
        PS_AUDIOMETRIST:1725,
        PS_LABORATORY:1726,
      PT_AMBULANCE:1720,
        PS_AMBULANCE:1721,
      PT_DIAGNOSTIC_CENTER:1730,
        PS_DIAGNOSTIC_CENTER:1731,
        PS_LABORATORY_DC:1732,
      PT_PHARMACY:1740,
        PS_PHARMACY:1741,
      PT_MORTUARY:1750,
        PS_MORTUARY:1751,
    MEDICAL_SPECIALTY:1800,
    PROCESS_TYPE: 1112,
      SALE:1113,
      BUY:1114,
      BUY_SALE:1115,
    PRODUCT_TYPE:1039,
      PT_GOOD:1040,
      PT_SERVICE:1041,
        PS_PLANS:2168,
        PS_LABS:2169,
        PS_SERVICES:2170,
      PT_MATERIAL:1042,
        PS_MEDICINES:2171,
      PT_PACKAGE:1380,
    CORRELATIVE_TYPE: 1370,
      CORRELATIVE_CUSTOMER: 1371,
      CORRELATIVE_BILL: 1372,
      CORRELATIVE_GEOGRAFIC_ORGANIZATION: 1373,
      CORRELATIVE_ORDER: 1374,
      CORRELATIVE_ORGANIZATIONAL_CLASSIFICATION: 1375,
      CORRELATIVE_PROVIDERS:1376,
      CORRELATIVE_PROVIDER_TYPE: 1377,
      CORRELATIVE_PRICE_LIST: 1378,
      CORRELATIVE_FRANCHISES: 1379,
      CORRELATIVE_PROFESSIONAL:1381,
      CORRELATIVE_WAREHOUSE_CODE: 9580,
      CORRELATIVE_PRODUCT_ENTRIES: 9582,
      CORRELATIVE_PRODUCT_USES: 9583,
      CORRELATIVE_PRODUCT_RETURN: 9584,
      CORRELATIVE_PRODUCT_TRANSFER: 9585,
      CORRELATIVE_PRODUCT_VARIANCES: 9586,
      CORRELATIVE_POLICY_TYPE: 9605,
      CORRELATIVE_ACCOUNTING_POLICY: 9606,
      CORRELATIVE_CUSTOMER_TYPE: 3045,
    ORGANIZATION_CONTACT_TYPE: 1235,
      OCT_TELEPHONE:1236,
      OCT_CELLPHONE:1237,
      OCT_FAX:1238,
      OCT_EMAIL:1239,
      OCT_WEBSITE:1240,
      OCT_FACEBOOK:1241,
      OCT_TWITTER:1242,
      OCT_LINKEDIN:1243,
      OCT_SKYPE:1244,
    ORGANIZATION_ADDRESS_TYPE:1203,
      OAT_BILLING:1204,
      OAT_DELIVERY:1205,
      OAT_PAYMENT:1206,
      OAT_PARTICULAR:1207,
      OAT_OWNER:1208,
      OAT_ADMINISTRATION:1209,
      OAT_RECEPTION:1210,
      OAT_EVENT_OR_ACTIVITY:1211,
    ORGANIZATION_ATTACHMENT_TYPE:1400,
      OAT_BUSINESS_LICENSE: 1401,
      OAT_PARTNERSHIP_AGREEMENT: 1402,
    DAYS_OF_WEEK: 323,
      DW_MONDAY: 324,
      DW_TUESDAY: 325,
      DW_WEDNESDAY: 326,
      DW_THURSDAY: 327,
      DW_FRIDAY: 328,
      DW_SATURDAY: 329,
      DW_SUNDAY: 330,
    PRICE_TYPE:1060,
    ACCOUNT_TYPE:1005,
    ATTACHMENT_TYPE:1400,
    PAYMENT_CONDITION:200,
        PC_COSTUMER:201,
        PC_PROVIDER:202,
    DOCUMENT_TYPE: 1011,
         CREDIT_NOTE: 1012,
         DEBIT_NOTE: 1013,
         BUY_INVOICE: 1014,
         CASH_RECEIPT: 1015,
         PROPOSAL:1033,
         PROJECT:1034,
         SELL_INVOICE: 1035,
         SPECIAL_INVOICE: 1036,
         EXCHANGE_INVOICE: 1037,
    FISCAL_YEAR_STATUS: 337,
      FY_PENDING: 338,
      FY_OPENED: 339,
      FY_CLOSED: 340,
    FISCAL_PERIOD_STATUS: 341,
      FP_PENDING: 342,
      FP_OPENED: 343,
      FP_REVISION: 344,
      FP_CLOSED: 345,
    LOG_TYPE: 1760,
      LT_CORE_LOG: 1761,
      LT_AUTH_LOG: 1762,
      LT_ORDER_LOG: 1763,
    FB_FISCAL_BOOK:965,
        FB_JOURNAL:966,
        FB_MAJOR:967,
        FB_ACCOUNT_BALANCE:968,
        FB_GENERAL_BALANCE:969,
        FB_INCOME_RESULTS:970,
        FB_BUY_BOOK:971,
        FB_SALE_BOOK:1180,
    POLICY_TYPE: 1830,
        POL_OPEN_CLOSE: 1831,
        POL_REVENUES: 1832,
        POL_EXPENSES: 1833,
        POL_PURCHASES: 1834,
        POL_SALES: 1835,
        POL_SALARIES_BENEFITS: 1836,
        POL_TAXES: 1837,
        POL_INVENTORY: 1838,
        POL_MANUAL: 1839,
        POL_FIXED_ASSETS: 1840,
        POL_SUPPLIERS: 1842,
        POL_INVESTMENTS: 1843,
        POL_ADJUSTMENTS: 1844,
    POLICY_CATEGORY: 894,
        P_OPENING: 895,
        P_NORMAL: 896,
        P_REGULARIZATION: 897,
        P_CLOSURE: 898,
    POLICY_STATUS: 346,
        P_DRAFT: 347,
        P_REVIEW: 348,
        P_CLOSED: 349,
        P_ANULLED: 996,
    ACCOUNT_PAYABLE_TYPE: 1830,
      APT_PROJECTED: 1830,
      APT_REAL: 1830,
    CASE_STATUS: 1835,
      CS_CREATED: 1836,
      CS_ASSIGNED: 1837,
      CS_IN_DOCUMENTATION: 1838,
      CS_FINALIZED: 1839,
      CS_REJECTED: 1840,
      CS_DELETED: 1841,
    PAYMENT_STATUS: 1845,
      PS_PENDING: 1846,
      PS_PAID: 1847,
    ACCOUNTING_ELEMENT_TYPE: 1860,
      CE_T_ACTIVE: 1861,
      CE_T_PASSIVE: 1862,
      CE_T_PATRIMONY: 1863,
      CE_T_INCOME: 1864,
      CE_T_EXPENSES: 1865,
      CE_T_COSTS: 1866,
      CE_T_OTHER_INCOME: 1867,
      CE_T_OTHER_EXPENSES: 1868,
    ACCOUNTING_ELEMENT_NATURE: 1869,
      CE_N_CREDIT: 1870,
      CE_N_DEBIT: 1871,
      CE_N_NEUTRAL: 1872,
    ACCOUNTING_ELEMENT_LEVEL: 1873,
      CE_L_FIRST_LEVEL: 1874,
      CE_L_SECOND_LEVEL: 1875,
      CE_L_THIRD_LEVEL: 1876,
      CE_L_FOURTH_LEVEL: 1877,
      CE_L_FIFTH_LEVEL: 1878,
      CE_L_SIXTH_LEVEL: 1879,
      CE_L_SEVENTH_LEVEL: 1880,
      CE_L_EIGHTH_LEVEL: 1881,
    ACCOUNTING_CASH_FLOW: 1882,
      ACC_OPERATING_ACTIVY: 1883,
      ACC_INVESTING_ACTIVITY: 1884,
      ACC_FINANCING_ACTIVITY: 1885,
      ACC_CASH_EQUIVALENTS: 1886,
    EXPORT_FIELD_TYPE: 1990,
      EFT_FILTER: 1991,
      EFT_COLUMN: 1992,
    WORK_DAY: 1870,
      WD_SUNDAY: 1871,
      WD_MONDAY: 1872,
      WD_TUESDAY: 1873,
      WD_WEDNESDAY: 1874,
      WD_THURSDAY: 1875,
      WD_FRIDAY: 1876,
      WD_SATURDAY: 1877,
    WORK_DAY_TYPE: 1880,
      WDT_WORKING_DAY: 1881,
      WDT_NON_WORKING_DAY: 1882,
      WDT_SPECIAL_WORKING_DAY: 1883,
      WDT_REASON_OF_ABSENCE: 1884,
    PARAMETER_TYPE: 1850,
      PMT_ACCOUNTING_DECIMALS: 1857,
      PMT_ORGANIZATION_HAS_FRANCHISES:2020,
      PMT_PHARMACY_PROVIDER_TYPE_CODE:2021,
      PMT_PROVIDER_NETWORK_CODE:2022,
      PMT_TIME_PER_SESSION:2023,
      PMT_DEFAULT_COORDINATES:2029,
      PMT_DATE_TO_CALCULATE_CXP:2035,
      PMT_DATE_TO_CALCULATE_CXC:2036,
      PMT_SEPARATE_DECIMALS:2050,
    DATA_TYPE: 1320,
      DT_INT: 1321,
      DT_STRING: 1322,
      DT_LONG: 1323,
      DT_BOOLEAN: 1324,
      DT_CHAR: 1325,
      DT_DECIMAL: 1326,
    CASE_ATTACHMENT_TYPE: 1920,
    EVENT_TYPE: 1896,
      PRD_IN:1897,
      PRD_OUT:1898,
      PRD_VARIANCE:1899,
    TRANSACTION_TYPE:1900,
      PRD_ACQUISITION:1901,
      PRD_DISPOSAL:1902,
      PRD_TRANSFERENCE:1903,
      PRD_RETURN:1904,
      PRD_USAGE:1905,
      PRD_MANUAL_INVENTORY:1906,
      PRD_MANUAL_ADJUSTMENT:1907,

    DOCUMENT_STATUS: 1064,
      DS_REVIEW: 1065,
      DS_CLOSED: 1066,
      DS_AUHTORIZED: 1067,
      DS_ANULLED: 1068,
      DS_UNCOLLECTIBLE: 1109,
      DS_COMMITTED: 1110,
      DS_WITHOUT_USE: 1111,
    EXPORT_FORMAT: 2010,
      EF_CSV: 2011,
      EF_XLSX: 2012,
      EF_PDF: 2013,
    BANK_TRANSACTION_TYPE: 1016,
      BTT_DEPOSIT: 1017,
      BTT_TRANSACTION: 1018,
      BTT_ACH_TRANSACTION: 1019,
      BTT_CHECK: 1020,
      BTT_CREDIT_CARD: 1106,
      BTT_COMPRA_CLICK: 1107,
      BTT_BOUNCED_CHECK: 1179,
    BANK_ACCOUNT_TRANSACTION_STATUS: 1024,
      BATS_ENTERED: 1025,
      BATS_OPERATED: 1026,
      BATS_DECLINED: 1027,
      BATS_REVERSED: 1028,
    CUSTOMER_TYPE: 2020,
      CT_LEGAL: 2021,
      CT_INDIVIDUAL: 2022,
      CT_AFFILIATED: 2023,
    CUSTOMER_FIELD: 2030,
      CF_GENERAL: 2154,
        CF_STATUS: 2155,
      CF_GENERAL_INDIVIDUAL: 2031,
        CF_GI_FIRST_NAME:2040,
        CF_GI_SECOND_NAME:2041,
        CF_GI_FIRST_SURNAME:2042,
        CF_GI_SECOND_SURNAME:2043,
        CF_GI_MARRIED_NAME:2044,
        CF_GI_IDENTIFICATION_NUMBER:2045,
        CF_GI_TAX_IDENTIFICATION_NUMBER:2046,
        CF_GI_PHONE_NUMBER:2047,
        CF_GI_SECONDARY_PHONE_NUMBER:2048,
        CF_GI_EMAIL_ADDRESS:2049,
        CF_GI_STATE:2050,
        CF_GI_CITY:2051,
        CF_GI_ADDRESS:2052,
        CF_GI_POSTAL_CODE:2053,
        CF_GI_BIRTHDAY:2054,
        CF_GI_NATIONALITY:2055,
        CF_GI_PROFESSION:2056,
        CF_GI_GENDER:2057,
        CF_GI_EMERGENCY_CONTACTS:2058,
        CF_GI_CUSTOMER_CATEGORY:2059,
        CF_GI_CREDIT_TIME:2060,
        CF_GI_PAYMENT_TYPE:2061,
        CF_GI_AUTOMATIC_DEBIT_SUBSCRIPTION:2062,
        CF_GI_CARD_NUMBER:2063,
        CF_GI_CONTRACT:2064,
        CF_GI_IDENTIFICATION:2065,
        CF_GI_EMERGENCY_CONTACT:2066,
      CF_GENERAL_LEGAL: 2032,
        CF_GL_LEGAL_PERSON_TYPE:2075,
        CF_GL_COMPANY_NAME_LEGAL_NAME:2076,
        CF_GL_TRADE_NAME:2077,
        CF_GL_TAX_IDENTIFICATION_NUMBER:2078,
        CF_GL_PHONE_NUMBER:2079,
        CF_GL_SECONDARY_PHONE_NUMBER:2080,
        CF_GL_ADDRESS:2081,
        CF_GL_STATE:2082,
        CF_GL_CITY:2083,
        CF_GL_POSTAL_CODE:2084,
        CF_GL_DATE_OF_ESTABLISHMENT:2085,
        CF_GL_WEB_SITE:2086,
        CF_GL_LEGAL_REPRESENTATIVE_FIRST_NAME:2087,
        CF_GL_LEGAL_REPRESENTATIVE_SECOND_NAME:2088,
        CF_GL_LEGAL_REPRESENTATIVE_FIRST_SURNAME:2089,
        CF_GL_LEGAL_REPRESENTATIVE_SECOND_SURNAME:2090,
        CF_GL_LEGAL_REPRESENTATIVE_IDENTIFICATION_NUMBER:2091,
        CF_GL_EFFECTIVE_DATE:2092,
        CF_GL_EMAIL_ADDRESS:2093,
        CF_GL_BUSINESS_ACTIVITY:2094,
        CF_GL_CUSTOMER_CATEGORY:2095,
        CF_GL_PURCHASE_CONTACT:2096,
        CF_GL_PAYMENT_CONTACT:2097,
        CF_GL_ACCOUNTING_CONTACT:2098,
        CF_GL_PAYMENT_METHOD:2099,
        CF_GL_PAYMENT_CURRENCY:2100,
        CF_GL_TAX_REGIME:2101,
        CF_GL_CONTRACT:2102,
        CF_GL_IDENTIFICATION_HOLDER:2103,
        CF_GL_IDENTIFICATION:2104,
        CF_GL_NDA:2105,
        CF_GL_TAX_IDENTIFICATION:2106,
        CF_GL_LEGAL_REPRESENTATIVE:2107,
        CF_GL_TRADE_LICENSE:2108,
        CF_GL_CORPORATE_PATENT:2109,
      CF_GENERAL_AFFILIATED: 2033,
        CF_GA_IDENTIFIER:2115,
        CF_GA_AFFILIATION_CODE:2116,
        CF_GA_FIRST_NAME:2117,
        CF_GA_SECOND_NAME:2118,
        CF_GA_FIRST_SURNAME:2119,
        CF_GA_SECOND_SURNAME:2120,
        CF_GA_MARRIED_NAME:2121,
        CF_GA_IDENTIFICATION_NUMBER:2122,
        CF_GA_TAX_IDENTIFICATION_NUMBER:2123,
        CF_GA_PHONE_NUMBER:2124,
        CF_GA_SECONDARY_PHONE_NUMBER:2125,
        CF_GA_EMAIL_ADDRESS:2126,
        CF_GA_RESIDENTIAL_ADDRESS:2127,
        CF_GA_RESIDENTIAL_STATE:2128,
        CF_GA_RESIDENTIAL_CITY:2129,
        CF_GA_POSTAL_CODE:2130,
        CF_GA_BIRTHDAY:2131,
        CF_GA_NATIONALITY:2132,
        CF_GA_PROFESSION:2133,
        CF_GA_GENDER:2134,
        CF_GA_EMERGENCY_CONTACT:2135,
        CF_GA_EMERGENCY_CONTACT_CODE:2136,
        CF_GA_EMERGENCY_CONTACT_IDENTIFICATION_NUMBER:2137,
        CF_GA_BRANCH_NAME:2138,
        CF_GA_BRANCH_CODE:2139,
        CF_GA_BRANCH_EMAIL_ADDRESS:2140,
        CF_GA_BRANCH_ADDRESS:2141,
        CF_GA_BRANCH_STATE:2142,
        CF_GA_BRANCH_CITY:2143,
        CF_GA_BRANCH_CONTACT_NAME:2144,
        CF_GA_BRANCH_CONTACT_PHONE:2145,
        CF_GA_BRANCH_CONTACT_POSITION:2146,
        CF_GA_STATUS:2147,
        CF_GA_ENTRY_DATE:2148,
        CF_GA_MODIFICATION_DATE:2149,
        CF_GA_LEVEL:2150,
        CF_GA_BENEFICIARIES:2151,
        CF_GA_NUMBER_OF_BENEFICIARIES:2152,
    ATTRIBUTE_CATEGORY: 2156,
      AC_ANALGESICS: 2157,
      AC_ANTIBIOTICS: 2158,
      AC_ANTIPYRETICS: 2159,
    ATTRIBUTE_CHARACTTERISTIC: 2160,
      ACH_ORAL_EXTENDED_RELEASE: 2161,
      ACH_INTRAVEOUS_FAST_ACTING: 2162,
      ACH_TOPICAL_EXTERNAL_USE: 2163,
    ATTRIBUTE_PRESENTATION: 2164,
      AP_TABLET_IN_BLISTER: 2165,
      AP_INTRAVENOUS_AMPOLE: 2166,
      AP_CREAN_IN_TUBE: 2167,
    CUSTOMER_ATTACHMENT_TYPE:3000,
      CAT_CONTRACT:3001,
      CAT_PERSONAL_IDENTIFICATION:3002,
      CAT_PERSONAL_IDENTIFICATION_EMERGENCY_CONTACT:3003,
      CAT_LEGAL_REPRESENTATIVE_IDENTIFICATION_DOCUMENT:3004,
      CAT_INVOICE_PHOTO: 3005,
    REASON_OF_ABSENCE: 3006,
    CUSTOMER_CONTACT_PURPOSE:3020,
      CCP_EMERGENCY_CONTACT:3021,
      CCP_OPTIONAL_EMERGENCY_CONTACT_1:3022,
      CCP_OPTIONAL_EMERGENCY_CONTACT_2:3023,
      CCP_PAYMENT:3024,
      CCP_ACCOUNTING:3025,
      CCP_SALES:3026,
    TRANSACTION_ATTACHMENTS: 3027,
      TA_PERSONAL_DOCUMENT: 3028,
      TA_INVOICE_PHOTO: 3029,
    ORGANIZATIONAL_CLASSIFICATION:2,
    RETENTION: 9600,
      R_ISR: 9601,
      R_IVA: 9602,
      R_BANK: 9603,



    AUTH_OP_STATUS: 2030,
      AOS_IN_REVIEW: 2031,
      AOS_PENDING_REVIEW: 2032,
      AOS_AUTHORIZED: 2033,
      AOS_REJECTED: 2034,
    PAGE_AUTHORIZATION_TYPE: 3040,
      PGAT_CREATION: 3041,
      PGAT_ELIMINATION: 3042,
    ACC_CONFIGURATION_TYPE: 1122,
      ACC_BUY_CONFIGURATION: 1123,
      ACC_SALE_CONFIGURATION: 1124,
      ACC_PRODUCT_CONFIGURATION: 1125,
      ACC_RETENTIONS_ACCOUNTS: 1157,
      ACC_ADVANCES_ACCOUNTS: 1162,
      ACC_DEVOLUTIONS_ACCOUNTS: 1177,
    ACC_BUY_CONFIGURATION_PARENT: 1126,
      BUY_ACCOUNT: 1127,
      BUY_ACCOUNT_PRODUCT: 1128,
      BUY_ACCOUNT_MATERIAL: 1129,
      BUY_ACCOUNT_SERVICE: 1130,
      BUY_TAX_ACCOUNT: 1131,
      BUY_PAYMENT_ACCOUNT: 1132,
      BUY_PAYMENT_ACCOUNT_PRODUCT: 1133,
      BUY_PAYMENT_ACCOUNT_MATERIAL: 1134,
      BUY_PAYMENT_ACCOUNT_SERVICE: 1135,
      BUY_DISCOUNT_ACCOUNT: 1136,
    ACC_SALE_CONFIGURATION_PARENT: 1137,
      SALE_ACCOUNT: 1141,
      SALE_ACCOUNT_PRODUCT: 1142,
      SALE_ACCOUNT_MATERIAL: 1143,
      SALE_ACCOUNT_SERVICE: 1144,
      SALE_TAX_ACCOUNT: 1145,
      SALE_PAYMENT_ACCOUNT: 1146,
      SALE_PAYMENT_ACCOUNT_PRODUCT: 1147,
      SALE_PAYMENT_ACCOUNT_MATERIAL: 1148,
      SALE_PAYMENT_ACCOUNT_SERVICE: 1149,
      SALE_DISCOUNT_ACCOUNT: 1150,
    ACC_PRODUCT_CONFIGURATION_PARENT: 1151,
      PRODUCT_BUY_ACCOUNT: 1152,
      PRODUCTO_BUY_PAYMENT_ACCOUNT: 1153,
      PRODUCT_SALE_ACCOUNT: 1154,
      PRODUCT_SALE_PAYMENT_ACCOUNT: 1155,
      PRODUCT_TAX_ACCOUNT: 1156,
    ACC_RETENTIONS_ACCOUNTS_PARENT: 1158,
      RETENTION_BUY_TAX: 1159,
      RETENTION_SALE_TAX: 1160,
      RETENTION_BUY_ISR: 1161,
    ACC_ADVANCES_ACCOUNTS_PARENT: 1163,
      PROVIDER_ADVANCES: 1164,
      CUSTOMER_ADVANCES: 1165,
    ACC_DEVOLUTIONS_ACCOUNTS_PARENT: 1174,
      BUY_DEVOLUTION: 1175,
      SALE_DEVOLUTION: 1176,
    BILLING_REPORT_STATUS: 9607,
      BR_ENLISTED: 9608,
      BR_COMPLETED: 9609,
      BR_INVOICED: 9610,
      BR_ERROR: 9611,
        BRE_DATE: 9612,
        BRE_SERIES: 9613,
        BRE_NUMBER: 9614,
        BRE_AMOUNT: 9615,
        BRE_FILE: 9616,
        BRE_DESCRIPTION: 9617,
        BRE_MULTIPLE_REASONS: 9618,
      BR_ACCOUNTING_PENDING: 9619,
      BR_ACCOUNTING_REJECTED: 9620,
      BR_ACCOUNTING_AUTHORIZED: 9621,
    APPOINTMENT_CRITERIA: 9610,
        AC_PER_SESSION: 9611,
        AC_PER_MONTH: 9612,
        AC_PER_QUANTITY: 9613,
}

export enum TypologyFieldNameEnum {
  ID = "typologyId"
}
