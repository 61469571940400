import { Pipe, PipeTransform } from '@angular/core';
import { LOCALKENUM } from 'src/global/local-enum';


@Pipe({
  name: 'decimalCustom'
})
/**
 * DecimalCustomPipe
 *
 * This custom pipe transforms a numeric value into a text string with a specific decimal format.
 * Use the decimal separator stored in localStorage, or by default, a comma.
 *
 * @example
 * // If LOCALKENUM.DECIMAL_SEPARATOR in localStorage is '.' and 2 decimals are specified
 * {{ 1234.5678 | decimalCustom:2 }}  // Result: '1234.57'
 *
 * // If LOCALKENUM.DECIMAL_SEPARATOR in localStorage is ',' and number of decimal places is not specified
 * {{ 1234.5678 | decimalCustom }}  // Result: '1234,5678'
 *
 * @remarks
 * It is important to ensure that the value of LOCALKENUM.DECIMAL_SEPARATOR in localStorage is valid (',' or '.').
 */
export class DecimalCustomPipe implements PipeTransform {

  /**
   * Transforms a numeric value to a text string with a specific decimal format.
   *
   * @param value - The numeric value that you want to transform.
   * @param decimals - The number of decimal places you want in the result. If not specified, the default value of the number will be used.
   * @returns A text string with the numeric value formatted with the specified number of decimal places and the appropriate decimal separator.
   */
  transform(value: any, decimals: number): string {
    let valueInt :number = Number(value);
    return  valueInt.toFixed(decimals).replace('.', localStorage.getItem(LOCALKENUM.DECIMAL_SEPARATOR) || ',');
  }

}
