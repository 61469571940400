import packageInfo from 'package.json';

export const environment = {
  production: true,
  profile: 'customtheme',
  version: packageInfo.version,
  authUrl: 'https://api.xelaapp.org/auth',
  coreUrl: 'https://api.xelaapp.org/core',
  orderUrl: 'https://api.xelaapp.org',
  mailUrl: 'https://api.xelaapp.org',
  googleApiKey: 'AIzaSyCwbdqmt3LlA6-Na42rM1CKGF0jEkzniQw'
};
