<div class="main-container" *transloco="let t">
    <app-navbar></app-navbar>
    <div class="body-container">
        <app-header title="txt_my_profile" [type]="headerEnum.VIEW" editRoute="/profile/edit"></app-header>

        <!-- <app-header title="txt_my_profile" [type]="headerEnum.VIEW"></app-header> -->
        <app-body *ngIf="user">
            <div class="user-info align-items-center d-flex gap-1">
                <div class="avatar fw-semibold h2 m-0">
                    {{ initials }}
                </div>
                <div class="row">
                    <p class="user-name text-gray-600 m-0 fw-semibold">{{user.admPerson.fullName}}</p>
                    <div class="col-6 col-lg-6">
                        <div class="d-flex flex-column d-none d-lg-block">
                            <p class="user-role text-primary-400 m-0">
                                {{ roles }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_person_first_name')}}</label>
                        <div *ngIf="user.admPerson.firstName; then firstName; else noFirstName"></div>
                        <ng-template #firstName>
                            <p>{{user.admPerson.firstName}}</p>
                        </ng-template>
                        <ng-template #noFirstName>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_person_middle_name')}}</label>
                        <div *ngIf="user.admPerson.middleName; then middleName; else noMiddleName"></div>
                        <ng-template #middleName>
                            <p>{{user.admPerson.middleName}}</p>
                        </ng-template>
                        <ng-template #noMiddleName>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_person_third_name')}}</label>
                        <p class="text-gray-600">{{t('txt_no_information')}}</p>
                    </div>
                </div>

                <div class="col-12 col-lg-6">

                </div>

                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_person_last_name')}}</label>
                        <div *ngIf="user.admPerson.lastName; then lastName; else noLastName"></div>
                        <ng-template #lastName>
                            <p>{{user.admPerson.lastName}}</p>
                        </ng-template>
                        <ng-template #noLastName>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_person_married_name')}}</label>
                        <div *ngIf="user.admPerson.marriedName; then marriedName; else noMarriedName"></div>
                        <ng-template #marriedName>
                            <p>{{user.admPerson.marriedName}}</p>
                        </ng-template>
                        <ng-template #noMarriedName>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_user_email_1')}}</label>
                        <div *ngIf="user.email1; then Email; else noEmail"></div>
                        <ng-template #Email>
                            <p>{{user.email1}}</p>
                        </ng-template>
                        <ng-template #noEmail>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label fs-14 text-gray-600">{{t('adm_user_email_2')}}</label>
                        <div *ngIf="user.email2; then alternateEmail; else noAlternateEmail"></div>
                        <ng-template #alternateEmail>
                            <p>{{user.email2}}</p>
                        </ng-template>
                        <ng-template #noAlternateEmail>
                            <p class="text-gray-600">{{t('txt_no_information')}}</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </app-body>
    </div>
</div>