<ng-template *transloco="let t" #content>
  <div class="modal-header">
    <h5 class="modal-title">{{t(modalTitle)}}
    </h5>
    <button class="close btn btn-outline-primary" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-main-container d-flex flex-column gap-3">
      <div class="table-actions d-flex flex-column flex-lg-row justify-content-between gap-2">
        <app-search-filter [(filterConfig)]="filters" [filters]="objectFilter" (onChange)="onFilterChange()"
          [(columns)]="columns" [displayFiltersDropDown]="showFiltersAndColumns"
          [displayInputFilters]="displayInputFilters">
        </app-search-filter>

        <div *ngIf="multiple && showSelectAll" class="form-check">
          <input class="form-check-input" type="checkbox" id="select-all" [checked]="isAllSelected"
            (change)="onSelectAll($event)">
          <label class="form-check-label" for="select-all">{{t('txt_select_all')}}</label>
        </div>
      </div>
      <div class="table-container">
        <table class="table border-0 sticky-header sticky-actions">
          <tr>
            <th *ngFor="let name of sortedKeys(columns.config)" [hidden]="checkVisible(name)"
              [style.width.%]="columns.config[name].widthPercentage">
              <ng-container
                *ngIf="columns.config[name].withOrder != undefined && columns.config[name].withOrder == false; then columnWithoutOrder; else columnWithOrder"></ng-container>
              <ng-template #columnWithOrder>
                <div [ngClass]="{ byOrder: checkSort(name, undefined) }" class="d-flex">
                  <div (click)="sort(name)">{{ t(columns.config[name].name)}}</div>
                  <app-material-icon *ngIf="checkSort(name, true)">arrow_downward</app-material-icon>
                  <app-material-icon *ngIf="checkSort(name, false)">arrow_upward</app-material-icon>
                </div>
              </ng-template>
              <ng-template #columnWithoutOrder>
                <div>{{ t(columns.config[name].name)}}</div>
              </ng-template>
            </th>
          </tr>
          <tr *ngIf="simpleList.length === 0" class="p-1">
            <td colspan="12">
              <app-empty-panel customPadding="1rem"></app-empty-panel>
            </td>
          </tr>
          <ng-container *ngFor="let element of simpleList">
            <tr [class.active-row]="isAnySelected" [class.selected-row]="element.selected">
              <ng-container *ngFor="let field of sortedKeys(columns.config); let i = index">
                <td [hidden]="checkVisible(field)">
                  <div class="d-flex align-items-center gap-2">
                    <input *ngIf="i==0" [(ngModel)]="element.selected" (change)="validateMultiple(element)"
                      class="form-check-input" type="checkbox">
                    <div *ngIf="element.entity.displayIcon != undefined; then iconWithCondition; else iconOnAllRows ">
                    </div>
                    <ng-template #iconWithCondition>
                      <app-material-icon *ngIf="i==0 && rowsIcon && element.entity.displayIcon === true"
                        class="text-primary-300 fw-semibold">{{rowsIcon}}</app-material-icon>
                    </ng-template>
                    <ng-template #iconOnAllRows>
                      <app-material-icon *ngIf="i==0 && rowsIcon"
                        class="text-primary-300 fw-semibold">{{rowsIcon}}</app-material-icon>
                    </ng-template>
                    <div class="d-flex gap-2" role="button"
                      *ngIf="element.entity[field]!==null&&element.entity[field]!==undefined; then noEmpty; else empty">
                    </div>
                    <ng-template #noEmpty>
                      <ng-container
                        *ngIf="columns.config[field].withTranslation!=undefined && columns.config[field].withTranslation == true; then fieldWithTranslation; else fieldWithoutTranslation"></ng-container>
                    </ng-template>
                    <ng-template #fieldWithTranslation>
                      {{columns.config[field].keyAttribute==undefined ? t(element.entity[field].toString()) :
                      t(element.entity[field][columns.config[field].keyAttribute!].toString())}}
                    </ng-template>
                    <ng-template #fieldWithoutTranslation>
                      {{columns.config[field].keyAttribute==undefined ? t(element.entity[field].toString()) :
                      t(element.entity[field][columns.config[field].keyAttribute!].toString())}}
                    </ng-template>
                    <ng-template #empty>
                      {{t('txt_no_information')}}
                    </ng-template>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </table>
      </div>
      <app-pagination [(paginationConfig)]="this.filters" [currentCollectionSize]="this.simpleList.length"
        (onChange)="onFilterChange()">
      </app-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="close()" data-dismiss="modal">{{t('cancel')}}
    </button>
    <button type="button" class="btn btn-primary" (click)="select()" data-dismiss="modal">{{t('select')}}
    </button>
  </div>
</ng-template>
