<div class="main-container" *transloco="let t">
    <app-navbar></app-navbar>
    <div class="body-container">
        <app-header title="txt_my_profile" [type]="headerEnum.SAVE"  [tabs]="tabs" (tabSelected)="changeView($event)" (saveAction)="save()"
            >

        </app-header>
        <app-body *ngIf="user">
            <app-required></app-required>
            <div class="row" *ngIf="tabs[0].view">
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="name" class="form-label"><span class="text-danger fw-bold me-2">*</span>
                            {{t('adm_person_first_name')}}
                        </label>
                        <input type="" class="form-control" id="name" name="name" [placeholder]="t('adm_person_first_name_placeholder')"
                            [(ngModel)]="user.admPerson.firstName" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="middle_name" class="form-label">{{t('adm_person_middle_name')}}</label>
                        <input type="" class="form-control" id="middle_name" name="middle_name"
                            [placeholder]="t('adm_person_middle_name_placeholder')" [(ngModel)]="user.admPerson.middleName" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="middle_name" class="form-label">{{t('adm_person_third_name')}}</label>
                        <input type="" class="form-control" id="middle_name" name="middle_name"
                            [placeholder]="t('adm_person_third_name_placeholder')" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">

                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="last_name" class="form-label"><span
                                class="text-danger fw-bold me-2">*</span>{{t('adm_person_last_name')}}</label>
                        <input type="" class="form-control" id="last_name" name="last_name"
                            [placeholder]="t('adm_person_last_name_placeholder')" [(ngModel)]="user.admPerson.lastName" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="married_name" class="form-label">{{t('adm_person_married_name')}}</label>
                        <input type="" class="form-control" id="married_name" name="married_name"
                            [placeholder]="t('adm_person_married_name_placeholder')" [(ngModel)]="user.admPerson.marriedName" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="email1" class="form-label"><span class="text-danger fw-bold me-2">*</span>{{t('adm_user_email_1')}}</label>
                        <input type="" class="form-control" id="email1" name="email1"
                            [placeholder]="t('adm_user_email_1_placeholder')" [(ngModel)]="user.email1" requerid>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="email2" class="form-label">{{t('adm_user_email_2')}}</label>
                        <input type="" class="form-control" id="email2" name="email2"
                            [placeholder]="t('adm_user_email_2_placeholder')" [(ngModel)]="user.email2" requerid>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="tabs[1].view">
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="current_password" class="form-label"><span
                                class="text-danger fw-bold me-2">*</span>{{t('txt_current_password')}}</label>
                        <div class="input-prefix-icon input-suffix-icon">
                            <app-material-icon>lock</app-material-icon>
                            <input [(ngModel)]="credential.oldPassword" [type]="showPass ? 'text' : 'password'"
                                class="form-control" id="current_password" name="current_password"
                                [placeholder]="t('txt_current_password_placeholder')" maxlength="20" requerid>
                            <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle"
                                (click)="showPass=!showPass">
                                <span class="show-option" [hidden]="showPass">visibility</span>
                                <span class="hide-option" [hidden]="!showPass">visibility_off</span>
                            </app-material-icon>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">

                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="new-password" class="form-label"><span
                                class="text-danger fw-bold me-2">*</span>{{t('txt_new_password')}}</label>
                        <div class="input-prefix-icon input-suffix-icon">
                            <app-material-icon>lock</app-material-icon>
                            <input [(ngModel)]="credential.password" [type]="showPassN ? 'text' : 'password'"
                                class="form-control" id="new-password" name="new-password"
                                [placeholder]="t('txt_new_password_placeholder')" maxlength="20" requerid>
                            <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle"
                                (click)="showPassN=!showPassN">
                                <span class="show-option" [hidden]="showPassN">visibility</span>
                                <span class="hide-option" [hidden]="!showPassN">visibility_off</span>
                            </app-material-icon>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label for="confirm-password" class="form-label"><span
                                class="text-danger fw-bold me-2">*</span>{{t('txt_confirm_password')}}</label>
                        <div class="input-prefix-icon input-suffix-icon">
                            <app-material-icon>lock</app-material-icon>
                            <input [(ngModel)]="confirmPassword" [type]="showPassC ? 'text' : 'password'"
                                class="form-control" id="confirm-password" name="confirm-password"
                                [placeholder]="t('txt_confirm_password_placeholder')" maxlength="20" requerid>
                            <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle"
                                (click)="showPassC=!showPassC">
                                <span class="show-option" [hidden]="showPassC">visibility</span>
                                <span class="hide-option" [hidden]="!showPassC">visibility_off</span>
                            </app-material-icon>
                        </div>
                    </div>
                </div>
            </div>
        </app-body>
    </div>
</div>