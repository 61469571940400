import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GTLat, GTLng, regexCoordinatesPattern } from 'src/global/map-const';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';
import { CoordinatesInfo } from 'src/app/data/others/location';


@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements AfterViewInit {

  apiLoaded: boolean = false;
  loader = new Loader({
    apiKey: environment.googleApiKey,
    libraries: ['places']
  });

  constructor(
    private modalService: NgbModal,
    private toastService: ToasterService
  ) {
    this.loader.importLibrary('maps').then(() => {
      this.apiLoaded = true;
    });
  }
  /**Modal variables */

  @Output() actionConfirm = new EventEmitter();
  @Input() backdrop: boolean | 'static' = true;
  @Input() modalTitle = 'Nuevo modal';
  @Input() modalSize = 'lg';
  @Input() modalScrollable = true;
  @Input() modalCentered = false;
  @Input() showCancelButton = true;
  @ViewChild('content') content!: any; closeResult = '';
  @Output() modalCallback = new EventEmitter<boolean>();
  modalRef!: NgbModalRef;
  @Input() pageTitle: string = "txt_set_location";

  @Input() inputCoordinatesInfo!: CoordinatesInfo;

  /**Google Maps variables */

  @ViewChild(MapInfoWindow) info!: MapInfoWindow;
  @ViewChild('mapSearchField') searchField!: ElementRef;
  @ViewChild('googleMap') mapG!: GoogleMap;

  coordinatesPattern = regexCoordinatesPattern;

  markers: google.maps.Marker[] = [];
  options: google.maps.MapOptions = {
    center: { lat: GTLat, lng: GTLng },
    zoom: 8,
    disableDefaultUI: true
  };

  coordinates!: string;
  center!: google.maps.LatLngLiteral;
  latitude!: any;
  longitude!: any;
  geocoder!: google.maps.Geocoder;

  coordinatesInfo!: CoordinatesInfo;

  marker = {
    icon: 'assets/icons/location_on.svg',
    position: {
      lat: GTLat,
      lng: GTLng
    },
    label: {
      text: '',
      className: 'mark-label',
      color: '#3374AC',
      fontSize: '12px',
      fontWeight: '700'
    },
    title: '',
    info: '',
  };
  infoContent = '';

  /** Other variables */

  active = 1;

  openModal() {
    this.open();
  }

  open(): void {
    setTimeout(() => {
      this.initSearch();
    })
    this.modalRef = this.modalService.open(this.content, { size: this.modalSize, scrollable: this.modalScrollable, centered: this.modalCentered, backdrop: this.backdrop });

    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  protected close(): void {
    this.modalRef.close();
  }

  confirmBtn() {
    this.actionConfirm.emit(this.coordinatesInfo);
    this.close();
  }


  ngAfterViewInit(): void {
    this.coordinatesInfo = new CoordinatesInfo();
  }


  initSearch(): void {
    const input = document.getElementById("map-search-field") as HTMLInputElement;

    let searchBox = new google.maps.places.SearchBox(
      input
    );

    if (!this.mapG || !this.mapG.googleMap) {
      return
    }

    this.geocoder = new google.maps.Geocoder;

    this.mapG.googleMap.addListener("bounds_changed", () => {
      searchBox.setBounds(this.mapG.googleMap?.getBounds() as google.maps.LatLngBounds);
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();


      if (!places) {
        return
      }

      if (places.length == 0) {
        return;
      }
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        this.markers.push(
          new google.maps.Marker({
            map: this.mapG.googleMap,
            icon: 'assets/icons/location_on.svg',
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.mapG.googleMap?.fitBounds(bounds);
    });

    if (this.inputCoordinatesInfo != null && this.inputCoordinatesInfo.latitude != null && this.inputCoordinatesInfo.longitude != null) {
      if (this.inputCoordinatesInfo.latitude == 0 && this.inputCoordinatesInfo.longitude == 0) {
        return;
      } 
      this.setMarker(this.inputCoordinatesInfo.latitude, this.inputCoordinatesInfo.longitude);
    }

  }



  click(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) {

      let coordinates = event.latLng.toJSON();
      this.setMarker(coordinates.lat, coordinates.lng);
    }
  }

  openInfo(marker: any, content: any) {
    this.infoContent = content;
    this.info.open(marker);
  }


  searchByCoordinates() {
    if (this.verifyCoordinates()) {
      const [latitude, longitude] = this.coordinates.split(',');
      this.setMarker(Number(latitude), Number(longitude));
    }
  }

  setMarker(lat: number, lng: number) {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
    const marker = new google.maps.Marker({
      icon: 'assets/icons/location_on.svg',
      position: {
        lat: lat,
        lng: lng,
      },

      map: this.mapG.googleMap,
    });

    this.markers.push(marker);
    const latlng = {
      lat: lat,
      lng: lng,
    };

    this.coordinatesInfo.latitude = lat;
    this.coordinatesInfo.longitude = lng;
    this.geocoder
      .geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          this.coordinatesInfo.address = response.results[0].formatted_address;
          this.info.infoWindow?.setContent(response.results[0].formatted_address);
          this.info.infoWindow?.open(this.mapG.googleMap, marker)
        } else {
          this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
        }
      })
      .catch(() => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_google_maps' }));

  }

  verifyCoordinates() {
    if (!this.coordinates) {
      return false;
    }
    return this.coordinatesPattern.test(this.coordinates)
  }
}
