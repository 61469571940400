<div class="row" *transloco="let t">
    <div *ngIf="imageDataArray.length === 0" class="col-sm-12 d-flex rounded select-image-indicator text-center">
        <label *ngIf="!saving" [attr.for]="idInput" class="d-flex flex-column my-auto w-100 img-selector-label">
            <span class="fa fa-image text-muted mb-2"></span>
            <span class="text-muted">{{ t(title) }}</span>
        </label>
        <div *ngIf="saving" class="spinner d-flex mt-auto mb-2">
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">{{t('txt_loading')}}</span>
            </div>
            <span class="text-muted small ml-2">{{t('txt_saving_image')}}</span>
        </div>
    </div>
    <div *ngIf="imageDataArray.length > 0"
        class="col-sm-12 p-2 d-flex flex-wrap mb-3 mx-auto rounded select-image-preview">
        <div *ngIf="newUser">
            <label permissionType="create" *ngIf="image" class="change-img-icon" [attr.for]="idInput"
                title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <div *ngIf="me">
            <label *ngIf="image" class="change-img-icon" [attr.for]="idInput" title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <div *ngIf="!newUser && !me">
            /
            <label permissionType="update" *ngIf="image" class="change-img-icon" [attr.for]="idInput"
                title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <div *ngFor="let image of imageDataArray; let i = index">
            <div class="image-wrapper">
                <img [src]="image.url" class="img-preview" height="140" />
                <button (click)="deleteImage(i)" class="delete-button">
                    <app-material-icon>close</app-material-icon>
                </button>
            </div>
        </div>
        <div *ngIf="saving" class="spinner d-flex mt-auto mb-2">
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">{{t('txt_loading')}}</span>
            </div>
            <span class="text-muted small ml-2">{{t('txt_saving_image')}}</span>
        </div>
        <div class="d-flex mx-3 flex-column align-items-center">
            <input *ngIf="cutImage" class="d-none" type="file" [attr.id]="idInput" accept="image/*"
                (change)="openModalToCutImage($event, withCut)" />
            <label [attr.for]="idInput"
                class="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                style="width: 200px; height: 140px">
                <app-material-icon>add</app-material-icon>
                {{ t("txt_add_an_image") }}
            </label>
        </div>
    </div>

    <input *ngIf="cutImage" class="d-none" type="file" [attr.id]="idInput" accept="image/*"
        (change)="openModalToCutImage($event, withCut)" />
</div>

<app-image-selector-with-cut [informationRequired]="true" (loadImageB64)="saveCutImage($event)"
    [aspectRatio]="aspectRatio" #withCut>
</app-image-selector-with-cut>