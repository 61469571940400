import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit{
  title = 'site_in_construction';
  message = 'site_in_construction_description';
  icon = 'construction';

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe({
      next: value => {
        if (value['404']) {
          this.title = 'page_not_found'
          this.icon = 'block'
          this.message = 'page_not_found_description';
        }
      }
    });
  }
}
