/**
 * View List Enum
 *
 * Column type
 * Utilized in {@link ViewListsComponent}
 */
export enum ViewListEnum {
  /**
   * 
  */
  NORMAL,
  /**
   * 
   */
  PRINCIPAL
}

export enum ViewField{

  TP,

  NORMAL,

  ATTACHMENT
}

/**
* View List Enum
*
* Column type
* Utilized in {@link ViewListsComponent}
*/
export enum ViewListValueEnum {
  /**
   * Tipology
  */
  TP,
  /**
   * 
   */
  TP_BADGE,
  /**
 * 
 */
  TP_BADGE_SECOND_LEVEL,
  /**
* 
*/
  TP_BADGE_THIRD_LEVEL,
  /**
   * 
   */
  TP_STATUS_BADGE,
  /**
   * 
   */
  OBJECT,
  /**
 * 
 */
  OBJECT_SECOND_LEVEL,
  /**
   * 
   */
  TEXT,
  /**
   * 
   */
  BADGE,
  /**
   * 
   */
  URL,
}