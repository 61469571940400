<app-two-columns *transloco="let t" subtitle="Sistema gestor de contenido">
  <h2 class="fw-bold h2" >{{t('txt_log_in')}}</h2>
  <p class="mb-4 text-gray-600">{{t('txt_type_credentials')}}</p>
  <form class="">
    <div class="mb-3">
      <label for="sign-in-email" class="form-label">{{t('txt_email')}}</label>
      <div class="input-prefix-icon">
        <app-material-icon>mail</app-material-icon>
        <input [(ngModel)]="loginRequest.user" type="email" class="form-control" id="sign-in-email"
          name="sign-in-email" placeholder="{{t('txt_email_placeholder')}}" requerid>
      </div>
    </div>
    <div class="mb-3">
      <label for="sign-in-password" class="form-label">{{t('txt_password')}}</label>
      <div class="input-prefix-icon input-suffix-icon">
        <app-material-icon>lock</app-material-icon>
        <input [(ngModel)]="loginRequest.password" [type]="showPass ? 'text' : 'password'" class="form-control"
          id="sign-in-password" name="sign-in-password" placeholder="{{t('txt_password_placeholder')}}" requerid>
        <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle" (click)="togglePass()">
          <span class="show-option" [hidden]="showPass">visibility</span>
          <span class="hide-option" [hidden]="!showPass">visibility_off</span>
        </app-material-icon>
      </div>
    </div>
    <button [disabled]="!isValid()" (click)="doLogin()" type="submit" class="btn btn-primary w-100 mb-3">
      <span>{{t('txt_log_in')}}</span>
    </button>
    <button [routerLink]="['/password-recovery']" type="button" class="btn btn-text-primary ms-auto d-block mb-3">
      {{t('txt_recover_my_password')}}
    </button>
  </form>

  <div class="version text-center text-gray-500">v{{ version }}</div>
</app-two-columns>
