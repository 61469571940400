import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from "@angular/platform-browser";
import { UtilsService } from 'src/app/services/core/utils.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { PermissionsEnum } from 'src/global/permission-utils';
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent {

  @Input() title: string = "Selecciona una imagen";
  @Input() image!: string | SafeUrl;
  @Output() imageChange = new EventEmitter<string>();
  @Output() getImageUrl = new EventEmitter<string>();
  @Input() idInput: string = "customFileLangHTML";
  @Input() newUser: boolean = true;
  @Input() me: boolean = false;
  imageName: string = "";
  saving = false;
  saved = false;
  permissionsEnum = PermissionsEnum;


  @Input() cutImage: boolean = false;
  @Input() aspectRatio: number = 10 / 2; //examples: 21/9, 16/9, 4/3, 1/1

  constructor(
    private toaster: ToasterService,
    private utilService: UtilsService
  ) { }

  updateImage(event: any) {
    if (event.target.files[0] !== undefined) {
      this.saving = true;
      this.saved = false;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        if (typeof base64 === 'string') {
          this.persisImage(base64);
        }
      }
      reader.readAsDataURL(file);
    }
  }

  private persisImage(base64: string) {
    this.utilService.persistImage(base64).subscribe({
      next: (result) => {
        this.setImageUrl(result.fileUrl);
        this.saved = true;
        this.saving = false;
      }, error: () => {
        this.saved = false;
        this.saving = false;
        this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
      }
    });
  }

  private setImageUrl(url: string) {
    this.image = url;
    this.getImageUrl.emit(url);
    this.imageChange.emit(url);
  }

  openModalToCutImage(event: any, modal: any) {
    if (event.target.files[0] !== undefined) {
      modal.openModal(event);
    }
  }

  saveCutImage(imgB64: any) {
    this.saving = true;
    this.saved = false;
    this.persisImage(imgB64);
  }

}
