import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {
  AdmExportConfiguration,
  AdmExportConfigurationDetail,
  AdmExportConfigurationDto
} from "src/app/data/models/adm";
import { environment } from "src/environments/environment";

const baseUrl = environment.coreUrl + '/export-configurations';

@Injectable({
  providedIn: 'root'
})
export class AdmExportConfigurationService {

  constructor(private http: HttpClient) {
  }

  private getQueryParams(queryParams: any) {
    let params = new HttpParams();
    if (queryParams['permissionInternalId'] !== undefined && queryParams['permissionInternalId'])
      params = params.set('permissionInternalId', queryParams['permissionInternalId']);
    if (queryParams['tpExportFormatInternalId'] !== undefined && queryParams['tpExportFormatInternalId'])
      params = params.set('tpExportFormatInternalId', queryParams['tpExportFormatInternalId'])
    if (queryParams['configurationName'] !== undefined && queryParams['configurationName'])
      params = params.set('configurationName', queryParams['configurationName'])
    return params;
  }

  create(exportConfiguration: AdmExportConfiguration): Observable<AdmExportConfiguration> {
    return this.http.post<AdmExportConfiguration>(baseUrl, exportConfiguration);
  }

  findAll(queryParams: any): Observable<AdmExportConfigurationDto[]> {
    const params = this.getQueryParams(queryParams);
    return this.http.get<AdmExportConfigurationDto[]>(baseUrl, { params: params });
  }

  findDetailsByExportConfId(exportConfigurationId: number) {
    return this.http.get<AdmExportConfigurationDetail[]>(`${baseUrl}/${exportConfigurationId}/details`);
  }

  checkByPermissionAndConfigurationName(queryParams: any) {
    const params = this.getQueryParams(queryParams);
    return this.http.get<boolean>(`${baseUrl}/check`, { params: params });
  }

  deleteById(exportConfigurationId: number): Observable<any> {
    return this.http.delete(`${baseUrl}/${exportConfigurationId}`);
  }
}
