import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalComponent } from 'src/app/app-commons/components/modal/modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';

interface ImageData {
  url: string;
  title?: string;
  description?: string;
}

@Component({
  selector: 'app-image-selector-with-cut',
  templateUrl: './image-selector-with-cut.component.html',
  styleUrls: ['./image-selector-with-cut.component.scss'],
})
export class ImageSelectorWithCutComponent extends ModalComponent {
  @Output() loadImageB64 = new EventEmitter<ImageData>();
  @Output() getTitle = new EventEmitter<String>();
  @Output() getDescription = new EventEmitter<String>();
  @Input() aspectRatio: number = 16 / 9; //examples: 21/9, 16/9, 4/3, 1/1
  @Input() informationRequired: boolean = false;
  originalImage: any = '';
  croppedImage: any = '';
  title: string = '';
  description: string = '';

  constructor(
    modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private toaster: ToasterService
  ) {
    super(modalService);
  }

  openModal(event: any): void {
    this.originalImage = event;
    this.title = '';
    this.description = '';
    this.open();
  }

  imageCropped(event: ImageCroppedEvent) {
    let reader = new FileReader();
    if (event.blob) {
      reader.readAsDataURL(event.blob);

      reader.onloadend =  () => {
        let base64data = reader.result;
        this.croppedImage = base64data;
      };
      
    }
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  saveImage() {
    if (this.informationRequired) {
      if (!this.title) {
        this.toaster.show({
          type: ToasterEnum.ERROR,
          message: 'msg_error',
        });
      } else {
        const imageData: ImageData = {
          url: this.croppedImage,
          title: this.title,
          description: this.description,
        };

        this.loadImageB64.emit(imageData);
        this.close();
      }
    } else {
      const imageData: ImageData = {
        url: this.croppedImage,
      };

      this.loadImageB64.emit(imageData);
      this.close();
    }
  }
}
