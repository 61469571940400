import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdmPermissionDto } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';
import { PermissionTypeEnum } from 'src/global/permission-utils';

const baseUrl = environment.coreUrl + '/permissions';

@Injectable({
  providedIn: 'root'
})
export class AdmPermissionsService {

  constructor(
    private http: HttpClient
  ) { }

  listAll(queryParams: any): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    if (queryParams !== undefined) {
      if (queryParams['verify'] !== undefined)
        params = params.append('verify', queryParams['verify']);
      if (queryParams['isApproved'] !== undefined)
        params = params.append('isApproved', queryParams['isApproved']);
      if (queryParams['max'] !== undefined)
        params = params.append('max', queryParams['max']);
      if (queryParams['permissionType'] !== undefined)
        params = params.append('permissionType', queryParams['permissionType']);
      if (queryParams['status'] !== undefined)
        params = params.append('status', queryParams['status']);
    }
    return this.http.get<HttpResponse<any>>(baseUrl, { params: params, observe: 'response' });
  }

  findUserPermissions(): Observable<AdmPermissionDto[]> {
    return this.http.get<AdmPermissionDto[]>(`${baseUrl}/by-user`)
      .pipe(
        tap(permissions => {
          const permissionsJson = JSON.stringify(permissions);
          localStorage.setItem('snoissimrep', permissionsJson);
        })
      );
  }

  private getMyPermissionsLS(): AdmPermissionDto[] {
    let data = localStorage.getItem('snoissimrep');
    let permissions = data ? JSON.parse(data) as AdmPermissionDto[] : [];
    for (let i = 0; i < permissions.length; i++) {
      const element = permissions[i];
      element.childrenPermissions = [];
      for (let j = 0; j < permissions.length; j++) {
        const secondElement = permissions[j];
        if (secondElement.parentPermissionId == element.permissionId) {
          element.childrenPermissions.push(secondElement);
        }
      }
      element.childrenPermissions.sort((a, b) => a.priority - b.priority);
    }
    return permissions;
  }

  getMyPermissions(onlyWithRead: boolean = false): AdmPermissionDto[] {
    return this.getMyPermissionsLS().filter(x =>
      x.icon && (onlyWithRead ? x.readPermission : true)
    ).sort((a, b) => a.priority - b.priority);
  }

  findPermission(internalId: number): any {
    let permission = this.getMyPermissionsLS().find(element => element.internalId == internalId);
    return permission;
  }

  verifyPermissions(permissionInternalIds: number[], permissionType: PermissionTypeEnum): boolean {
    const perm = this.getMyPermissionsLS()
      .find(cp => {
        for (let internalId of permissionInternalIds) {
          if (cp.internalId !== internalId) {
            return false;
          }
          switch (permissionType) {
            case PermissionTypeEnum.READ: return cp.readPermission;
            case PermissionTypeEnum.CREATE: return cp.createPermission;
            case PermissionTypeEnum.UPDATE: return cp.updatePermission;
            case PermissionTypeEnum.DELETE: return cp.deletePermission;
            case PermissionTypeEnum.EXPORT: return cp.exportPermission;
          }
        }
        return false;
      });
    return perm != undefined;
  }

  getMyDefaultPathToNavigate() {
    let myPermissions = this.getMyPermissions(true);
    //remove duplicates
    myPermissions = myPermissions.filter((permission, index, self) =>
      index === self.findIndex((t) => (
        t.permissionId === permission.permissionId
      ))
    );
    if (myPermissions.length > 0 && myPermissions.length == 1) {
      return myPermissions[0].sref;
    }
    return '/home';
  }

  isNavigationAllowed(pathToNavigate: string): boolean {
    const permission = this.getMyPermissionsLS()
      .filter(p => p && p.sref.length > 1)
      .find(p => {
        return pathToNavigate === p.sref.trim() && p.readPermission
      });
    return permission != null || permission != undefined;
  }

}
