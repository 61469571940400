import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DbLoggableInterceptor } from 'src/app/conf/db-loggable.interceptor';
import { PageAuthOpInterceptor } from 'src/app/conf/page-auth-op.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './views/other/base/login/login.component';
import { FormsModule } from '@angular/forms';
import { BaseInterceptor } from './conf/base.interceptor';
import { SecureInterceptor } from './conf/secure.interceptor';
import { UnauthInterceptor } from './conf/unauth.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordRecoveryComponent } from './views/other/base/password-recovery/password-recovery.component';
import { ChangePasswordComponent } from './views/other/base/change-password/change-password.component';
import { HomeComponent } from './views/other/base/home/home.component';
import { ViewProfileComponent } from './views/other/base/profile/view-profile/view-profile.component';
import { EditProfileComponent } from './views/other/base/profile/edit-profile/edit-profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ListComponent } from './views/other/demo/list/list.component';
import { OrganizationTypeComponent } from './views/organization-type/organization-type.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { LoadingUiInterceptor } from './conf/loading-ui.interceptor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './views/other/demo/calendar/calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@ngneat/transloco';
import { CdkTreeModule } from '@angular/cdk/tree';
import { AppCommonsModule } from './app-commons/app-commons.module';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListComponent,
    PasswordRecoveryComponent,
    ChangePasswordComponent,
    HomeComponent,
    ViewProfileComponent,
    EditProfileComponent,
    OrganizationTypeComponent,
    CalendarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ImageCropperModule,
    NgSelectModule,
    BreadcrumbModule,
    TranslocoRootModule,
    UiSwitchModule,
    DigitOnlyModule,
    CdkTreeModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AppCommonsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DbLoggableInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PageAuthOpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingUiInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslocoService) => translate.getActiveLang(),
      deps: [TranslocoService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
