import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { PasswordRecoveryService } from 'src/app/services/core/password-recovery.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { environment } from "src/environments/environment";
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  version = environment.version;

  constructor(
    private auth: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private passwordRecoveryService: PasswordRecoveryService,
    private permissionService: AdmPermissionsService
  ) { }

  loginRequest = { user: '', password: '' };
  showErrorLogin = false;
  showPass = false;

  doLogin(): void {
    this.auth.doLogin(
      this.loginRequest,
      profile => {
        const mainRole = (profile?.roles || []).sort((p1, p2) => p1.priority - p2.priority)[0];
        if (mainRole) {
          this.toaster.show({ message: "Inicio de sesion exitoso", header: "Éxito", type: ToasterEnum.SUCCESS });
          let defaultPath = this.permissionService.getMyDefaultPathToNavigate();
          this.router.navigate([defaultPath]);
          return;
        }
        this.toaster.show({ message: "Inicio de sesion exitoso", header: "Éxito", type: ToasterEnum.SUCCESS });
      },
      error => {
        this.showErrorLogin = true;
        switch (error.status) {
          case 500:
            this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
            break;
          case 400:
            if (error.error === 'require_change_password') {
              this.passwordRecoveryService.resetPasswordWithouSendMail(this.loginRequest.user).subscribe({
                next: (newUser: any) => {
                  this.router.navigate(['/change-password' + newUser.urlLogin]);
                  this.toaster.show({ message: "Debe cambiar su contraseña", header: "Información ", type: ToasterEnum.WARNING });
                }, error: _ => {
                  this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
                }
              })
            } else {
              this.toaster.show({ message: error.error,  type: ToasterEnum.ERROR });
            }
            break;
          case 401:
            this.toaster.show({ message: "Credenciales incorrectas",  type: ToasterEnum.ERROR });
            break;
          default:
            this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
            break;
        }
      }
    );
  }

  isValid() {
    return this.loginRequest.user && this.loginRequest.password;
  }

  togglePass() {
    this.showPass = !this.showPass;
  }
}
