import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdmPageAuthOpDto, AdmPageAuthStepLogOpDto, AdmPageAuthStepOpDto } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/page-authorization-ops';

@Injectable({
  providedIn: 'root'
})
export class AdmPageAuthOpService {

  constructor(private http: HttpClient) {
  }

  findAll(queryParams: any) {
    let params = new HttpParams();
    if (queryParams['inReview'] !== undefined)
      params = params.append('inReview', queryParams['inReview']);
    if (queryParams['permission'] !== undefined)
      params = params.append('permission', queryParams['permission']);
    if (queryParams['parent'] !== undefined)
      params = params.append('parent', queryParams['parent'])
    if (queryParams['hashId'] != undefined && queryParams['hashId'] != '')
      params = params.append('hashId', queryParams['hashId'])
    if (queryParams['entityHashId'] != undefined && queryParams['entityHashId'] != '')
      params = params.append('entityHashId', queryParams['entityHashId'])
    if (queryParams['tpPageAuthorizationType'] !== undefined)
      params = params.append('tpPageAuthorizationType', queryParams['tpPageAuthorizationType'])
    if (queryParams['tpAuthorizationStatus'] !== undefined)
      params = params.append('tpAuthorizationStatus', queryParams['tpAuthorizationStatus'])
    if (queryParams['mine'] !== undefined)
      params = params.append('mine', queryParams['mine'])
    if (queryParams['userHashId'] != undefined && queryParams['userHashId'] != '')
      params = params.append('userHashId', queryParams['userHashId']);
    if (queryParams['entryDate'] != undefined && queryParams['entryDate'] != '')
      params = params.append('entryDate', queryParams['entryDate']);
    if (queryParams['columnOrder'] != undefined && queryParams['columnOrder'] != '')
      params = params.append('columnOrder', queryParams['columnOrder']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);

    return this.http.get<AdmPageAuthOpDto[]>(`${baseUrl}`, { params: params, observe: 'response' });
  }

  findAllSteps(queryParams: any) {
    let params = new HttpParams();
    if (queryParams['hashId'] != undefined && queryParams['hashId'] != '')
      params = params.append('hashId', queryParams['hashId']);
    if (queryParams['entityHashId'] != undefined && queryParams['entityHashId'] != '')
      params = params.append('entityHashId', queryParams['entityHashId']);
    if (queryParams['pending'] !== undefined)
      params = params.append('pending', queryParams['pending']);
    if (queryParams['tpAuthorizationStatus'] != undefined && queryParams['tpAuthorizationStatus'] != '')
      params = params.append('tpAuthorizationStatus', queryParams['tpAuthorizationStatus']);
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);

    return this.http.get<AdmPageAuthStepOpDto[]>(`${baseUrl}/steps`, { params: params, observe: 'response' });
  }

  findAllStepLogs(queryParams: any) {
    let params = new HttpParams();
    if (queryParams['hashId'] != undefined && queryParams['hashId'] != '')
      params = params.append('hashId', queryParams['hashId']);
    if (queryParams['entityHashId'] != undefined && queryParams['entityHashId'] != '')
      params = params.append('entityHashId', queryParams['entityHashId']);
    if (queryParams['pageAuthStepOpId'] !== undefined)
      params = params.append('pageAuthStepOpId', queryParams['pageAuthStepOpId']);
    if (queryParams['stepTpAuthStatus'] != undefined && queryParams['stepTpAuthStatus'] != '')
      params = params.append('stepTpAuthStatus', queryParams['stepTpAuthStatus']);
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);

    return this.http.get<AdmPageAuthStepLogOpDto[]>(`${baseUrl}/logs`, {
      params: params,
      observe: 'response'
    });
  }
}
