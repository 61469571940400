<div class="container-fluid two-columns-container">
  <div class="row">
    <div class="d-none position-relative col-lg-4 text-white left-column d-lg-flex flex-column gap-3 p-5 gradient-backgroud">
      <img src="{{'../../../../assets/' + profile +'/logo/logo-white.svg'}}" alt="" width="200px" class="logo mt-5">
      <h2 *ngIf="subtitle" class="text-white fw-bold">
        {{subtitle}}
      </h2>
      <div class="position-absolute" style="bottom:0;left:0;margin:0;">
        <img src="{{'../../../../assets/' + profile +'/illustrations/xela.png'}}" alt="" class="w-100">
      </div>
    </div>
    <div class="col-12 col-lg bg-white right-column d-flex align-items-center justify-content-center">
      <div class="content d-flex flex-column col-12  col-md-8 col-xl-6 col-xxl-5">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
