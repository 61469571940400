import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdmTypology } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/typologies';

@Injectable({
  providedIn: 'root'
})
export class AdmTypologiesService {

  constructor(
    private http: HttpClient
  ) { }

  listByParentInternalId(internalId: number): Observable<AdmTypology[]> {
    return this.http.get<AdmTypology[]>(`${baseUrl}/by-parent/${internalId}`);
  }

  listByParentInternalIdAndValue1(internalId: number, value1:string): Observable<AdmTypology[]> {
    let params = new HttpParams();
    params = params.append('internalId', internalId);
    params = params.append('value1', value1);

    return this.http.get<AdmTypology[]>(`${baseUrl}/by-parent-and-value-1`, {
      params: params
    });
  }

  listByParentInternalIdAndOrderBy(internalId: number, columnOrder:string): Observable<AdmTypology[]> {
    let params = new HttpParams();
    params = params.append('internalId', internalId);
    params = params.append('columnOrder', columnOrder);

    return this.http.get<AdmTypology[]>(`${baseUrl}/by-parent-and-order-by`, {
      params: params
    });
  }

  getByInternalId(internalId: number): Observable<AdmTypology> {
    return this.http.get<AdmTypology>(`${baseUrl}/${internalId}`);
  }

}
