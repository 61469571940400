<ng-container *ngIf="viewFieldEnum.NORMAL==viewFieldType">
    <div *transloco="let t">
        <div class="mb-3">
            <label class="form-label fs-14 text-gray-600">{{t(label)}}</label>
            <div *ngIf="value; then code; else noCode"></div>
            <ng-template #code>
                <p>{{value}}</p>
            </ng-template>
            <ng-template #noCode>
                <p class="text-gray-600">{{t('txt_no_information')}}</p>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="viewFieldEnum.TP==viewFieldType">
    <div *transloco="let t">
        <div class="mb-3">
            <label class="form-label fs-14 text-gray-600">{{t(label)}}</label>
            <div *ngIf="value; then code; else noCode"></div>
            <ng-template #code>
                <p>{{t(value.description)}}</p>
            </ng-template>
            <ng-template #noCode>
                <p class="text-gray-600">{{t('txt_no_information')}}</p>
            </ng-template>
        </div>
    </div>
</ng-container>



<ng-container *ngIf="viewFieldEnum.ATTACHMENT==viewFieldType">
    <div *transloco="let t">
        <div class="mb-3">
            <label class="form-label fs-14 text-gray-600">{{t(label)}}</label>
            <div *ngIf="value; then code; else noCode"></div>
            <ng-template #code>
                <button (click)="goToLink(value)" class="btn btn-gray">
                    <app-material-icon class="btn text-primary btn-sm d-inline-flex type-toggle">
                        visibility
                    </app-material-icon>
                </button>
                <p>{{t('visibility')}}</p>
            </ng-template>
            <ng-template #noCode>
                <p class="text-gray-600">{{t('txt_no_information')}}</p>
            </ng-template>
        </div>
    </div>
</ng-container>