import { Component, EventEmitter, Injectable, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { ListSendValuesConfig, ValueConfig } from 'src/app/app-commons/components/lists/list-config';
import { AdmTypology } from 'src/app/data/models/adm';
import { UtilsService } from 'src/app/services/core/utils.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ListEnum } from 'src/global/list-enum';
import { ToasterEnum } from 'src/global/toaster-enum';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}


/**
 * Dynamic list component.
 *
 * If it's a creation, just send in value the array in which the information will be stored.
 * If it is an edition, send in value the array with the data, the component will transform the information.
 *
 * The data will come back transformed, use the transformation service to get a list with the structure of the objects.
 */
@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
})
export class ListsComponent implements OnChanges {

  /**
   * list of configurations
   */
  @Input() config!: ListSendValuesConfig;
  @Output() inputChanged = new EventEmitter<any>();
  @Output() removedRow = new EventEmitter<any>();
  @Output() dataEvent = new EventEmitter();

  constructor(
    private toaster: ToasterService,
    private utilService: UtilsService,
    private translocoService: TranslocoService
  ) { }

  listEnum = ListEnum;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      if (this.config.values.length > 0 && !this.config.values[0].row) {
        let info = JSON.parse(JSON.stringify(this.config.values));
        this.config.values = [];
        for (let i = 0, sizeI = info.length; i < sizeI; i++) {
          this.addItem(true, info[i]);
          this.verifyData();
        }
      }

      if (this.config.isCreation && this.config.defaultRows > 0) {
        if (this.config.values.length === 0) {
          for (let i = 0; i < this.config.defaultRows; i++) {
            this.addItem(false, undefined);
          }
        }
      }
    }
  }

  counter() { return new Array(this.config.configValues.length); }

  addItem(canBeDeleted: boolean, defaultData: any) {
    this.verifyData();

    let row = [];
    for (let i = 0, size = this.config.configValues.length; i < size; i++) {
      let value: any = {};
      let key = this.config.configValues[i].fieldName;
      value[key] = defaultData ? defaultData[this.config.configValues[i].fieldName] : this.getDefaultValue(this.config.configValues[i].type);
      row.push(value);
    }
    let data = new ValueConfig(canBeDeleted, row, defaultData ? true : false);
    this.config.values.push(data);
    this.checkCompleteRows();
  }

  searchTp = (term: string, item: any) => {
    return this.translocoService.translate(item.description).toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  compareWithTp = (item: AdmTypology, selected: number) => {
    return item.typologyId === selected;
  }

  deleteItem(position: number) {
    if (this.config.values[position].canBeDeleted) {
      this.config.values.splice(position, 1);
      this.verifyData();
      this.checkCompleteRows();
      this.removedRow.emit(this.config.values[position]);
    }
    if (this.config.showTotal) {
      this.config.total = 0;
      for (let i = 0; i <= this.config.values.length; i++) {
        let rowTemp = this.config.values[i];
        let result = parseInt(Object.values(rowTemp.row[this.config.configValues[this.config.configValues.length - 1].storageResult])[0] as string);
        result = result ? result : 0;
        this.config.total += result;
      }
    }
  }

  onChange(positionJ: number, positionI: number) {
    setTimeout(() => {
      let affectChildren = this.config.configValues[positionI].affectChildren &&
        (this.config.configValues[positionI].type === ListEnum.UNIQUE_TP_WITH_RESTRICTION || this.config.configValues[positionI].type === ListEnum.UNIQUE_OBJ_WITH_RESTRICTION);
      if (affectChildren) {

        for (let i = 0; i < this.config.configValues.length; i++) {
          if (this.config.configValues[i].affectedByParent && this.config.configValues[i].parentPosition === positionI) {
            this.config.values[positionJ].row[i][this.config.configValues[i].fieldName] = undefined;

          }
        }
      }

      // Para multiplicar dos campos
      if (this.config.configValues[positionI].type === ListEnum.NUMBER_ALL_DECIMAL && this.config.configValues[positionI].operation === ListEnum.MULTIPLICATION) {
        let row = this.config.values[positionJ];
        let operator1 = row.row[positionI][this.config.configValues[positionI].fieldName];
        setTimeout(() => {
          row.row[positionI][this.config.configValues[positionI].fieldName] = parseFloat(row.row[positionI][this.config.configValues[positionI].fieldName]).toFixed(2) + "";

        }, 3000)
        let operator2 = row.row[this.config.configValues[positionI].position][this.config.configValues[this.config.configValues[positionI].position].fieldName];
        operator1 = operator1 ? operator1 : 1;
        operator2 = operator2 ? operator2 : 1;
        let result = (operator1 * operator2).toFixed(2) + "";


        row.row[this.config.configValues[positionI].storageResult][this.config.configValues[this.config.configValues[positionI].storageResult].fieldName] = result;

      }

      // para restar dos campos
      if (this.config.configValues[positionI].type === ListEnum.NUMBER_ALL_DECIMAL && this.config.configValues[positionI].operation === ListEnum.SUBTRACTION
        && this.config.configValues[positionI].position > -1) {

        let row = this.config.values[positionJ];
        let operator1 = row.row[positionI][this.config.configValues[positionI].fieldName];
        setTimeout(() => {
          row.row[positionI][this.config.configValues[positionI].fieldName] = parseFloat(row.row[positionI][this.config.configValues[positionI].fieldName]).toFixed(2) + "";
        }, 3000)
        let operator2 = row.row[this.config.configValues[positionI].position][this.config.configValues[this.config.configValues[positionI].position].fieldName];

        operator1 = operator1 ? operator1 : 0;
        operator2 = operator2 ? operator2 : 0;

        let result = operator2 - operator1;
        row.row[this.config.configValues[positionI].storageResult][this.config.configValues[this.config.configValues[positionI].storageResult].fieldName] = result;

      }
      //para restarle a un valor fijo
      if (this.config.configValues[positionI].type === ListEnum.NUMBER_ALL_DECIMAL && this.config.configValues[positionI].operation === ListEnum.SUBTRACTION
        && this.config.configValues[positionI].position === -2) {
        this.config.total = parseFloat(this.config.total + "");
        let row = this.config.values[positionJ];
        let operator1 = row.row[positionI][this.config.configValues[positionI].fieldName];
        setTimeout(() => {
          row.row[positionI][this.config.configValues[positionI].fieldName] = parseFloat(row.row[positionI][this.config.configValues[positionI].fieldName]).toFixed(2) + "";
        }, 3000)
        this.config.total -= operator1;
        row.row[this.config.configValues[positionI].storageResult][this.config.configValues[this.config.configValues[positionI].storageResult].fieldName] = this.config.total;
        row.row[this.config.configValues[positionI].storageResult][this.config.configValues[this.config.configValues[positionI].storageResult].fieldName] = row.row[this.config.configValues[positionI].storageResult][this.config.configValues[this.config.configValues[positionI].storageResult].fieldName].toFixed(2) + "";
      }

      //suma los valores de una columna
      if (this.config.showTotal && this.config.sumColumn !== -1) {
        this.config.total = 0;
        for (let i = 0; i < this.config.values.length; i++) {
          let rowTemp = this.config.values[i];
          //let result = parseFloat(rowTemp.row[this.config.sumColumn][this.config.configValues[this.config.sumColumn].fieldName])
          let result=((rowTemp.row[3].quantity?parseFloat(rowTemp.row[3].quantity):0)*(rowTemp.row[4].unitAmount?parseFloat(rowTemp.row[4].unitAmount):0))-(rowTemp.row[6].discount?parseFloat(rowTemp.row[6].discount):0);
          rowTemp.row[7].amount=result?result.toFixed(2):0.00;
          result = result ? parseFloat(result.toFixed(2)) : 0.00;
          this.config.total += parseFloat(result.toFixed(2));
        }
        this.dataEvent.emit({ total: this.config.total.toFixed(2) });
      }

      if (this.config.limit > 0) {
        if (!(this.config.limit >= this.config.total)) {
          this.dataEvent.emit({ limit: true });
        }
      }

      this.verifyData();
      let row = this.config.values[positionJ];
      row.isComplete = false;
      this.inputChanged.emit(row.row)
      for (let i = 0, sizeI = row.row.length; i < sizeI; i++) {
        let value = row.row[i][this.config.configValues[i].fieldName];
        let type = this.config.configValues[i].type;
        let isOptional = this.config.configValues[i].isOptional;
        if (isOptional) {
          continue;
        }
        if (type === ListEnum.TEXT && !value) {
          return;
        }
        if ((type === ListEnum.UNIQUE_TP || type === ListEnum.UNIQUE_TP_WITH_RESTRICTION) && value === undefined) {
          return;
        }
        if (type === ListEnum.DATE && value === undefined) {
          return;
        }
        if (type === ListEnum.UNIQUE_OBJ && value === undefined) {
          return;
        }
        if ((type === ListEnum.NUMBER_ALL || type === ListEnum.NUMBER_POSITIVE || type === ListEnum.NUMBER_ALL_DECIMAL
          || type === ListEnum.NUMBER_POSITIVE_DECIMAL) && !value) {
          return;
        }
        if (type === ListEnum.TIME && !value) {
          return;
        }
      }
      row.isComplete = true;
      this.checkCompleteRows();
    }, 200);

    if (this.config.configValues[positionI + 2] && this.config.configValues[positionI + 2].useDefaultValue) {
      let rowTemp = this.config.values[positionJ];
      rowTemp.row[positionI + 2][this.config.configValues[positionI + 2].fieldName] = this.config.configValues[positionI + 2].value;
      this.onChange(positionJ, positionI + 2);

    }
  }

  checkDateRestriction(currentPosition: number, checkMin: boolean, row: number) {
    let today = moment().format('DD-MM-YYYY');
    let position = checkMin ? this.config.configValues[currentPosition].minDatePosition : this.config.configValues[currentPosition].maxDatePosition;
    if (position) {
      let date = this.config.values[row].row[position][this.config.configValues[position].fieldName];
      let parseDate = this.parse(date);

      if (this.config.configValues[currentPosition].todayRestriction) {
        if (moment(today, 'DD-MM-YYYY').isAfter(moment(date, 'DD-MM-YYYY'))) {
          parseDate = this.parse(today);
        }
      }

      if (parseDate) {
        return parseDate;
      }
    }

    if (this.config.configValues[currentPosition].todayRestriction && checkMin) {
      let parseDate = this.parse(today);
      if (parseDate) {
        return parseDate;
      }
    }

    return checkMin ? { year: 1900, month: 1, day: 1 } : { year: 3000, month: 1, day: 1 };
  }

  loadFile(event: any, positionJ: number, positionI: number) {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result;
        if (base64) {
          this.utilService.persistImage(base64 + '').subscribe({
            next: (result) => {
              this.config.values[positionJ].row[positionI][this.config.configValues[positionI].fieldName] = result.fileUrl;
              this.onChange(positionJ, positionI);
            }, error: () => {
              this.toaster.show({ message: "msg_error", type: ToasterEnum.ERROR });
            }
          });
        }
      };
    }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getTpValuesWithRestriction(positionJ: number, positionI: number) {
    if (this.config.configValues[positionI].affectChildren) {
      return this.config.configValues[positionI].values;
    }

    let parentPosition = this.getParentItemSelectedTp(positionJ, this.config.configValues[positionI].parentPosition);
    if (parentPosition >= 0) {
      for (let i = 0; i < this.config.configValues.length; i++) {
        if (this.config.configValues[i].affectedByParent) {
          return this.config.configValues[i].auxValues[parentPosition];
        }
      }
    }
    return [];
  }

  getObjValuesWithRestriction(positionJ: number, positionI: number) {

    if (this.config.configValues[positionI].affectChildren && !this.config.configValues[positionI].affectedByParent) {
      return this.config.configValues[positionI].values;
    }

    let parentPosition = this.getParentItemSelectedObj(positionJ, this.config.configValues[positionI].parentPosition);


    if (parentPosition >= 0) {
      for (let i = 0; i < this.config.configValues.length; i++) {
        if (positionI === i && this.config.configValues[i].affectedByParent) {

          return this.config.configValues[i].auxValues[parentPosition];
        }
      }
    }

    return [];
  }

  private verifyData() {
    for (let i = 0, size = this.config.configValues.length; i < size; i++) {
      if (this.config.configValues[i].uniqueValues) {
        this.getObjectListValues(i);
      }
    }
  }

  private getDefaultValue(type: ListEnum) {
    switch (type) {
      case ListEnum.TEXT:
        return "";
      case ListEnum.TIME:
        return "12:00";
      default:
        return undefined;
    }
  }

  private getAddedListValues(position: number) {
    let data = [];
    for (let i = 0, size = this.config.values.length; i < size; i++) {
      let value = this.config.values[i].row[position][this.config.configValues[position].fieldName];
      if (value !== undefined) {
        data.push(value);
      }
    }
    return data;
  }

  private getObjectListValues(position: number) {
    if (this.config.configValues[position].uniqueValues) {
      let data = this.getAddedListValues(position);

      for (let obj of this.config.configValues[position].values) {
        let exist = false;
        for (const item of data) {
          if (item === obj[this.config.configValues[position].bindValue || '']) {
            obj.disabled = true;
            exist = true;
            break;
          }
        }
        if (!exist) {
          obj.disabled = false;
        }
      }
      this.config.configValues[position].values = [...this.config.configValues[position].values]
    }
  }

  private parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split("-");
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  private checkCompleteRows() {
    for (let i = 0, size = this.config.values.length; i < size; i++) {
      if (!this.config.values[i].isComplete) {
        this.config.completedRows = false;
        return;
      }
    }
    this.config.completedRows = true;
    return;
  }

  markAsTouched(d: any, input: any) {
    d.toggle();
    input.control.markAsTouched();
  }

  private getParentItemSelectedTp(positionJ: number, positionI: number) {
    let parent = this.config.values[positionJ].row[positionI][this.config.configValues[positionI].fieldName];
    let parentOptions = this.config.configValues[positionI].values;

    if (!parent) {
      return -1;
    }

    for (let i = 0, size = parentOptions.length; i < size; i++) {
      if (parent.internalId === parentOptions[i].internalId) {
        return i;
      }
    }
    return -1;
  }

  private getParentItemSelectedObj(positionJ: number, positionI: number) {
    let parent = this.config.values[positionJ].row[positionI][this.config.configValues[positionI].fieldName];
    //let parentOptions = this.config.configValues[positionI].values;
    let parentOptions = this.getObjValuesWithRestriction(positionJ, positionI);

    if (!parent) {
      return -1;
    }

    for (let i = 0, size = parentOptions.length; i < size; i++) {
      if (parent === parentOptions[i][this.config.configValues[positionI].bindValue + '']) {
        return i;
      }
    }
    return -1;
  }

}
