import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordRecoveryService } from 'src/app/services/core/password-recovery.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  email: string = '';

  constructor(
    private passRecoveryService: PasswordRecoveryService,
    private toaster: ToasterService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  sendEmail() {
    this.passRecoveryService.resetPassword(this.email).subscribe(
      response => {
        this.toaster.show({ message: "Proceso exitoso. Se ha enviado un correo electrónico", header: "Exito", type: ToasterEnum.SUCCESS });
        this.email = '';
        this.router.navigate(['']);
      }, error => {
        switch (error.status) {
          case 500:
            this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
            break;
          case 400:
            this.toaster.show({ message: "No se encontro el correo electrónico",  type: ToasterEnum.ERROR });
            break;
          default:
            this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
            break;
        }
      }
    );
  }

  isValid(): boolean {
    return this.email.length > 0;
  }


}
