import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';

@Injectable()
export class PageAuthOpInterceptor implements HttpInterceptor {

  constructor(private toastService: ToasterService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method !== 'PUT' && request.method !== 'DELETE') {
      return next.handle(request);
    }

    return next.handle(request)
      .pipe(
        catchError((e: any, caught: Observable<any>) => {
          if (e instanceof HttpErrorResponse && e.status == 400 && e.error == 'entity_has_pending_page_auth_op') {
            this.toastService.show({ type: ToasterEnum.ERROR, message: 'txt_item_has_already_a_pending_page_auth' });
            return EMPTY;
          }
          throw e;
        })
      );
  }
}
