/**
 * @example
 * new TabConfig("txt_general_info", true, false)
 */
export class TabConfig {
    constructor(
        /**
         * Tab name.
         * Not optional
         */
        public name: string,

        /**
         * Check if it is the current view.
         * Not optional.
         */
        public view: boolean,

        /**
         * Required for when the view is required to be completed.
         * If navigation is free it is optional.
         */
        public complete: boolean | undefined
    ) { }
}