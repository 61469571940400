import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { PermissionsEnum, PermissionTypeEnum } from 'src/global/permission-utils';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';

@Directive({
  selector: '[appCheckPermission]'
})
export class CheckPermissionDirective implements OnInit {

  @Input() appCheckPermission: number = PermissionsEnum.NULL;
  @Input() multiplePermissions: number[] = [];
  @Input() permissionType: PermissionTypeEnum = PermissionTypeEnum.NONE;
  @Input() multiplePermissionTypes: PermissionTypeEnum[] = [];

  constructor(
    private elRef: ElementRef,
    private admPermissionsService: AdmPermissionsService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    if (this.appCheckPermission == PermissionsEnum.NONE) {
      return;
    }

    /**
     // TODO: remove this after check permissions in all buttons
     if (this.permissionType === PermissionTypeEnum.NONE) {
      return;
    }

     const p = document.createElement('span');
     p.innerText = '1. '
     this.renderer.insertBefore(this.elRef.nativeElement, p, this.elRef.nativeElement.firstChild);
     */

    if (this.appCheckPermission !== PermissionsEnum.NULL) {
      this.multiplePermissions.push(this.appCheckPermission);
    }
    let result = false;
    if (this.multiplePermissionTypes.length > 0) {
      for (const permission of this.multiplePermissionTypes) {
        result = result || this.admPermissionsService.verifyPermissions(this.multiplePermissions, permission);
      }
    } else {
      result = this.admPermissionsService.verifyPermissions(this.multiplePermissions, this.permissionType);
    }
    if (!result) {
      this.elRef.nativeElement?.remove();
    }
  }
}
