import { Component } from '@angular/core';
import { MapInfo } from 'src/app/app-commons/components/map/map.component';
import { AdmRole } from 'src/app/data/models/adm';
import { AdmRolesService } from 'src/app/services/core/adm-roles.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { HeaderEnum } from 'src/global/header-enum';
import { PermissionsEnum } from 'src/global/permission-utils';
import { ToasterEnum } from "src/global/toaster-enum";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  /**
   * Whether to show the map or not, default -> false
   */
  showMap: boolean = true;
  demoMapInfo: MapInfo[] = [
    { entityHashId: 'abc', latitude: 15.505, longitude: -89.1012, label: 'Nombre proveedor' },
    { entityHashId: 'xzy', latitude: 14.405, longitude: -90.4884, label: 'Nombre proveedor' },
    { entityHashId: 'fda', latitude: 15.200, longitude: -91.281, label: 'Nombre proveedor' },
    { entityHashId: 'efd', latitude: 14.591, longitude: -89.311, label: 'Nombre proveedor' },
    { entityHashId: 'fda', latitude: 14.501, longitude: -91.301, label: 'Nombre proveedor' },
    { entityHashId: 'xyz', latitude: 14.840364804836518, longitude: -91.52742431913872, label: 'Nombre proveedor' }
  ];

  admRoleList: { selected: boolean, admRole: AdmRole }[] = [];

  maximum = 10;
  page = 1;
  permissionsEnum = PermissionsEnum;
  headerEnum = HeaderEnum;

  filters = {
    max: this.maximum,
    name: '',
    page: 1,
    collectionSize: 0,
    asc: true,
    columnOrder: 'name'
  };

  constructor(
    private admRoleService: AdmRolesService,
    private toaster: ToasterService
  ) { }

  ngOnInit(): void {
    this.getFilters();
    this.findAllAdmRoles();
  }

  findAllAdmRoles() {
    this.admRoleService.listAll(this.filters).subscribe(
      response => {
        // this.admRoleList = response.body.items;
        this.admRoleList = (response.body.items ?? []).map((role: AdmRole) => {
          return {
            selected: false,
            admRole: role
          }
        })
        this.filters.collectionSize = response.body.count;
      }, error => {
        this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
      }
    );
  }

  onPageChange() {
    localStorage.setItem(this.constructor.name, JSON.stringify(this.filters));
    this.findAllAdmRoles();
  }

  deleteRole(hashId: string) {
    if (confirm("Está seguro de continuar?")) {
      this.admRoleService.softDelete(hashId).subscribe({
        next: _ => {
          this.toaster.show({ message: "msg_success_changes_saved", header: "Exito", type: ToasterEnum.SUCCESS });
          this.findAllAdmRoles();
        }, error: _ => {
          this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
        }
      });
    }
  }

  sort(name: string) {
    if (this.filters.columnOrder === name) {
      this.filters.asc = !this.filters.asc;
    } else {
      this.filters.columnOrder = name;
      this.filters.asc = true;
    }
    this.findAllAdmRoles();
  }

  checkSort(name: string, sort: boolean | undefined) {
    if (name === this.filters.columnOrder) {
      return sort === undefined ? true : (sort === this.filters.asc);
    }
    return false;
  }

  private getFilters() {
    const json = localStorage.getItem(this.constructor.name);
    if (!json) {
      return;
    }

    const tmp = JSON.parse(json);
    if (typeof tmp === 'object') {
      this.filters = JSON.parse(json);
    }
  }


  get isRowSelected() {
    return this.admRoleList.some(role => role.selected);
  }

  get totalSelected() {
    return this.admRoleList.filter(role => role.selected).length;
  }

  get isAllSelected() {
    return this.admRoleList.length == this.totalSelected;
  }

  onDeselectedAll() {
    this.admRoleList.forEach(role => role.selected = false);
  }

  onSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.admRoleList.forEach(role => role.selected = checked);
  }

  onDelete() {
    const roles = this.admRoleList.filter(role => role.selected).map(role => role.admRole);
  }

  isNavbarActive = false;
  showNavbar(){
    if(this.isNavbarActive){
      this.isNavbarActive=false;
    }else{
      this.isNavbarActive=true
    }
  }

  onMapClick(hashId: string) {
    window.alert(`hashId: ${hashId}`);
  }
}
