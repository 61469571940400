import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingControlService } from 'src/app/app-commons/components/loading-indicator/loading-control.service';

@Injectable()
export class LoadingUiInterceptor implements HttpInterceptor {

  constructor(
    private loadingControlService: LoadingControlService
  ) { }

  private countRequest = 0;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.countRequest++;
    this.loadingControlService.showLoadingIndicator();
    return next.handle(request).pipe(
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          this.loadingControlService.hideLoadingIndicator();
        }
      })
    );
  }
}
