<ng-template *transloco="let t" #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{t(title)}}
        </h5>
        <button class="close btn btn-outline-primary" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="bodyQuestion">{{t(bodyQuestion)}}</p>
        <p *ngIf="bodyTxt">{{t(bodyTxt)}}</p>
        <pre *ngIf="bodyTxtFunction">{{functionReturn}}</pre>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="close()"
            data-dismiss="modal">{{t('cancel')}}</button>
        <button type="button" class="btn" (click)="confirmBtn()"
            [ngClass]="{'btn-primary': btnEnum.CONFIRM === btnType, 'btn-danger': btnEnum.DELETE === btnType}">
            {{t(btnTxt)}}
        </button>
    </div>
</ng-template>