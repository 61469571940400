import { debounce } from "@agentepsilon/decko";
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';



/**
 * Component to control a treeview alike list (list of lists/list with children)
 * The 'TreeView' allows drag and drop to reorder the list or set inside any node or parent
 * This component has customizable add, edit, remove buttons and texts to have a better control of nodes.
 *
 * Example of a simple Draggable tree for a list called 'acChartElements' with children list named 'children'
 * @Example
 * ```html
 *  <app-draggable-tree
 *    [childrenPropertyName]="'children'"
 *    [nodeTextPropertyName]="'name'"
 *    [keyPropertyName]="'name'"
 *    (updateItemsList)="update($event)"
 *    [items]="accChartElements" />
 * ```
 *
 * @Example
 * Example for a 'Treeview' with NO drag and drop feature.
 * ```html
 *  <app-draggable-tree
 *    [title]="t('txt_chart_elements')"
 *    [childrenPropertyName]="'children'"
 *    [nodeTextPropertyName]="'name'" [keyPropertyName]="'name'" [showAddButton]="false"
 *    [showAddSonButton]="false" [showEditSonButton]="false" [showRemoveSonButton]="false"
 *    [allowDragAndDrop]="false"
 *    [items]="chartAccount.accChartElements" />
 * ```
 *
 * @Example
 * Example for a draggable-tree with custom titles and texts that allows inserting new nodes and son-nodes
 * ```html
 *  <app-draggable-tree [title]="t('txt_chart_elements')"
 *    [btnAddTxt]="t('txt_add_chartelement')" [childrenPropertyName]="'children'"
 *    [nodeTextPropertyName]="'name'" [keyPropertyName]="'name'" [showAddButton]="true"
 *    [showAddSonButton]="true" [showEditSonButton]="true" [showRemoveSonButton]="true"
 *    (updateItemsList)="update($event)" (addNodeAction)="addChartElement.open()"
 *    (addSonNodeAction)="fatherChartElement = $event; addChartElement.open()"
 *    (editSonNodeAction)="setChartElement($event); addChartElement.open()"
 *    (removeSonNodeAction)="appConfirmDeleteElement.openModal($event)"
 *    [items]="chartAccount.accChartElements" />
 * ```
 */




export interface TreeNode {
  id: string;
  entity: any;
  text: string;
  isExpanded: boolean;
  isBottomSon: boolean;
}

export interface DropInfo {
  targetId: string | null;
  action?: string;
}


@Component({
  selector: 'app-draggable-tree',
  templateUrl: './draggable-tree.component.html',
  styleUrls: ['./draggable-tree.component.scss']
})
export class DraggableTreeComponent implements OnChanges {

  _items: any[] = [];

  //NOT optional -  items: The main Item list
  @Input() set items(items: any[]) {
    this._items = items ?? [];
    this._items.forEach((item: any) => {
      this.prepareTreeNodes(undefined, item);
    });
    this.prepareDragDrop(this.nodes)
  }

  //NOT Optional - childrenPropertyName: The name of the property child list (Ex. 'mySonList' is the child property name in myItemList.mySonList)
  @Input() childrenPropertyName: string = 'children';

  //NOT Optional - keyPropertyName: The property name that stores a unique value in a item (such as id)
  @Input() keyPropertyName: string = 'id';

  //NOT Optional - nodeTextPropertyName - btnAddTxt: The property name that holds the text that is going to be shown in every node of the tree.
  @Input() nodeTextPropertyName: string = 'id'

  /**
   * Optional - Whether the input nodeTextPropertyName should be translated
   */
  @Input() translateTextPropertyName: boolean = false;

  //Optional - In case it's needed to display another value in the node title. (It's gonna be concatenated as "nodeTextPropertyName - secondNodeTextPropertName")
  @Input() secondNodeTextPropertyName!: string

  @Input() bottomSonPropertyName: string = 'isBottomSon'

  //Optional - title: The title text of the Tree (Default: "txt_treeview": "Treeview")
  @Input() title: string = 'txt_treeview'

  //Optional - btnAddTxt: The text of the main button to add new nodes in the root of the tree (Default: "txt_add_node": "Add node")
  @Input() btnAddTxt: string = 'txt_add_node'

  //Optional allowDragAndDrop: To allow or not the Drag and drop feature.
  @Input() allowDragAndDrop: boolean = true

  //Optional showAddButton: To show or not the add button (in root)
  @Input() showAddButton: boolean = false

  //Optional showAddSonButton: To show an add button in every node of the tree.
  @Input() showAddSonButton: boolean = false

  //Optional showEditSonButton: To show an edit button in every node of the tree.
  @Input() showEditSonButton: boolean = false

  //Optional showRemoveSonButton: To show a remove button in every node of the tree.
  @Input() showRemoveSonButton: boolean = false

  //Optional allExpandedDefault: To display or not all the tree expanded on init.
  @Input() allExpandedDefault: boolean = true

  //addNodeAction: The action when pressing the root add button (showAddButton)
  @Output() addNodeAction = new EventEmitter<void>();

  //addSonNodeAction: The action when pressing a add button of a node. Emits the father where the new son is gonna be inserted.
  @Output() addSonNodeAction = new EventEmitter<any>(); //not optional if showAddSonButton = true

  //addSonNodeAction: The action when pressing a edit button of a node. Emits the son that is gonna be edited and it's father. values: [father,son].
  @Output() editSonNodeAction = new EventEmitter<[any, any]>();//not optional if showEditButton = true

  //addSonNodeAction: The action when pressing a remove button of a node. Emits the node that is gonna be removed.
  @Output() removeSonNodeAction = new EventEmitter<any>(); //not optional if showRemoveButton = true

  //updateItemsList: The action that syncs the original item list of the parent component with the item and node list of this draggable-tree component. Emits the updated Item list
  @Output() updateItemsList = new EventEmitter<any>(); //not optional if adding nodes or drag and drop is allowed.

  nodes: TreeNode[] = [];
  dropTargetIds: string[] = [];
  nodeLookup: any = {};
  dropActionTodo!: DropInfo | null;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  prepareTreeNodes(father: TreeNode | undefined, item: any) {
    if (item instanceof Object && !item.entity) {
      let newItem = { ...item }
      newItem[this.childrenPropertyName] = [...item[this.childrenPropertyName]]
      let newNode: TreeNode = {
        id: newItem[this.keyPropertyName],
        entity: newItem,
        text: this.secondNodeTextPropertyName ? (newItem[this.nodeTextPropertyName] + ' - ' + newItem[this.secondNodeTextPropertyName]) : newItem[this.nodeTextPropertyName],
        isExpanded: newItem.isExpanded != undefined ? newItem.isExpanded : this.allExpandedDefault,
        isBottomSon: newItem[this.bottomSonPropertyName]
      }
      if (newItem[this.childrenPropertyName].length > 0) {
        newNode.isBottomSon = false
        newItem[this.bottomSonPropertyName] = false
      } else {
        newNode.isBottomSon = true
      }
      if (!father) {
        this.nodes.push(newNode)
      } else {
        let index = father.entity[this.childrenPropertyName].findIndex(
          (child: any) => child[this.keyPropertyName] === newNode.entity[this.keyPropertyName]);
        father.entity[this.childrenPropertyName][index] = newNode
      }
      newItem[this.childrenPropertyName].forEach((child: any) => {
        this.prepareTreeNodes(newNode, child)
      })
    } else if (item.entity) {
      item.entity[this.childrenPropertyName].forEach((child: any) => {
        this.prepareTreeNodes(item, child)
      })
    }
  }



  prepareDragDrop(nodes: TreeNode[]) {
    nodes.forEach((node: any) => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      if (node.entity) {
        this.prepareDragDrop(node.entity[this.childrenPropertyName]);
      }
    })
  }

  ngOnChanges() {
    if (this.allowDragAndDrop) {
      this.nodes = []
      this.dropTargetIds = []
      if (!this._items) {
        this._items = []
      }
      this._items.forEach((item: any) => {
        this.prepareTreeNodes(undefined, item);
      });
      this.prepareDragDrop(this.nodes)
    }
  }

  @debounce(50)
  dragMoved(event: any) {
    let e = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);
    let container = e?.classList.contains("node-item") ? e : e?.closest(".node-item");

    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute("data-id")
    };
    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo!["action"] = "before";
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo!["action"] = "after";
    } else {
      // inside
      this.dropActionTodo!["action"] = "inside";
    }
    this.showDragInfo();
  }

  protected drop(event: any) {
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(this.dropActionTodo.targetId!, this.nodes, 'main');

    const draggedItem = this.nodeLookup[draggedItemId];

    const oldItemContainer = parentItemId != 'main' ? this.nodeLookup[parentItemId].entity[this.childrenPropertyName] : this.nodes;
    const newContainer = targetListId != 'main' ? this.nodeLookup[targetListId!].entity[this.childrenPropertyName] : this.nodes;

    let i = oldItemContainer.findIndex((c: any) => c.id === draggedItemId);
    oldItemContainer.splice(i, 1);

    switch (this.dropActionTodo.action) {
      case 'before':
      case 'after':
        const targetIndex = newContainer.findIndex((c: any) => c.id === this.dropActionTodo!.targetId);
        if (this.dropActionTodo.action == 'before') {
          newContainer.splice(targetIndex, 0, draggedItem);
        } else {
          newContainer.splice(targetIndex + 1, 0, draggedItem);
        }
        break;

      case 'inside':
        if (this.dropActionTodo.targetId) {
          this.nodeLookup[this.dropActionTodo.targetId].entity[this.childrenPropertyName].push(draggedItem)
          this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
        }
        break;
    }
    this.updateItems(this.nodes).then((items) => {
      this._items = []
      this._items = items;
      this.updateItemsList.emit(items);
      this.clearDragInfo(true)
    })
  }

  private async flattenEntity(node: TreeNode): Promise<any> {
    let entity: any = { ...node.entity };
    entity.isExpanded = node.isExpanded
    entity[this.bottomSonPropertyName] = node.isBottomSon
    entity[this.childrenPropertyName] = await Promise.all(
      entity[this.childrenPropertyName].map(async (child: any) => await this.flattenEntity(child))
    );
    return entity;
  }

  public async getFinalItemList(): Promise<any[]>{
    return this.updateItems(this.nodes)
  }

  private async updateItems(nodes: TreeNode[]): Promise<any[]> {
    const items = await Promise.all(
      nodes.map(async (node) => await this.flattenEntity(node))
    );
    return items;
  }


  getParentNodeId(id: string, nodesToSearch: TreeNode[], parentId: string): string | null {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.entity[this.childrenPropertyName], node.id);
      if (ret) return ret;
    }
    return null;
  }

  protected showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo != null) {
      this.document.getElementById("node-" + this.dropActionTodo.targetId)!.classList.add("drop-" + this.dropActionTodo.action);
    }
  }

  private clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach(element => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach(element => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach(element => element.classList.remove("drop-inside"));
  }

  protected async newNodeAction() {
    this._items = await this.updateItems(this.nodes);
    this.updateItemsList.emit(this._items)
    this.addNodeAction.emit()
  }

  private async lookForItem(fatherEntity: TreeNode, auxItems: any[]): Promise<any> {
    for (const item of auxItems) {
      if (item[this.keyPropertyName] === fatherEntity.id) {
        return item
      }
    }
    for (const item of auxItems) {
      if (item[this.childrenPropertyName]) {
        const foundItem = await this.lookForItem(fatherEntity, item[this.childrenPropertyName])
        if (foundItem) {
          return foundItem
        }
      }
    }
  }

  protected async sonNodeAction(fatherEntity: any) {
    this._items = await this.updateItems(this.nodes);
    this.updateItemsList.emit(this._items)
    this.lookForItem(fatherEntity, this._items).then((fatherItem) => {
      this.addSonNodeAction.emit(fatherItem)
    }).catch((error: any) => {
    })

  }

  private async lookForFatherAndItem(nodeToEdit: any, auxItems: any[], fatherItem: any): Promise<any> {
    for (const item of auxItems) {

      if (item[this.keyPropertyName] === nodeToEdit.id) {
        return [fatherItem, item]
      }
    }
    for (const item of auxItems) {
      if (item[this.childrenPropertyName]) {
        const wat = await this.lookForFatherAndItem(nodeToEdit, item[this.childrenPropertyName], item)
        if (wat) {
          return wat
        }
      }
    }
  }

  protected async editSonAction(nodeToEdit: any) {
    this._items = await this.updateItems(this.nodes);
    this.updateItemsList.emit(this._items)
    this.lookForFatherAndItem(nodeToEdit, this._items, null).then((foundItems) => {
      //[0] is the father item --- [1] is the son item (The one that is going to be edited)
      this.editSonNodeAction.emit([foundItems[0], foundItems[1]])
    })
  }

  protected async removeAction(node: TreeNode) {
    this._items = await this.updateItems(this.nodes);
    this.updateItemsList.emit(this._items)
    this.lookForItem(node, this._items).then((item) => {
      this.removeSonNodeAction.emit(item)
    })
  }
}
