import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdmTypology } from 'src/app/data/models/adm';
import { AdmTypologiesService } from 'src/app/services/core/adm-typologies.service';
import { TypologiesEnum } from 'src/global/typologies-enum';

@Component({
  selector: 'app-active-inactive-switch',
  templateUrl: './active-inactive-switch.component.html',
  styleUrls: ['./active-inactive-switch.component.scss']
})
export class ActiveInactiveSwitchComponent implements OnInit {

  @Input()
  status : AdmTypology | undefined;

  @Output()
  statusChange = new EventEmitter<AdmTypology>();

  active !: AdmTypology;
  inactive !: AdmTypology;
  TE = TypologiesEnum;

  constructor(
    private admTypologiesService: AdmTypologiesService
  ) { }

  ngOnInit(): void {
    this.admTypologiesService.listByParentInternalId(TypologiesEnum.STATUS)
      .subscribe(typs => {
        typs.forEach(tp => {
          if (tp.internalId === TypologiesEnum.ACTIVE) {
            this.active = tp;
          }
          if (tp.internalId === TypologiesEnum.INACTIVE) {
            this.inactive = tp;
          }
        });
      }, err => {
        console.error('Error loading status typologies', err);
      });
  }

  changeValue(event: boolean) {
    this.status = event ? this.active : this.inactive;
    this.statusChange.emit(this.status);
  }

}
