<app-top-bar></app-top-bar>
<div class="navbar navbar-expand-lg bg-primary" *transloco="let t">
  <div class="container-fluid  px-1 px-lg-5 ">
    <div class="d-flex align-items-center logo-container gap-2 order-1 me-2" [ngClass]="{'logo-container-active': !isHome}">
      <button class="back-btn p-0 btn btn-text-light" (click)="return()">
        <app-material-icon>arrow_back</app-material-icon>
      </button>
      <button class="navbar-toggler p-2" (click)="showNavbar()" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <app-material-icon>menu</app-material-icon>
      </button>
      <img class="logo" routerLink="/home" role="button" src="{{'../../../../assets/' + profile + '/logo/logo-white.svg'}}" alt="logo">
    </div>
    <div [class.show]="isNavbarActive" class="collapse navbar-collapse order-3 order-lg-2" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <ng-container *ngFor="let item of permissions">
          <ng-container *ngIf="item.childrenPermissions.length==0">
            <button *ngIf="item.readPermission" routerLink="{{item.sref}}" class="btn btn-primary fs-14">
              {{t(item.name)}}
            </button>
          </ng-container>
          <div ngbDropdown *ngIf="item.childrenPermissions.length>0" class="d-grid">
            <button ngbDropdownToggle class="btn btn-primary fs-14">
              {{t(item.name)}}
            </button>
            <div ngbDropdownMenu  >
              <ng-container *ngFor="let childrenItem of item.childrenPermissions">

                <a *ngIf="childrenItem.readPermission" routerLink="{{childrenItem.sref}}" ngbDropdownItem>{{t(childrenItem.name)}}</a>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ul>


    </div>
    <div class="user-data text-white d-flex gap-2 align-items-center order-2 order-lg-3">
      <!-- alerts commented for now
      <div class="alerts d-flex">
        <button class="notifications active btn btn-text-light d-flex">
          <app-material-icon>notifications</app-material-icon>
        </button>
        <button class="validations active btn btn-text-light d-flex">
          <app-material-icon>assignment</app-material-icon>
        </button>
      </div>
    -->
      <div ngbDropdown *ngIf="user">
        <button type="button" class="btn btn-outline-primary text-start p-0" ngbDropdownToggle>
          <div class="user-info text-white align-items-center d-flex gap-2 pe-2">
            <div class="avatar fw-semibold h6 m-0 notranslate">
              {{initials}}
            </div>
            <div class="d-flex flex-column d-none d-lg-block">
              <p class="user-name text-gray-100 m-0 fw-semibold fs-14">{{user.admPerson.fullName}}</p>
              <p class="user-role text-primary-100 m-0 fs-12">{{user.role}}</p>
            </div>
            <app-material-icon class="d-none d-lg-block">expand_more</app-material-icon>
          </div>
        </button>
        <div ngbDropdownMenu class="end-0">
          <button ngbDropdownItem class="btn" [routerLink]="['/profile']">
            <app-material-icon class="d-none d-lg-block">
              account_circle
            </app-material-icon>
            <span>
              {{t('txt_my_profile')}}
            </span>
          </button>
          <button (click)="logout()" class="btn" ngbDropdownItem>
            <app-material-icon class="d-none d-lg-block">
              logout
            </app-material-icon>
            <span>
              {{t('txt_log_out')}}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
