import { Component, OnInit } from '@angular/core';
import { AdmParameter, AdmPermissionDto } from 'src/app/data/models/adm';
import { AdmParameterService } from 'src/app/services/core/adm-parameter.service';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { LOCALKENUM } from 'src/global/local-enum';
import { TypologiesEnum } from 'src/global/typologies-enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private admPermissionService: AdmPermissionsService,
    private parameterService: AdmParameterService
  ) {
  }

  menuItems: AdmPermissionDto[] = [];
  name!: String;

  ngOnInit(): void {
    let profile = localStorage.getItem('profile');
    if (profile != null) {
      let user = JSON.parse(profile);
      this.name = user.admPerson.fullName;
    }

    this.menuItems = this.admPermissionService
      .getMyPermissions()
      .filter(permission => permission.readPermission);


    this.parameterService.findByParameterType(TypologiesEnum.PMT_SEPARATE_DECIMALS).subscribe((parameter: AdmParameter) => {
      if (parameter != null) {
        let separador = ',';
        if(parameter.value==null || parameter.value=='0'){
          separador = '.';
        }
        localStorage.setItem(LOCALKENUM.DECIMAL_SEPARATOR, separador );
      }
    } );
  }
}
