<ng-container *ngIf="pageAuthOp || pageAuthOps.length">
  <button
    *transloco="let t"
    class="btn btn-outline-primary w-100 w-lg-auto"
    (click)="open()"
  >
    {{ t('txt_auth_process') }}
  </button>
</ng-container>

<ng-template #approvalView *transloco="let t">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title fs-4 fw-semibold">{{ t('txt_authorization_process') }}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="offcanvas-body">
    <div class="h-100 mx-2 d-flex flex-column justify-content-between">
      <!-- current page authorization operation -->
      <div id="auth-info" *ngIf="_hashId && pageAuthOp">
        <ng-container
          [ngTemplateOutlet]="pageAuthTemplate"
          [ngTemplateOutletContext]="{ pageAuthOp: pageAuthOp, showStatus: false }"
        >
        </ng-container>
        <div id="step-log-info">
          <ng-container
            *ngFor="let log of pageAuthOp.pageAuthStepLogsOp"
            [ngTemplateOutlet]="pageStepLogTmpl"
            [ngTemplateOutletContext]="{ log: log }"
          >
          </ng-container>
        </div>
        <div id="step-info">
          <ng-container
            *ngFor="let step of pageAuthOp.pageAuthSteps"
            [ngTemplateOutlet]="pageStepTmpl"
            [ngTemplateOutletContext]="{ step: step }"
          >
          </ng-container>
        </div>
      </div>

      <!-- all page authorization operations -->
      <div id="auth-ops" *ngIf="_entityHashId && this.pageAuthOps.length">
        <div ngbAccordion>
          <ng-container *ngFor="let page of pageAuthOps; let i = index;">
            <div ngbAccordionItem (shown)="onShowItem(page)">
              <div ngbAccordionHeader>
                <button ngbAccordionButton class="custom">
                  <ng-container
                    [ngTemplateOutlet]="pageAuthTemplate"
                    [ngTemplateOutletContext]="{ pageAuthOp: page, showStatus: true }"
                    >
                  </ng-container>
                </button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <ng-container
                      *ngFor="let log of page.pageAuthStepLogsOp"
                      [ngTemplateOutlet]="pageStepLogTmpl"
                      [ngTemplateOutletContext]="{ log: log }"
                    >
                    </ng-container>
                    <ng-container
                      *ngFor="let step of page.pageAuthSteps"
                      [ngTemplateOutlet]="pageStepTmpl"
                      [ngTemplateOutletContext]="{ step: step }"
                    >
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="auth-area" *ngIf="showAuthorizationArea">
        <p class="text-muted small my-1">{{ reviewCommentary.length }} / 150</p>
        <div class="form-floating mb-4">
          <textarea
            class="form-control"
            placeholder="{{ t('txt_add_comment') }}"
            style="height: 200px; resize: none;"
            [maxLength]="150"
            [(ngModel)]="reviewCommentary"
          ></textarea>
          <label class="text-muted">{{ t('txt_add_comment') }}</label>
        </div>

        <div id="auth-buttons" class="d-flex justify-content-between gap-2">
          <div class="w-50 d-grid">
            <button type="button" class="btn btn-danger" (click)="onAuthorize(typologyEnum.AOS_REJECTED)">{{ t('reject') }}</button>
          </div>
          <div class="w-50 d-grid">
            <button type="button" class="btn btn-success" (click)="onAuthorize(typologyEnum.AOS_AUTHORIZED)">{{ t('authorize') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- page authorization op -->
<ng-template #pageAuthTemplate let-pageAuth="pageAuthOp" let-showStatus="showStatus">
  <ng-container *transloco="let t">
    <div class="card my-2">
      <div class="card-body py-1 d-flex justify-content-start align-items-center gap-1">
        <app-material-icon [size]="56" class="text-primary" *ngIf="pageAuth.tpPageAuthorizationType.internalId == typologyEnum.PGAT_CREATION">
          note_add
        </app-material-icon>
        <app-material-icon [size]="56" class="text-danger" *ngIf="pageAuth.tpPageAuthorizationType.internalId == typologyEnum.PGAT_ELIMINATION">
          scan_delete
        </app-material-icon>
        <div>
          <span class="badge text-bg-light fw-semibold fs-6 p-0">
            <ng-container *ngIf="pageAuth.tpPageAuthorizationType.internalId == typologyEnum.PGAT_CREATION">
              {{ t('txt_created') }}
            </ng-container>
            <ng-container *ngIf="pageAuth.tpPageAuthorizationType.internalId == typologyEnum.PGAT_ELIMINATION">
              {{ t('txt_deleted') }}
            </ng-container>
          </span>
          <p class="my-1 small text-muted">
            <span>{{ pageAuth.entryDate | date: 'dd/MM/yyyy' }}</span>
            <span> a las </span>
            <span>{{ pageAuth.entryDate | date: 'HH:mm' }}</span>
          </p>
          <p class="my-1 fw-semibold text-primary-300">
            {{ t('by') }}:
            <span>{{ pageAuth.creatorUser.admPerson.fullName }}</span>
          </p>
          <p class="my-1" *ngIf="showStatus">
            <app-status-badge [typology]="pageAuth.tpAuthorizationStatus"></app-status-badge>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- logs -->
<ng-template #pageStepLogTmpl let-log="log">
  <ng-container *transloco="let t">
    <div class="card my-2">
      <div class="card-body py-1 d-flex justify-content-start align-items-center gap-1">
        <app-material-icon
          [size]="56"
          class="text-success"
          [ngClass]="{ 'text-success': log.tpAuthorizationStatus.internalId == typologyEnum.AOS_AUTHORIZED, 'text-danger': log.tpAuthorizationStatus.internalId == typologyEnum.AOS_REJECTED }"
        >
          task
        </app-material-icon>
        <div>
          <span class="badge text-bg-light fw-semibold fs-6 p-0">
            {{ t(log.tpAuthorizationStatus.description) }}
          </span>
          <p class="my-0 small text-muted">
            <span>{{ log.entryDate | date: 'dd/MM/yyyy' }}</span>
            <span> a las </span>
            <span>{{ log.entryDate | date: 'HH:mm' }}</span>
          </p>
          <p *ngIf="log.reviewCommentary" class="my-0 small">
            <span class="fw-medium">{{ t('txt_review_commentary') }}:</span>
            <span class="text-muted">
              {{ log.reviewCommentary }}
            </span>
          </p>
          <p class="my-0 fw-semibold text-primary-300">
            {{ t('by') }}:
            <span>{{ log.reviewingUser.admPerson.fullName }}</span>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- steps -->
<ng-template #pageStepTmpl let-step="step">
  <ng-container *transloco="let t">
    <div
      class="card my-2"
      [ngClass]="{
        'border-primary border-opacity-75': step.tpAuthorizationStatus.internalId == typologyEnum.AOS_IN_REVIEW,
        'border-opacity-10': step.tpAuthorizationStatus.internalId == typologyEnum.AOS_PENDING_REVIEW
      }"
    >
      <div class="card-body py-1 d-flex justify-content-start align-items-center gap-1">
        <app-material-icon [size]="56" class="text-muted">
          history_toggle_off
        </app-material-icon>
        <div>
          <span
            class="badge text-bg-light fw-semibold fs-6 p-0"
            [ngClass]="{ 'text-muted': step.tpAuthorizationStatus.internalId == typologyEnum.AOS_PENDING_REVIEW }"
          >
            {{ t(step.tpAuthorizationStatus.description) }}
          </span>
          <p class="my-1 fw-semibold text-primary-300">
            {{ t('by') }}:
            <span>{{ step.roleName }}</span>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
