import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/app-commons/components/modal/modal.component';
import { BtnEnum } from 'src/global/btn-enum';

/**
 * modal to confirm actions
 */
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent extends ModalComponent {

  /**
   * modal title
   */
  @Input() title = 'txt_confirm_delete';

  /**
   * question for the user, if it is sent empty it will not be displayed
   */
  @Input() bodyQuestion = 'txt_confirm_really_want_remove';

  /**
   * complementary text for the user, if it is sent empty it will not be displayed
   */
  @Input() bodyTxt = 'txt_confirm_no_recovery';

  /**
   * Type of button to be displayed to confirm the action
   */
  @Input() btnType: BtnEnum = BtnEnum.DELETE;

  /**
   * confirmation button text
   */
  @Input() btnTxt = 'txt_confirm_understand_delete';

  @Input() bodyTxtFunction!: Function;
  functionReturn !:string;

  /**
   * method to be called if confirmed
   */
  @Output() actionConfirm = new EventEmitter();

  constructor(modalService: NgbModal) {
    super(modalService)
  }

  btnEnum = BtnEnum;
  returnValue!: any;

  /**
   * @param returnValue
   * value that is returned when confirming the modal
   */
  openModal(returnValue: any) {
    this.returnValue = returnValue;
    this.open();
  }

  confirmBtn() {
    this.actionConfirm.emit(this.returnValue);
    this.close();
  }

  openModal2(returnValue: any) {
    this.returnValue = returnValue;
    this.functionReturn=this.bodyTxtFunction(this.returnValue);
    this.open();
  }

}
