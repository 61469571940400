import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdmUser } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/users';

@Injectable({
  providedIn: 'root'
})
export class AdmUsersService {

  constructor(
    private http: HttpClient
  ) { }

  save(user: AdmUser): Observable<number | unknown> {
    if (user.userId) {
      return this.http.put<unknown>(`${baseUrl}/${user.hashId}`, user);
    }
    return this.http.post<number>(`${baseUrl}`, user);
  }

  getEntity(hashId: string): Observable<AdmUser> {
    return this.http.get<AdmUser>(`${baseUrl}/${hashId}`);
  }

  spacesAvailableForProvider(providerId: number, isOrganization: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append('isOrganization', isOrganization);
    return this.http.get<any>(`${baseUrl}/spaces-available/${providerId}`, { params: params });
  }

  spacesAvailableForCustomer(customerId: number, isOrganization: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append('isOrganization', isOrganization);
    return this.http.get<any>(`${baseUrl}/spaces-available-customer/${customerId}`, { params: params });
  }

  getEntityId(userId: number, queryParams: any = {}): Observable<AdmUser> {
    let params = new HttpParams();
    if (queryParams['onlyDto'] !== undefined)
      params = params.append('onlyDto', queryParams['onlyDto']);
    return this.http.get<AdmUser>(`${baseUrl}/user/${userId}`, { params: params });
  }

  listAllHttp(queryParams: any): Observable<HttpResponse<any>> {
    const params = this.getQueryParams(queryParams);
    return this.http.get<HttpResponse<any>>(baseUrl, { params: params, observe: 'response' });
  }

  checkEmail(email: string): Observable<any> {
    return this.http.get<any>(`${baseUrl}/check-email/${email}`, { observe: "body" }).pipe(
      map(data => {
        return !data ? { status: false, apiCommunication: false, changes: false } : { status: true, apiCommunication: false, changes: false };
      }),
      catchError(error => {
        return of({ status: false, apiCommunication: true, changes: false });
      })
    );
  }

  getMe(): Observable<AdmUser> {
    return this.http.get<AdmUser>(`${baseUrl}/me`);
  }

  softDelete(hashId: string): Observable<unknown> {
    return this.http.delete<unknown>(`${baseUrl}/${hashId}`);
  }

  changePassword(data: any, queryParams: any = {}): Observable<unknown> {
    let params = new HttpParams();
    if (queryParams['sendMail'] !== undefined)
      params = params.append('sendMail', queryParams['sendMail']);
    if (queryParams['bySuperuser'] !== undefined)
      params = params.append('bySuperuser', queryParams['bySuperuser']);
    return this.http.put<unknown>(`${baseUrl}/change-password`, data, { params: params });
  }

  deleteEntitiesByHashId(roles: string[]) {
    return this.http.put<unknown>(`${baseUrl}/delete-users`, roles);
  }

  export(format: number, queryParams: any, columns: string) {
    const params = this.getQueryParams(queryParams)
      .set('columns', columns);
    return this.http.get(`${baseUrl}/export/${format}`, { params: params, responseType: 'blob' as 'json' });
  }

  private getQueryParams(queryParams: any): HttpParams {
    let params = new HttpParams();
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['columnOrder'] !== undefined)
      params = params.append('columnOrder', queryParams['columnOrder']);
    if (queryParams['name'] !== undefined && queryParams['name'] != '')
      params = params.append('name', queryParams['name']);
    if (queryParams['email1'] !== undefined && queryParams['email1'] != '')
      params = params.append('email1', queryParams['email1']);
    if (queryParams['tpStatus'] !== undefined && queryParams['tpStatus'] != '')
      params = params.append('tpStatus', queryParams['tpStatus']);
    if (queryParams['hashId'] !== undefined && queryParams['hashId'] != '')
      params = params.append('hashId', queryParams['hashId']);
    if (queryParams['memberTypes'] !== undefined)
      params = params.append('memberTypes', queryParams['memberTypes']);
    if (queryParams['externalUser'] !== undefined)
      params = params.append('externalUser', queryParams['externalUser']);
    if (queryParams['externalProviderUser'] !== undefined)
      params = params.append('externalProviderUser', queryParams['externalProviderUser']);
    if (queryParams['externalClientUser'] !== undefined)
      params = params.append('externalClientUser', queryParams['externalClientUser']);
    if (queryParams['providerId'] !== undefined)
      params = params.append('providerId', queryParams['providerId']);
    if (queryParams['clientId'] !== undefined)
      params = params.append('clientId', queryParams['clientId']);
    return params;
  }
}
