<div class="row">
    <div *ngIf="!image" class="col-sm-12 d-flex rounded select-image-indicator text-center">
        <label *ngIf="!saving" [attr.for]="idInput" class="d-flex flex-column my-auto w-100 img-selector-label">
            <span class="fa fa-image text-muted mb-2"></span>
            <span class="text-muted">{{title}}</span>
        </label>
        <div *ngIf="saving" class="spinner d-flex mt-auto mb-2">
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span class="text-muted small ml-2">Guardando imagen...</span>
        </div>
    </div>
    <div *ngIf="image" class="col-sm-12 p-2 rounded select-image-preview">
        <div *ngIf="newUser">
            <label permissionType="create" *ngIf="image" class="change-img-icon" [attr.for]="idInput"
                title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <div *ngIf="me">
            <label *ngIf="image" class="change-img-icon" [attr.for]="idInput" title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <div *ngIf="!newUser && !me">
            <label permissionType="update" *ngIf="image" class="change-img-icon" [attr.for]="idInput"
                title="Cambiar imagen">
                <span class="fa fa-refresh mx-auto my-auto"></span>
            </label>
        </div>
        <img [src]="image" class="w-100" height="140">
        <div *ngIf="saving" class="spinner d-flex mt-auto mb-2">
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span class="text-muted small ml-2">Guardando imagen...</span>
        </div>
    </div>
    <input *ngIf="!cutImage" class="d-none" type="file" [attr.id]="idInput" accept="image/*"
        (change)="updateImage($event)">
    <input *ngIf="cutImage" class="d-none" type="file" [attr.id]="idInput" accept="image/*"
        (change)="openModalToCutImage($event, withCut)">
</div>

<app-image-selector-with-cut (loadImageB64)="saveCutImage($event)" [aspectRatio]="aspectRatio" #withCut>
</app-image-selector-with-cut>