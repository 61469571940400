<div *ngIf="apiLoaded && showMap" style="height: 100%; width: 100%">
  <google-map height="100%" width="100%" [options]="options">
    <ng-container *ngFor="let mapInfo of markers">
      <map-marker
        [position]="mapInfo.position"
        [options]="mapInfo.options"
        (mapClick)="onMarkerClick(mapInfo.hashId)"
      >
      </map-marker>
    </ng-container>
  </google-map>
</div>
