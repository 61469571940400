<div *ngIf="config">
    <div *transloco="let t">
        <b>
            {{t(config.title)}}
        </b>
        <div *ngIf="config.configValues.length > 0" class="body-container">
            <div class="table-container">
                <table class="table sticky-header content-table"
                    [ngClass]="{'sticky-actions': config.values.length > 0 && config.showDeleteBtns}">
                    <tr>
                        <ng-container *ngFor="let input of counter(); let i = index;">
                            <th [style.width]="config.configValues[i].width !== '-1' ? config.configValues[i].width : null">
                                <span *ngIf="!config.configValues[i].isOptional" class="text-danger fw-bold me-2">*</span>
                                {{t(config.configValues[i].label)}}
                            </th>
                        </ng-container>
                        <th *ngIf="config.showDeleteBtns" class="text-center" [style.width]="'75px'" >
                            {{t('txt_actions')}}
                        </th>
                    </tr>

                    <tr *ngIf="config.values.length === 0" class="p-1">
                        <td [attr.colspan]="config.configValues.length + 1">
                            <app-empty-panel emptyMessage="txt_empty" icon="inventory_2" [emptyPanelStyle]="false"
                                (addItem)="addItem(true, undefined)" [showBtn]="config.showAddBtns"></app-empty-panel>
                        </td>
                    </tr>
                    <tr *ngFor="let item of config.values; let j = index;">
                        <td *ngFor="let input of counter(); let i = index;">

                            <div
                                *ngIf="config.configValues[i].type === listEnum.NUMBER_ALL || config.configValues[i].type === listEnum.NUMBER_POSITIVE">
                                <input [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    [ngClass]="{'is-invalid': input1.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    (ngModelChange)="onChange(j,i)" type="text" class="form-control"
                                    id="{{i}}-input-data" name="{{i}}-input-data"
                                    placeholder="{{t(config.configValues[i].placeholder)}}"
                                    [maxlength]="config.configValues[i].maxlength"
                                    [allowNegatives]="config.configValues[i].type === listEnum.NUMBER_ALL"
                                    [pattern]="config.configValues[i].numberIntPattern" #input1="ngModel" digitOnly>
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>
                            <div
                                *ngIf="config.configValues[i].type === listEnum.NUMBER_ALL_DECIMAL || config.configValues[i].type === listEnum.NUMBER_POSITIVE_DECIMAL">
                                <input [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    [ngClass]="{'is-invalid': input2.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    (ngModelChange)="onChange(j,i)" type="text" class="form-control"
                                    id="{{i}}-input-data" name="{{i}}-input-data"
                                    placeholder="{{t(config.configValues[i].placeholder)}}"
                                    [maxlength]="config.configValues[i].maxlength" [decimal]="true"
                                    [allowNegatives]="config.configValues[i].type === listEnum.NUMBER_ALL_DECIMAL"
                                    [disabled]="config.configValues[i].operation === listEnum.RESULT"
                                    [pattern]="config.configValues[i].numberDecimalPattern" #input2="ngModel" digitOnly>
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>
                            <div
                                *ngIf="config.configValues[i].type === listEnum.HIDDEN || config.configValues[i].type === listEnum.NUMBER_POSITIVE_DECIMAL">
                                <input [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    [ngClass]="{'is-invalid': input2.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    (ngModelChange)="onChange(j,i)" type="text" class="form-control"
                                    id="{{i}}-input-data" name="{{i}}-input-data"
                                    placeholder="{{t(config.configValues[i].placeholder)}}"
                                    [maxlength]="config.configValues[i].maxlength" [decimal]="true"
                                    [allowNegatives]="config.configValues[i].type === listEnum.NUMBER_ALL_DECIMAL"
                                    [disabled]="config.configValues[i].operation === listEnum.RESULT"
                                    [pattern]="config.configValues[i].numberDecimalPattern" #input2="ngModel" digitOnly hidden>
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>
                            <div *ngIf="config.configValues[i].type === listEnum.TEXT">
                                <input [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    [ngClass]="{'is-invalid': input3.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    (ngModelChange)="onChange(j,i)" type="text" class="form-control"
                                    id="{{i}}-input-data" name="{{i}}-input-data"
                                    placeholder="{{t(config.configValues[i].placeholder)}}"
                                    [maxlength]="config.configValues[i].maxlength" #input3="ngModel">
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.FILE" class="row">
                                <div *ngIf="item.row[i][config.configValues[i].fieldName]" id="wrapper" class="col-2">
                                    <button (click)="goToLink(item.row[i][config.configValues[i].fieldName])"
                                        class="btn btn-gray">
                                        <app-material-icon class="btn text-primary btn-sm d-inline-flex type-toggle">
                                            visibility
                                        </app-material-icon>
                                    </button>
                                </div>
                                <div
                                    [ngClass]="{'col-12': !item.row[i][config.configValues[i].fieldName], 'col-10': item.row[i][config.configValues[i].fieldName]}">
                                    <input [accept]="config.configValues[i].acceptedValues"
                                        [ngClass]="{'is-invalid': !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                        type="file" class="form-control" (change)="loadFile($event,j,i)"
                                        id="{{i}}-input-data" name="{{i}}-input-data"
                                        placeholder="{{t(config.configValues[i].placeholder)}}">
                                    <div class="invalid-feedback">
                                        {{t('msg_validation_required_field')}}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.TIME">
                                <input [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    [ngClass]="{'is-invalid': input5.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    (ngModelChange)="onChange(j,i)" type="time" class="form-control"
                                    id="{{i}}-input-data" name="{{i}}-input-data" #input5="ngModel">
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.UNIQUE_TP">
                                <div class="overflow-box">
                                    <ng-select id="{{i}}-input-data" name="{{i}}-input-data"
                                        (ngModelChange)="onChange(j,i)" [items]="config.configValues[i].values"
                                        bindLabel="description" [searchFn]="searchTp" [compareWith]="compareWithTp"
                                        appendTo="body" [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                        [multiple]="false" [searchable]="true" [closeOnSelect]="true"
                                        [clearable]="false" [hideSelected]="true"
                                        [ngClass]="{'is-invalid': input6.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName], 'is-invalid-in-table': input6.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                        placeholder="{{t(config.configValues[i].placeholder)}}" #input6="ngModel">
                                        <ng-template ng-label-tmp let-item="item">
                                            <span>{{t(item.description)}}</span>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <div title="item">{{t(item.description)}}</div>
                                        </ng-template>
                                    </ng-select>
                                    <div class="invalid-feedback">
                                        {{t('msg_validation_required_field')}}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.UNIQUE_TP_WITH_RESTRICTION">
                                <div class="overflow-box">
                                    <ng-select id="{{i}}-input-data" name="{{i}}-input-data"
                                        (ngModelChange)="onChange(j,i)" [items]="getTpValuesWithRestriction(j,i)"
                                        bindLabel="description" [searchFn]="searchTp" [compareWith]="compareWithTp"
                                        appendTo="body" [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                        [multiple]="false" [searchable]="true" [closeOnSelect]="true"
                                        [clearable]="false" [hideSelected]="true"
                                        [ngClass]="{'is-invalid': input6.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName], 'is-invalid-in-table': input6.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                        placeholder="{{t(config.configValues[i].placeholder)}}" #input6="ngModel">
                                        <ng-template ng-label-tmp let-item="item">
                                            <span>{{t(item.description)}}</span>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <div title="item">{{t(item.description)}}</div>
                                        </ng-template>
                                    </ng-select>
                                    <div class="invalid-feedback">
                                        {{t('msg_validation_required_field')}}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.DATE">
                                <div class="input-suffix-icon"
                                    [ngClass]="{'input-suffix-icon-error': input7.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}">
                                    <input name="{{i}}-input-data" class="form-control" #c2="ngModel"
                                        placeholder="{{t(config.configValues[i].placeholder)}}"
                                        [minDate]="checkDateRestriction(i,true,j)"
                                        [maxDate]="checkDateRestriction(i,false,j)"
                                        [ngClass]="{'is-invalid': input7.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                        [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                        (ngModelChange)="onChange(j,i)" ngbDatepicker #d="ngbDatepicker"
                                        container="body" readonly #input7="ngModel" />
                                    <div class="invalid-feedback">
                                        {{t('msg_validation_required_field')}}
                                    </div>
                                    <app-material-icon (click)="markAsTouched(d,input7)"
                                        class="btn text-primary btn-sm d-inline-flex type-toggle">calendar_month</app-material-icon>
                                </div>
                            </div>

                            <div *ngIf="config.configValues[i].type === listEnum.UNIQUE_OBJ_WITH_RESTRICTION">
                                <ng-select id="{{i}}-input-data" name="{{i}}-input-data" appendTo="body"
                                    [items]="getObjValuesWithRestriction(j,i)"
                                    [bindValue]="config.configValues[i].bindValue || ''"
                                    [bindLabel]="config.configValues[i].bindLabel || ''"
                                    [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    (ngModelChange)="onChange(j,i)" [multiple]="false" [searchable]="true"
                                    [closeOnSelect]="true" [clearable]="false" [hideSelected]="true"
                                    [ngClass]="{'is-invalid': input8.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName], 'is-invalid-in-table': input8.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    placeholder="{{t(config.configValues[i].placeholder)}}" #input8="ngModel">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span>{{t(item[config.configValues[i].bindLabel || ''])}}</span>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <div title="item">{{t(item[config.configValues[i].bindLabel || ''])}}</div>
                                    </ng-template>
                                </ng-select>
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>

                            <div
                                *ngIf="config.configValues[i].type === listEnum.UNIQUE_OBJ || config.configValues[i].type === listEnum.READ_ONLY">
                                <ng-select id="{{i}}-input-data" name="{{i}}-input-data" appendTo="body"
                                    [items]="config.configValues[i].values"
                                    [bindValue]="config.configValues[i].bindValue || ''"
                                    [bindLabel]="config.configValues[i].bindLabel || ''"
                                    [(ngModel)]="item.row[i][config.configValues[i].fieldName]"
                                    (ngModelChange)="onChange(j,i)" [multiple]="false" [searchable]="true"
                                    [closeOnSelect]="true" [clearable]="false" [hideSelected]="true"
                                    [ngClass]="{'is-invalid': input8.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName], 'is-invalid-in-table': input8.touched && !config.configValues[i].isOptional && !item.row[i][config.configValues[i].fieldName]}"
                                    placeholder="{{t(config.configValues[i].placeholder)}}"
                                    groupBy="{{config.configValues[i].groupBy}}"
                                    [disabled]="config.configValues[i].type === listEnum.READ_ONLY" #input8="ngModel">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span>{{t(item[config.configValues[i].bindLabel || ''])}}</span>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <div title="item">{{t(item[config.configValues[i].bindLabel || ''])}}</div>
                                    </ng-template>
                                </ng-select>
                                <div class="invalid-feedback">
                                    {{t('msg_validation_required_field')}}
                                </div>
                            </div>

                        </td>
                        <td *ngIf="config.showDeleteBtns">
                          <div class="d-flex flex-column flex-lg-row justify-content-center mt-auto">
                            <button *ngIf="item.canBeDeleted" (click)="deleteItem(j)"
                                class="btn btn-danger">
                                {{t('delete')}}
                            </button>
                          </div>
                        </td>
                    </tr>
                </table>
            </div>

        </div>

        <div *ngIf="config.values.length > 0 && config.configValues.length > 0 && config.showAddBtns && (config.itemLimit > 0 ? (config.values.length < config.itemLimit) : true)"
            class="center">
            <span (click)="addItem(true, undefined)" class="btn btn-text-primary">
                <app-material-icon>add</app-material-icon>{{t(config.btnTxt)}}
            </span>

        </div>
        <div  *ngIf="config.values.length > 0 && config.configValues.length > 0  && (config.itemLimit > 0 ? (config.values.length < config.itemLimit) : true) && config.showTotal" style="float: right;">
            <h1 class="h4 fw-bold" style="text-align: right;">{{t(config.labelTotal)}}: {{config.total.toFixed(2)}}</h1>
        </div>
    </div>
</div>
