import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

export interface MapInfo {
  entityHashId: string,
  latitude: number,
  longitude: number,
  label: string
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  apiLoaded: boolean = false;
  loader = new Loader({
    apiKey: environment.googleApiKey,
    libraries: ['places']
  });

  /**
   * Information to be displayed on the map
   * @param {MapInfo} mapInfo
   */
  @Input() set mapInfo(mapInfo: MapInfo[]) {
    if (!mapInfo) {
      return;
    }

    this._mapInfo = mapInfo;
    this.transformMapInfo();
  }

  /**
   * Whether to show the map or not
   */
  @Input() showMap: boolean = false;

  /**
   * event that is triggered by clicking on a map element (Marker or MapInfo)
   */
  @Output() onMapClick = new EventEmitter<string>();

  _mapInfo: MapInfo[] = [];
  markers: any[] = [];

  /**
   * Options in the map
   */
  options: google.maps.MapOptions = {
    center: { lat: 14.628434, lng: -90.522713 },
    zoom: 8,
    disableDefaultUI: true
  };

  constructor() {
    this.loader.importLibrary('maps').then(() => {
      this.apiLoaded = true;
    });
  }

  transformMapInfo() {
    this.markers = this._mapInfo.map(info => {
      const options: google.maps.MarkerOptions = {
        icon: 'assets/icons/location_on.svg',
        label: {
          text: info.label,
          className: 'mark-label',
          color: '#3374AC',
          fontSize: '12px',
          fontWeight: '700'
        }
      }

      return {
        hashId: info.entityHashId,
        options: options,
        position: {
          lat: info.latitude,
          lng: info.longitude
        }
      }
    });
  }

  onMarkerClick(entityHashId: string) {
    this.onMapClick.emit(entityHashId);
  }
}
