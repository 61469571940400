<div style="text-align:center" *transloco="let t">
  <div>
    <webcam [height]="height" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      *ngIf="webcamToggle" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
      (initError)="handleInitError($event)">
    </webcam>
    <div class="snapshot" *ngIf="imageSrc">
      <img [src]="imageSrc" [height]="height" [width]="width" />
    </div>
    <br />
    <div class="d-flex gap-2 justify-content-center">
      <!-- UPLOAD FILE CHOOSER -->
      <div *ngIf="showImgChooser">
        <label for="file-input">
          <a *ngIf="multipleWebcamsAvailable && !isPictureTaken" class="btn btn-outline-primary"
             title="{{t(uploadBtnTxt)}}">
            <app-material-icon>{{uploadBtnIcon}}</app-material-icon>
            {{t(uploadBtnTxt)}}
          </a>
         </label>
        <input accept="image/*" type="file" hidden class="form-control" (change)="prepareUploadedImage($event)"
          id="file-input" name="file-input">
      </div>
      <!-- SWITCH BUTTON COMMENTED FOR NOW (AS THE WEBCAM AREA ITSELF CONTAINS A SWITCH BUTTON)
      <button *ngIf="multipleWebcamsAvailable && !isPictureTaken" class="btn btn-outline-primary"
        (click)="showNextWebcam(true);" title="{{t(switchBtnTxt)}}">
        <app-material-icon>{{switchBtnIcon}}</app-material-icon>
        {{t(switchBtnTxt)}}
      </button>
      -->
      <button *ngIf="!isPictureTaken" class="btn btn-primary" (click)="triggerSnapshot();"
        title="{{t(takePictureBtnTxt)}}">
        <app-material-icon>{{takePictureBtnIcon}}</app-material-icon>
        {{t(takePictureBtnTxt)}}
      </button>
      <button *ngIf="isPictureTaken" class="btn btn-outline-primary" (click)="resetCamera();"
        title="{{t(takeAgainPictureTxt)}}">
        <app-material-icon>{{takeAgainBtnIcon}}</app-material-icon>
        {{t(takeAgainPictureTxt)}}
      </button>
    </div>
  </div>
</div>
