import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TabConfig } from 'src/app/app-commons/components/header/tabs-config';
import { AdmPageAuthReqDto, AdmTypology } from 'src/app/data/models/adm';
import { HeaderEnum } from 'src/global/header-enum';
import { PermissionTypeEnum, PermissionsEnum } from 'src/global/permission-utils';
import { TypologiesEnum } from 'src/global/typologies-enum';

/**
 * HeaderComponent
 * @var  {string} title  The title for the view
 * @var  {HeaderEnum} type Filter of buttons needed
 * @var  {string} editRoute If the view uses a edit button this is going to be the route to navigate
 * @var  {string} createRoute If the view uses a save button this is going to be the route to navigate
 * @emits  saveAction When the save button is clicked, this component sends a signal to its parent component
 * @emits  deleteAction When the delete button is clicked, this component sends a signal to its parent component
 */

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  @Input() title = '';
  @Input() type: HeaderEnum = HeaderEnum.VIEW;
  @Input() showEditButtonInput: boolean = true;
  @Input() editRoute: any[] | string | null = '';
  @Input() createRoute: any[] | string | null = '';
  @Input() cancelRoute: any[] | string | null = '../';
  @Input() tabs: TabConfig[] = [];
  @Output() tabSelected = new EventEmitter<number>();
  @Input() validateCompleteTab = false;
  @Input() showBreadCrumbs: boolean = true;
  @Input() isCreateAction=false;

  /**
   * Show or not the delete button
   */
  @Input() showDeleteAction: boolean = true;

  @Output() saveAction = new EventEmitter<void>();
  @Output() deleteAction = new EventEmitter<void>();
  @Output() openAction = new EventEmitter<void>();
  @Output() closeAction = new EventEmitter<void>();
  @Output() createAction = new EventEmitter<void>();

  /**
   * Typology for the entity status
   */
  @Input() tpStatus!: AdmTypology;

  @ViewChild('navigationTab') navigationTab2!: ElementRef;

  headerEnum = HeaderEnum;
  isOverflow = false;
  scrollLeft = false;
  scrollRight = false;

  /**
   * Permissions
   */
  @Input() permission: number = PermissionsEnum.NONE;
  permissionType = PermissionTypeEnum;

  /**
   * Properties for {@link ApprovalViewComponent}
   */
  @Input() entityHashId!: string | null;
  @Input() authorizationOpHashId!: string | null;
  @Input() authorizationObservable!: Observable<any>;
  @Output() pageAuthReqEmitter = new EventEmitter<AdmPageAuthReqDto>();

  ngAfterViewInit() {
    if(this.tabs.length > 0) {
      setTimeout(() => {
        this.checkOverflow()
      }, 250);
    }
  }

  save() {
    this.saveAction.emit();
  }

  delete() {
    this.deleteAction.emit();
  }

  open() {
    this.openAction.emit();
  }

  close() {
    this.closeAction.emit();
  }

  create(){
    this.createAction.emit();
  }

  selectTab(position: number) {
    if (this.validateCompleteTab) {
      if (!this.tabs[position].complete) {
        return
      }
    }

    this.tabs.forEach(tab => {
      tab.view = false;
    });
    this.tabs[position].view = true;
    this.tabSelected.emit(position);
  }

  checkOverflow() {
    let element = this.navigationTab2.nativeElement as HTMLElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.isOverflow = true;
      this.scrollLeft = element.scrollLeft != 0;
      this.scrollRight = !(element.scrollLeft == (element.scrollWidth - element.clientWidth));
    } else {
      this.isOverflow = false;
    }
  }

  scrollingLeft() {

    let element = this.navigationTab2.nativeElement as HTMLElement;

    element.scrollLeft -= 50;
  }

  scrollingRight() {
    let element = this.navigationTab2.nativeElement as HTMLElement;
    element.scrollLeft += 50;
  }

  get showDeleteButton(): boolean {
    const show = this.tpStatus
      ? this.tpStatus.internalId !== TypologiesEnum.IN_PROCESS_OF_AUTHORIZATION
      && this.tpStatus.internalId !== TypologiesEnum.REJECTED
      : true;

    return (this.type === HeaderEnum.VIEW
        || this.type === HeaderEnum.VIEW_OPEN
        || this.type === HeaderEnum.VIEW_CLOSE)
      && !!this.deleteAction.observers.length
      && this.showDeleteAction
      && show;
  }

  get showEditButton(): boolean {
    const show = this.tpStatus
      ? this.tpStatus.internalId !== TypologiesEnum.IN_PROCESS_OF_AUTHORIZATION
      && this.tpStatus.internalId !== TypologiesEnum.REJECTED
      : true;

    return (this.type == HeaderEnum.VIEW
        || this.type == HeaderEnum.VIEW_OPEN
        || this.type == HeaderEnum.VIEW_CLOSE)
      && !!this.editRoute
      && show;
  }

  get showAuthorizationComponent(): boolean {
    return this.type == HeaderEnum.VIEW
      && !!this.authorizationOpHashId
      || (!!this.entityHashId && !!this.permission);
  }
}
