<ng-container *transloco="let t">

  <div class="main-container">
    <app-navbar [view]="permissionsEnum.SECURITY"></app-navbar>
    <div class="body-container">
      <app-header title="{{t('txt_configuration')}}" [type]="headerEnum.NONE"></app-header>
      <app-body>
        <label for="name" class="form-label">{{t('txt_page_name')}}</label>
        <input type="text" class="form-control" id="name" name="name" value="{{t('Page Name')}}" readonly
               placeholder="Ej. Contador titular"
               maxlength="75">

        <table class="table m-0 sticky-header table table-borderless">
          <thead>
          <tr>
            <th class="d-flex justify-content-between" scope="col"><span>Campos Generales</span>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="select-all-fields"
                  [(ngModel)]="selectAllFields"
                  (change)="selectAllInFields()"
                >
                <label class="form-check-label" for="select-all-fields">
                  {{t('txt_select_all')}}
                </label>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <div class="row mx-2">
              <ng-container *ngFor="let item of getItems()">
                <div class="col-12 col-lg-6">
                  <div class="mb-3 form-check">
                    <input type="checkbox" id="{{item.name}}" [(ngModel)]="item.isSelected"
                           (change)="selectAllFields = isAllSelected()"
                           class="form-check-input" name="{{item.name}}">
                    <label for="{{item.name}}"
                           class="form-check-label">{{t('txt_' + item.name)}}</label>
                  </div>
                </div>
              </ng-container>
            </div>
          </tr>
          </tbody>
        </table>
        <app-footer *ngIf="configAlreadyExists"
                    [type]="footerEnum.EDIT"
                    cancelRoute="/security/roles"
                    (saveAction)="save()" (deleteAction)="appConfirmDeleteRole.openModal(undefined)">
        </app-footer>
        <app-footer *ngIf="!configAlreadyExists"
                    [type]="footerEnum.EDIT"
                    cancelRoute="/security/roles"
                    (saveAction)="save()">
        </app-footer>
      </app-body>
    </div>
  </div>

  <app-confirm #appConfirmDeleteRole (actionConfirm)="deleteConfiguration()" [modalCentered]="true"
               bodyTxt="{{t('Configuration Page Name - User Name')}}"></app-confirm>
</ng-container>
