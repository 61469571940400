import { Component, DoCheck, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SimpleList } from 'src/app/data/others/simple-list';
import { ViewListEnum, ViewListValueEnum } from 'src/global/view-list-enum';
import { ColumnConfig, FilterSearch, ObjectFilter } from 'src/app/app-commons/components/search-filter/filter-util';
import { ViewListConfig } from 'src/app/app-commons/components/view-lists/view-list-util';

@Component({
  selector: 'app-view-lists',
  templateUrl: './view-lists.component.html',
  styleUrls: ['./view-lists.component.scss']
})
export class ViewListsComponent extends SimpleList implements OnChanges, DoCheck {

  @Input() config!: ViewListConfig;
  @Input() showActions: boolean = false;
  @Input() showOptions: boolean = false;

  objectFilter!: ObjectFilter;
  filters!: FilterSearch;
  columns!: ColumnConfig;

  @Output() find = new EventEmitter<void>();


  @Output() findSelec = new EventEmitter<ViewListConfig>();
  @Output() selectOne = new EventEmitter<any[]>();
  @Output() deleteAction = new EventEmitter<string>();
  @Output() editAction = new EventEmitter<string>();
  @Output() viewAction = new EventEmitter<void>();
  @Output() deleteActionAll = new EventEmitter<void>();

  simpleList: { selected: boolean, entity: any }[] = [];

  values!: any[];

  viewListEnum = ViewListEnum;
  viewListValueEnum = ViewListValueEnum;

  @Input() icon = "tag";

  ngDoCheck() {
    this.simpleList = this.config.simpleList;
    if (this.simpleList) {
      this.getValues();
    } else {
      this.simpleList = [];
    }
  }

  onEditAction(value:any){
    this.editAction.emit(value);
  }

  onViewAction(){
    this.viewAction.emit();
  }

  findAll() {
    if (this.config.identificator) {
      this.findAllSpecific();
    } else {

      this.find.emit();
    }
  }

  findAllSpecific() {
    this.findSelec.emit(this.config)
  }

  onSelect(value: any) {
    this.selectOne.emit([value, this.config]);
  }

  delete(value:any){
    this.deleteAction.emit(value);
  }

  onDeleteSelected(): void {
    this.deleteActionAll.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.objectFilter = this.config.objectFilter;
      this.filters = this.config.filters;
      this.columns = this.config.columns;
      this.simpleList = this.config.simpleList;
      if (this.simpleList) {
        this.getValues();
      }
    }
  }

  private getValues() {
    this.values = this.simpleList
      .map((entity: any) => {
        return entity.entity;
      });
    let info = JSON.parse(JSON.stringify(this.values));
    this.values = info;
  }
}
