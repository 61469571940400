<app-two-columns>

  <h2 class="fw-bold h2">Reestablecer contraseña</h2>
  <p class="mb-4 text-gray-600">Crea tu nueva contraseña para poder continuar</p>
<form class="">
    <div class="mb-3">
      <label for="sign-in-email" class="form-label">Nueva contraseña</label>
      <div class="input-prefix-icon input-suffix-icon">
        <app-material-icon>lock</app-material-icon>
        <input [(ngModel)]="changePasswordRequest.newPassword" [type]="showPass ? 'text' : 'password'" class="form-control" id="new-password"
          name="new-password" placeholder="Ingresa tu nueva contraseña" minlength="8" requerid>
          <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle" (click)="togglePassNew()">
            <span class="show-option" [hidden]="showPass">visibility</span>
            <span class="hide-option" [hidden]="!showPass">visibility_off</span>
          </app-material-icon>
        </div>
    </div>
    <div class="mb-3">
      <label for="sign-in-password" class="form-label">Confirmar contraseña</label>
      <div class="input-prefix-icon input-suffix-icon">
        <app-material-icon>lock</app-material-icon>
        <input [(ngModel)]="changePasswordRequest.confirmNewPassword" [type]="showPassConfirm ? 'text' : 'password'" class="form-control"
          id="sign-in-password" name="sign-in-password" placeholder="Ingresa de nuevo tu contraseña" minlength="8" requerid>
          <app-material-icon class="btn text-primary  btn-sm d-inline-flex type-toggle" (click)="togglePassConfirm()">
            <span class="show-option" [hidden]="showPassConfirm">visibility</span>
            <span class="hide-option" [hidden]="!showPassConfirm">visibility_off</span>
          </app-material-icon>
      </div>            
    </div>
    <button [disabled]="!isValid()" (click)="changePassword()" type="button" class="btn btn-primary w-100">Actualizar contraseña</button>
  </form>
</app-two-columns>

