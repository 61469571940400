import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/recovery';


@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {

  constructor(
    private http: HttpClient
  ) { }

  resetPasswordWithouSendMail(email: string): Observable<any> {
    let params = new HttpParams()
      .set('email', email)
    return this.http.post<any>(`${baseUrl}` + '/require-reset-password', null, { params: params });
  }

  resetPassword(email: string): Observable<any> {
    let params = new HttpParams()
      .set('email', email)
    return this.http.post<any>(`${baseUrl}` + '/reset-password', null, { params: params });
  }


  verificateToken(token: string): Observable<any> {
    let params = new HttpParams()
      .set('token', token)
    return this.http.get<any>(`${baseUrl}` + '/verificate', { params });
  }

  changePassword(passwordRecovery: any): Observable<any> {
    return this.http.post<any>(`${baseUrl}` + '/change-password', passwordRecovery);
  }

}
