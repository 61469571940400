<app-two-columns *transloco="let t">
    <h2 class="fw-bold h2">{{t('txt_password_recovery')}}</h2>
    <p class="mb-4 text-gray-600">{{t('txt_password_recovery_message')}}</p>
    <form class="">
        <div class="mb-3">
            <label for="sign-in-email" class="form-label">{{t('txt_email')}}</label>
            <div class="input-prefix-icon">
                <app-material-icon>mail</app-material-icon>
                <input [(ngModel)]="email" type="email" class="form-control" id="email"
                    name="email" placeholder="{{t('txt_email_placeholder')}}" requerid>
            </div>
        </div>
        <button [disabled]="!isValid()" (click)="sendEmail()" type="button"
            class="btn btn-primary w-100">{{t('txt_password_recovery')}}</button>
        <br>
            <button [routerLink]="['']" type="button" class="btn btn-text-primary mx-auto d-block ">
              {{t('txt_back_to_main_page')}}
            </button>
    </form>
</app-two-columns>
