import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingControlService {

  loading = false;

  showLoadingIndicator(): void {
    this.loading = true;
  }

  hideLoadingIndicator(): void {
    this.loading = false;
  }
}
