import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormatter]',
})
export class CurrencyFormatterDirective {
  @Input('appCurrencyFormatter') set freeEvent(isFree: boolean) {
    if (isFree) {
      this.setDefaultValue();
    }
  }

  private decimalSeparator: string;
  private thousandSeparator: string;

  constructor(private el: ElementRef) {
    this.decimalSeparator = '.';
    this.thousandSeparator = ',';
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.el.nativeElement.value = this.formatCurrency(value);
  }

  @HostListener('blur')
  onBlur(): void {
    if (!this.el.nativeElement.value) {
      this.setDefaultValue();
    }
  }

  private setDefaultValue(): void {
    this.el.nativeElement.value = '0.00';
    this.formatCurrency(this.el.nativeElement.value);
  }

  private formatCurrency(value: string): string {
    if (!value) return '';

    let numericValue = value.replace(/[^0-9]/g, '');

    if (numericValue === '0') {
      return `0${this.decimalSeparator}00`;
    }

    if (numericValue === '') return '';

    numericValue = numericValue.replace(/^0+(?!$)/, '');

    let formattedValue: string;
    const length = numericValue.length;

    if (length === 1) {
      formattedValue = `0${this.decimalSeparator}0${numericValue}`;
    } else if (length === 2) {
      formattedValue = `0${this.decimalSeparator}${numericValue}`;
    } else {
      const integerPart = numericValue.slice(0, length - 2);
      const decimalPart = numericValue.slice(length - 2);
      formattedValue = `${integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        this.thousandSeparator
      )}${this.decimalSeparator}${decimalPart}`;
    }

    return formattedValue;
  }
}
