import { Component } from '@angular/core';

@Component({
  selector: 'app-required',
  templateUrl: './required.component.html',
  styleUrls: ['./required.component.scss']
})
export class RequiredComponent {

}
