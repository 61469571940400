import {environment} from 'src/environments/environment';
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import { AdmParameter } from 'src/app/data/models/adm';

const baseUrl = environment.coreUrl + '/parameters'

@Injectable({
  providedIn: 'root'
})
export class AdmParameterService {

  constructor(
    private http: HttpClient
  ) {
  }

  public get(type: string): Observable<AdmParameter> {
    return this.http.get<AdmParameter>(`${baseUrl}/${type}`)
  }

  public save(parameter:any): Observable<any | unknown>{
    if(parameter.hashId){
      return this.http.put<unknown>(`${baseUrl}/${parameter.hashId}`, parameter);
    }else{
      return this.http.post<any>(`${baseUrl}`,parameter);
    }
  }

  public deleteByHashIds(hashIds: Array<string>):Observable<HttpResponse<any>>{
    return this.http.put<any>(`${baseUrl}/delete-parameters`, hashIds);
  }

  public deleteByHashId(hashId:string): Observable<any>{
    return this.http.delete<unknown>(`${baseUrl}/${hashId}`);
  }

  public listAll(queryParams: any): Observable<HttpResponse<any>>{
    let params = new HttpParams();
    //Commons
    if(queryParams['page']!==undefined){
      params = params.append('page', queryParams['page']);
    }
    if(queryParams['max']!==undefined){
      params = params.append('max', queryParams['max']);
    }
    if(queryParams['asc']!==undefined){
      params = params.append('asc', queryParams['asc']);
    }
    if(queryParams['collectionSize']!==undefined){
      params = params.append('collectionSize', queryParams['collectionSize']);
    }
    //Filter By
    if(queryParams['value']!==undefined){
      params = params.append('value', queryParams['value']);
    }
    if(queryParams['description']!==undefined){
      params = params.append('description', queryParams['description']);
    }
    if(queryParams['tpDataType']!==undefined){
      params = params.append('tpDataType', queryParams['tpDataType']);
    }
    if(queryParams['tpParameterType']!==undefined){
      params = params.append('tpParameterType', queryParams['tpParameterType']);
    }
    if(queryParams['tpStatus']!==undefined){
      params = params.append('tpStatus', queryParams['tpStatus']);
    }
    //Order By
    if (queryParams['columnOrder'] !== undefined){
      params = params.append('columnOrder', queryParams['columnOrder']);
    }
    return this.http.get<HttpResponse<any>>(baseUrl, {
      params: params,
      observe: 'response'
    });
  }

  public findByParameterType(internalId:number): Observable<any>{
    return this.http.get<any>(`${baseUrl}/find-by-parameter-type/${internalId}`);
  }

}
