<div class="switch-content">
    <div class="switch-btn">
        <ui-switch
            [checked]="status?.internalId == TE.ACTIVE"
            (change)="changeValue($event)">
        </ui-switch>
        <label *ngIf="status?.internalId === TE.ACTIVE" class="mb-1">
            {{'active' | transloco}}
        </label>
        <label *ngIf="status?.internalId == TE.INACTIVE" class="mb-1">
            {{'inactive' | transloco}}
        </label>
    </div>
</div>
