import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdmOrganization } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/organizations';

@Injectable({
  providedIn: 'root'
})
export class AdmOrganizationService {
  private MY_ORG = 'current_org';
  private MY_ORGs = 'my_org';

  constructor(
    private http: HttpClient
  ) { }

  private getQueryParams(queryParams: any) {
    let params = new HttpParams();
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['organizationCode'] !== undefined && queryParams['organizationCode'] != '')
      params = params.append('organizationCode', queryParams['organizationCode']);
    if (queryParams['billingName'] !== undefined && queryParams['billingName'] != '')
      params = params.append('billingName', queryParams['billingName']);
    if (queryParams['businessName'] !== undefined && queryParams['businessName'] != '')
      params = params.append('businessName', queryParams['businessName']);
    if (queryParams['taxId'] !== undefined && queryParams['taxId'] != '')
      params = params.append('taxId', queryParams['taxId']);
    if (queryParams['tpStatus'] !== undefined && queryParams['tpStatus'] != '')
      params = params.append('tpStatus', queryParams['tpStatus']);
    if (queryParams['admOrganizationType'] !== undefined && queryParams['admOrganizationType'] != '')
      params = params.append('admOrganizationType', queryParams['admOrganizationType']);
    if (queryParams['columnOrder'] !== undefined && queryParams['columnOrder'] != '')
      params = params.append('columnOrder', queryParams['columnOrder']);
    params = params.append('memberTypes', queryParams['memberTypes']);
    return params;
  }

  listAllHttp(queryParams: any): Observable<HttpResponse<any>> {
    const params = this.getQueryParams(queryParams);
    return this.http.get<HttpResponse<any>>(baseUrl, { params: params, observe: 'response' });
  }

  export(format: number, queryParams: any, columns: string) {
    const params = this.getQueryParams(queryParams)
      .set('columns', columns);
    return this.http.get(`${baseUrl}/export/${format}`, { params: params, responseType: 'blob' as 'json' });
  }

  currentOrganizationLS(): Observable<AdmOrganization> {
    const current = localStorage.getItem(this.MY_ORG);
    if (!current)
      return this.currentOrganization();

    const currentOrg = JSON.parse(current) as AdmOrganization;
    if (typeof currentOrg !== 'object')
      return this.currentOrganization();

    return of(currentOrg);
  }

  currentOrganization() {
    return this.http.get<AdmOrganization>(`${baseUrl}/current`)
      .pipe(tap({
        next: (org: AdmOrganization) => localStorage.setItem(this.MY_ORG, JSON.stringify(org))
      }));
  }

  listMyOrganizationsLS(): Observable<AdmOrganization[]> {
    const my = localStorage.getItem(this.MY_ORGs);
    if (!my)
      return this.listMyOrganizations();

    const myOrg = JSON.parse(my) as AdmOrganization[];
    if (typeof myOrg !== 'object')
      return this.listMyOrganizations();

    return of(myOrg);
  }

  listMyOrganizations() {
    return this.http.get<AdmOrganization[]>(`${baseUrl}/my`)
      .pipe(tap({
        next: (organizations: AdmOrganization[]) => localStorage.setItem(this.MY_ORGs, JSON.stringify(organizations))
      }));
  }

  save(organization: AdmOrganization): Observable<number | unknown> {
    if (organization.organizationId) {
      return this.http.put<unknown>(`${baseUrl}/${organization.organizationId}`, organization);
    }
    return this.http.post<number>(`${baseUrl}`, organization);
  }


  create(admOrganization: AdmOrganization): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(baseUrl, admOrganization);
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(baseUrl + '/' + id);
  }

  find(id: number): Observable<AdmOrganization> {
    return this.http.get<AdmOrganization>(baseUrl + '/' + id);
  }

  findByHashId(hashId: string): Observable<AdmOrganization> {
    return this.http.get<AdmOrganization>(`${baseUrl}/hashId/${hashId}`);
  }

  update(organization: AdmOrganization): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(baseUrl + '/' + organization.organizationId, organization);
  }

  filterContacts(queryParams: any, hashId:string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['tpOrganizationContactPurpose'] !== undefined)
      params = params.append('tpOrganizationContactPurpose', queryParams['tpOrganizationContactPurpose']);
    if (queryParams['tpOrganizationContactType'] !== undefined)
      params = params.append('tpOrganizationContactType', queryParams['tpOrganizationContactType']);
    if (queryParams['contactDetail'] !== undefined && queryParams['contactDetail'] != '')
      params = params.append('contactDetail', queryParams['contactDetail']);
    if (queryParams['contactDetail2'] !== undefined && queryParams['contactDetail2'] != '')
      params = params.append('contactDetail2', queryParams['contactDetail2']);
    if (queryParams['contactPerson'] !== undefined && queryParams['contactPerson'] != '')
      params = params.append('contactPerson', queryParams['contactPerson']);
    if (queryParams['contactPersonInformation'] !== undefined && queryParams['contactPersonInformation'] != '')
      params = params.append('contactPersonInformation', queryParams['contactPersonInformation']);
      if (queryParams['description'] !== undefined && queryParams['description'] != '')
        params = params.append('description', queryParams['description']);
    if (queryParams['columnOrder'] !== undefined && queryParams['columnOrder'] != '')
      params = params.append('columnOrder', queryParams['columnOrder']);
    return this.http.get<HttpResponse<any>>(`${baseUrl}/organization-contacts/${hashId}`, { params: params, observe: 'response' });
  }

  filterAttachments(queryParams: any, hashId:string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['tpAttachmentType'] !== undefined)
      params = params.append('tpAttachmentType', queryParams['tpAttachmentType']);
    if (queryParams['description'] !== undefined && queryParams['description'] != '')
      params = params.append('description', queryParams['description']);
    if (queryParams['startDate'] !== undefined && queryParams['startDate'] != '')
      params = params.append('startDate', queryParams['startDate']);
    if (queryParams['endDate'] !== undefined && queryParams['endDate'] != '')
      params = params.append('endDate', queryParams['endDate']);
    if (queryParams['columnOrder'] !== undefined && queryParams['columnOrder'] != '')
      params = params.append('columnOrder', queryParams['columnOrder']);
    return this.http.get<HttpResponse<any>>(`${baseUrl}/organization-attachments/${hashId}`, { params: params, observe: 'response' });
  }

  deleteCorrelativesById(organizations: number[]) {
    return this.http.put<unknown>(`${baseUrl}/delete-organizations`, organizations);
  }

  getDocumentsToExpire():Observable<HttpResponse<any>>{
    return this.http.get<HttpResponse<any>>(`${baseUrl}/documents-to-expire`, {
      observe: 'response'
    });
  }


}
