import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabConfig } from 'src/app/app-commons/components/header/tabs-config';
import { AdmUser, AdmUserCredentialDto } from 'src/app/data/models/adm';
import { AdmUsersService } from 'src/app/services/core/adm-users.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { HeaderEnum } from 'src/global/header-enum';
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  constructor(private admUserService: AdmUsersService,
    private toaster: ToasterService,
    private router: Router) { }

  user!: AdmUser;
  credential = new AdmUserCredentialDto;
  confirmPassword !: string;
  showPass = false;
  showPassC = false;
  showPassN = false;
  headerEnum = HeaderEnum;

  tabs = [
    new TabConfig("txt_general_info", true, undefined),
    new TabConfig("txt_change_of_password", false, undefined)
  ];

  ngOnInit(): void {
    this.getMeProfile();
  }

  changeView(tabSelected: number) {
    this.credential = new AdmUserCredentialDto;
    this.confirmPassword = "";
  }

  getMeProfile() {
    let profile = localStorage.getItem('profile');
    if (profile != null) {
      let user = JSON.parse(profile);
      this.admUserService.getEntity(user.hashId).subscribe({
        next: (value) => {
          this.user = value;
        }, error: () => {
          this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
        },
      })
    }
  }

  save() {
    if (this.isValid()) {
      if (this.tabs[0].view) {
        this.admUserService.save(this.user).subscribe({
          next: () => {
            this.toaster.show({ message: "msg_success_changes_saved", header: "Exito", type: ToasterEnum.SUCCESS });
            this.router.navigate(['/profile']);
          }, error: () => {
            this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
          },
        })
      } else if (this.tabs[1].view) {
        this.credential.hashId = this.user.hashId;
        this.admUserService.changePassword(this.credential).subscribe({
          next: () => {
            this.toaster.show({ message: "msg_success_changes_saved", header: "Exito", type: ToasterEnum.SUCCESS });
            this.changeView(2);
          }, error: (error) => {
            switch (error.status) {
              case 500:
                this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR });
                break;
              default:
                this.toaster.show({ message: error.error,  type: ToasterEnum.ERROR });
                break;
            }
          }
        })
      }
    }
  }
  isValid() {
    if (this.tabs[0].view) {
      if (this.user && this.user.admPerson.firstName && this.user.admPerson.lastName && this.user.email1) {
        if (this.user && this.user.admPerson.firstName.length > 0 && this.user.admPerson.lastName.length > 0 && this.user.email1.length > 0) {
          return true;
        }
      }
    } else if (this.tabs[1].view) {
      if (this.credential.oldPassword && this.confirmPassword && this.credential.password) {
        if (this.credential.oldPassword.length > 0 && this.confirmPassword.length > 0 && this.credential.password.length > 0) {
          if (this.credential.password == this.confirmPassword) {
            return true;
          }
        }
      } else {
        this.toaster.show({ message: "Campos requeridos no completos", type: ToasterEnum.ERROR })
      }
    }
    return false;
  }
}
