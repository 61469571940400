<ng-template #content let-modal *transloco="let t">
    <div class="modal-header">
        <div class="d-flex flex-column">

            <h4 class="m-0">Ajuste de imagen</h4>
            <span class="text-muted">{{t('txt_crop_description')}}</span>
        </div>
        <div class="container-btn-close">

            <div class="p-2">
                <button type="button" class="btn icon-close-modal" (click)="cancel()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body" *transloco="let t">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="text-muted m-0">{{t('txt_crop_image')}}</p>
                    <image-cropper class="img-cropper" [imageChangedEvent]="originalImage"
                        [containWithinAspectRatio]="true" [aspectRatio]="aspectRatio" format="png" [resizeToWidth]="512"
                        [onlyScaleDown]="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                </div>
                <div class="col-sm-12 col-md-6">
                    <p class="text-muted m-0">{{t('txt_crop_preview')}}</p>
                    <img class="border img-preview" [src]="croppedImage" />
                </div>
            </div>
            <div *ngIf="informationRequired" class="row">
                <div class="w-100">
                    <div class="mb-3">
                        <label for="title" class="form-label required-field">
                            {{t('txt_title')}}
                        </label>
                        <input maxlength="200" class="form-control" placeholder="{{t('txt_title_post_placeholder')}}"
                            [(ngModel)]="title" required [ngClass]="{'is-invalid': !title}" />
                        <div class="invalid-feedback">
                            {{t('msg_validation_required_field')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="w-100">
                    <div class="mb-3">
                        <label for="title" class="form-label">
                            {{t('txt_description')}}
                        </label>
                        <textarea maxlength="200" class="form-control"
                            placeholder="{{t('txt_description_placeholder')}}" [(ngModel)]="description"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="cancel()" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button (click)="saveImage()" type="button" class="btn btn-primary">Cargar imagen</button>
    </div>
</ng-template>