import {Component} from '@angular/core';
import {HeaderEnum} from "src/global/header-enum";
import {PermissionsEnum} from "src/global/permission-utils";
import {FooterEnum} from "src/global/footer-enum";

const createItemMock = (quantity: number): { name: string, description: string, isSelected: boolean }[] => {
  let items = [];
  for (let i = 0; i < quantity; i++) {
    items.push({
      name: `some name ${i}`,
      description: "some description",
      isSelected: false,
    })
  }
  return items;
}

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {
  headerEnum = HeaderEnum;
  permissionsEnum = PermissionsEnum;
  items = createItemMock(10);
  selectAllFields = false;
  footerEnum = FooterEnum;
  // this should come from the backend maybe
  configAlreadyExists = true;

  getItems(): { name: string, description: string, isSelected: boolean }[] {
    return this.items;
  }

  selectAllInFields() {
    this.items.forEach(item => item.isSelected = this.selectAllFields);
  }

  isAllSelected(): boolean {
    return this.getItems().every((item) => item.isSelected);
  }

  save() {
    // TODO implement method
  }

  deleteConfiguration() {
    // TODO implement method
  }
}
