<ng-container *transloco="let t ">
  <div>
    <div class="border-bottom">
      <h1 class="h6 fw-medium my-2">{{ t('pending_approvals') }} <span class="small">({{ filters.collectionSize }})</span></h1>
    </div>
    <div class="d-flex flex-column mb-auto">
      <ng-container *ngFor="let auth of pageAuthOpList">
        <div role="button" class="card my-2" (click)="goToLink(auth)">
          <div class="card-body py-1">
            <div class="d-flex align-items-center gap-2 text-primary-300">
              <app-material-icon>description</app-material-icon>
              <h1 class="h6 my-2 text-primary-300 fw-semibold">{{ t(auth.permissionName) }}</h1>
              <p class="my-1">
                <app-status-badge [typology]="auth.tpPageAuthorizationType"></app-status-badge>
              </p>
            </div>
            <p class="my-1">
              {{ t('permission_applied') }}:
              <span class="fw-semibold text-muted">
                {{ auth.permissionAuthName }}
              </span>
            </p>
            <p class="my-1">
              {{ t('txt_entry_date') }}:
              <span class="fw-semibold text-muted">{{ auth.entryDate | date: 'dd/MM/yyyy - HH:mm' }}</span>
            </p>
            <p class="my-1">
              {{ t('txt_user') }}:
              <span class="fw-semibold text-muted">{{ auth.creatorUser.email1 }} - {{ auth.creatorUser.admPerson.fullName }}</span>
            </p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="pageAuthOpList.length === 0">
        <app-empty-panel customPadding="2.5rem"></app-empty-panel>
      </ng-container>
    </div>
  </div>
</ng-container>
