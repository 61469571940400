<ng-container *transloco="let t">
  <div>
    <div class="border-bottom">
      <h1 class="h6 fw-medium my-2">{{t('alerts')}}</h1>
    </div>
    <div class="d-flex flex-column mb-auto mt-2">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
