<div class="main-container" *transloco="let t">
  <app-navbar #nav></app-navbar>
  <div class="body-container">
    <app-body>
      <h1 class="text-center text-primary">
        {{ t(title) }}
      </h1>
      <h1 class="text-center text-primary">
        <app-material-icon [size]="52">{{ icon }}</app-material-icon>
      </h1>
      <p class="text-center fs-3">
        {{ t(message) }}
      </p>
      <div class="d-flex gap-4 justify-content-center">
        <div id="back">
          <a role="button" (click)="nav.return()"
             class="menu-item d-flex flex-column align-items-center gap-2 text-decoration-none">
            <div class="bg-white p-4 rounded d-flex justify-content-center align-items-center text-primary-200">
              <app-material-icon [size]="36">arrow_back</app-material-icon>
            </div>
            <p class="h6 fw-semibold text-gray-500 text-center">{{t('txt_return')}}</p>
          </a>
        </div>
      </div>
    </app-body>
  </div>
</div>
