import { HttpResponse } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { ColumnConfig, FilterSearch, ObjectFilter } from 'src/app/app-commons/components/search-filter/filter-util';
import { SimpleList } from 'src/app/data/others/simple-list';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ErrorMessageEnum } from 'src/global/message-enum';
import { ToasterEnum } from 'src/global/toaster-enum';

/**
 * Modal to select item/items from a list.
 *
 * @Example
 * ```html
 * <app-pick-list #myList
 *    modalTitle="My list"
 *    [columns]="columnsEntity"
 *    [filters]="filtersEntity"
 *    [objectFilter]="objectFilterEntity"
 *    [observable]="observableEntityList"
 *    (actionUpdateObservable)="updateObservable($event)"
 *    (actionSelect)="manageEntitySelected($event)"
 *    (actionAdditional)="actionAdditionalWithEntity($event)"
 *    [useAdditionalAction]="true"
 *    [multiple]="false"
 * ></app-pick-list>
 * ```
 */
@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html',
  styleUrls: ['./pick-list.component.scss']
})
export class PickListComponent extends SimpleList {

  /**
   * Modal title.
   */
  @Input() modalTitle = 'txt_title';

  /**
   * Size of a new modal window.
   * Type: 'sm' | 'lg' | 'xl' | string
   */
  @Input() modalSize = 'xl';

  /**
   * Scrollable modal content.
   * Type: boolean
   * Default value is true.
   */
  @Input() modalScrollable: boolean = true;

  /**
   * If true, the modal will be centered vertically.
   * Type: boolean
   * Default value is false.
   */
  @Input() modalCentered: boolean = false;

  /**
   * If true, cancel button will be showed.
   * Type: boolean
   * Default value is true.
   */
  @Input() showCancelButton: boolean = true;

  /**
   * If true, entity additional action will be used.
   * Type: boolean
   * Default value is false.
   */
  @Input() useAdditionalAction: boolean = false;

  /**
   * If true, the backdrop element will be created for a given modal.
   * Alternatively, specify 'static' for a backdrop which doesn't close the modal on click.
   * Default value is true.
   * Type: boolean | 'static'
   */
  @Input() backdrop: boolean | 'static' = true;

  /**
   * The modal content reference.
   */
  @ViewChild('content') content!: ElementRef;

  /**
   *  The personalized event emitter to indicate
   *  the action when item has been selected
   */
  @Output() actionSelect = new EventEmitter();

  /**
   *  The personalized event emitter to indicate
   *  the action to update the observable to get items list
   */
  @Output() actionUpdateObservable = new EventEmitter();

  /**
   *  The personalized event emitter to indicate
   *  the additional action to do with every entity added to simpleList
   *  Important: If "useAdditionalAction" is false, then the "actionAdditional" will not work.
   */
  @Output() actionAdditional = new EventEmitter();

  /**
   * A reference to the newly opened modal returned by the NgbModal.open() method.
   */
  modalRef!: NgbModalRef;

  /**
   * Save de result when modal is closed.
   */
  closeResult = '';

  /**
   * Table column configurations.
   * Implemented property to SimpleList class.
   * Type: ColumnConfig
   */
  @Input() columns!: ColumnConfig;

  /**
   * Filters for the search in the database.
   * Implemented property to SimpleList class.
   * Type: FilterSearch
   */
  @Input() filters!: FilterSearch;

  /**
   * Table filter configurations.
   * Implemented property to SimpleList class.
   * Type: ObjectFilter
   */
  @Input() objectFilter!: ObjectFilter;

  /**
   * The unique key of any item entity (To find selected items when iterating over simpleList)
   * Example: 'hashId' 'sessionId'
   */
  @Input() itemKey?: string;

  /**
   * Icon to display in the first column of every row.
   */
  @Input() rowsIcon?: string;


  /**
 * To show or not select all checkbox when multiple is true
 */
  @Input() showSelectAll?: boolean = true;

  /**
  * To show or not select all checkbox when multiple is true
  */
  @Input() showFiltersAndColumns?: boolean = true;

  @Input() displayInputFilters?: boolean = false;


  /**
   * List that contains the items.
   * Implemented property to SimpleList class.
   */
  simpleList: { selected: boolean; entity: any }[] = [];

  /**
   * entity that is opening the modal (OPTIONAL)
   */
  entity: any;
  /**
   * Observable to get database information (The entity list).
   * @param observable
   * To subscribe to an observable and receive the items list.
   */
  @Input() set observable(observable: Observable<HttpResponse<any>>) {
    if (observable) {
      observable.subscribe({
        next: (entities) => {
          this.simpleList = (entities.body ?? []).map((entity: any) => {
            if (this.useAdditionalAction) {
              this.actionAdditional.emit(entity);
            }
            return { selected: false, entity: entity };
          });
          this.filters.collectionSize = Number(entities.headers.get('X-Count-Total'));
          //Set selected items
          if (this.multiple && this.itemKey) {
            this.selectedElements.forEach((initialSelectedElement: any) => {
              let itemToSelect = this.simpleList.find(item => item.entity[this.itemKey!] === initialSelectedElement[this.itemKey!])
              if (itemToSelect) {
                itemToSelect.selected = true;
              } else {

              }
            });
          } else if (!this.multiple && this.itemKey) {
            this.simpleList.find(item => item.entity[this.itemKey!] === this.selectedElement[this.itemKey!])!.selected = true;
          }


        }, error: () => {
          this.showErrorMessage(ErrorMessageEnum.GETTING_INFORMATION);
        }
      });
    }
  }

  /**
   * Allows to select multiple items.
   * Type: boolean
   * Default value is false.
   */
  @Input() multiple: boolean = false;

  /**
   * The element selected by the user when multiple is false.
   */
  selectedElement: any;

  /**
   * The elements selected by the user when multiple is true.
   */
  selectedElements: any[] = [];



  constructor(
    protected modalService: NgbModal,
    private toasterService: ToasterService,
  ) {
    super();
  }

  /**
   * Method to cancel the selection and close the modal.
   */
  cancel(): void {
    this.close();
  }


  /**
   * Method to set selected items when opening the modal
   */

  setSelectedAndOpen(initSelected: any, optionalEntity?: any): void {
    this.open(optionalEntity)
    if (initSelected) {
      if (this.multiple) {
        this.selectedElements = initSelected
      } else {
        this.selectedElement = initSelected
      }
    }
  }

  /**
   * Method to open the modal with the specific parameters.
   * optionalParameter can be sent to indicate the object that is triggering the modal
   */
  open(optionalEntity?: any): void {
    this.findAll();
    this.entity = optionalEntity;
    this.modalRef = this.modalService.open(
      this.content,
      {
        size: this.modalSize,
        scrollable: this.modalScrollable,
        centered: this.modalCentered,
        backdrop: this.backdrop
      }
    );

    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.resetSelection();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.resetSelection();
    });
  }


  /**
   * Method to close the modal.
   * @protected
   */
  protected close(): void {
    this.modalRef.close();
  }

  /**
   * Method to reset the selection.
   * @private
   */
  private resetSelection(): void {
    this.selectedElement = null;
    this.selectedElements = [];
    this.simpleList.forEach((item: any) => {
      item.selected = false;
    });
  }

  /**
   * Method to get dismiss reason.
   * @param reason
   * @private
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * This function prevents the keys from being sorted alphabetically
   * and the actions column from being returned
   * @param obj
   * This param is the columns config
   */
  sortedKeys(obj: { [key: string]: any }) {
    return Object.keys(obj).filter((key) => key !== 'actions');
  }

  /**
   * Method to get the data from the database.
   * This use an event emitter to send the filters.
   */
  findAll(): void {
    this.actionUpdateObservable.emit(this.filters);
  }

  /**
   * Shows an error message.
   * @param message
   * Indicates the error message to show.
   * @private
   */
  private showErrorMessage(message: string): void {
    this.toasterService.show({
      type: ToasterEnum.ERROR,
      message: message
    });
  }

  /**
   * This method is to validate the multiple or unique selection.
   * If multiple = false, every time an option is selected others will be unselected.
   * @param element
   * Element parameter is the object selected. I
   * If multiple = false, element selected will be referenced in "selectedElement" variable.
   */
  validateMultiple(element: any): void {
    let selected = element.selected;
    if (!this.multiple) {
      this.simpleList.forEach((item: any) => {
        item.selected = false;
      });
      element.selected = selected;
      element.selected ? this.selectedElement = element : this.selectedElement = null;
    } else {
      if (selected) {
        this.selectedElements.push(element.entity);
      } else {
        //Remove from selectedElements
        let indexToRemove = this.selectedElements.findIndex(item => item[this.itemKey!] === element.entity[this.itemKey!])
        if (indexToRemove > -1) { // only splice array when item is found
          this.selectedElements.splice(indexToRemove, 1); // 2nd parameter means remove one item only
        }
      }
    }

  }

  /**
   * Select method is the final process when select button is pressed, this will validate
   * the multiple or unique selection and according to it will send the selection through
   * "actionSelect" event emitter.
   * If multiple = true, this method will send an object array.
   * If multiple = false, this method will send an object.
   * If entity != null/undefined, this method will send a mapped object with the entity that triggered the modal
   */
  select(): void {
    if (this.multiple) {
      /*
      this.selectedElements = [];
      this.simpleList.forEach((item: any) => {
        if (item.selected) {
          this.selectedElements.push(item.entity);
        }
      });
      */
      if (this.selectedElements.length > 0) {
        if (!this.entity) {
          this.actionSelect.emit(this.selectedElements);
        } else {
          let elementsWithParent = {
            parentEntity: this.entity,
            elements: this.selectedElements
          }
          this.actionSelect.emit(elementsWithParent);
        }
        this.close();
      }
    } else {
      if (this.selectedElement != null) {
        if (!this.entity) {
          this.actionSelect.emit(this.selectedElement.entity);
        } else {
          let elementWithParent = {
            parentEntity: this.entity,
            element: this.selectedElement.entity
          }
          this.actionSelect.emit(elementWithParent);
        }
        this.close();
      }
    }
  }

}
