import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('signaturePad', { static: false }) signaturePadElement2!: ElementRef;
  private signaturePad: any;

  @Input() width: number = 400;
  @Input() height: number = 250;


  @Output() signatureData = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }

  async saveSignature() {
    if (!this.signaturePad.isEmpty()) {
      const signatureData = await this.signaturePad.toDataURL();
      this.signatureData.emit(signatureData);
      this.cleanSignature()
    }
  }

  startSignPadDrawing(event: Event) {
    if (!this.signaturePad) {
      const canvas: HTMLCanvasElement = this.signaturePadElement2.nativeElement;
      this.signaturePad = new SignaturePad(canvas);
    }
  }


  cleanSignature() {
    this.signaturePad.clear();
  }

}
