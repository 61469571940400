import { Component, Input } from '@angular/core';
import { AdmTypology } from 'src/app/data/models/adm';
import { TypologiesEnum } from 'src/global/typologies-enum';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent {
  @Input() typology!: AdmTypology;

  @Input() txtToDisplay!: string;


  ngOnChanges() {
  }

  get clazz(): string {
    switch (this.typology.internalId) {
      case TypologiesEnum.ACTIVE:
      case TypologiesEnum.FP_OPENED:
      case TypologiesEnum.AOS_AUTHORIZED:
      case TypologiesEnum.BR_INVOICED:
      case TypologiesEnum.BR_ACCOUNTING_AUTHORIZED:
        return 'text-bg-success'
      case TypologiesEnum.DELETE:
      case TypologiesEnum.FP_CLOSED:
      case TypologiesEnum.REJECTED:
      case TypologiesEnum.AOS_REJECTED:
      case TypologiesEnum.BR_ERROR:
      case TypologiesEnum.BR_ACCOUNTING_REJECTED:
        return 'text-bg-danger';
      case TypologiesEnum.INACTIVE:
      case TypologiesEnum.FP_PENDING:
      case TypologiesEnum.BR_ENLISTED:
      case TypologiesEnum.AS_PENDING_INVOICE:
        return 'text-bg-inactive';
      case TypologiesEnum.LOCKED:
      case TypologiesEnum.FP_REVISION:
      case TypologiesEnum.PGAT_ELIMINATION:
      case TypologiesEnum.AOS_IN_REVIEW:
      case TypologiesEnum.AS_UNCOMPLETED:
        return 'text-bg-warning';
      case TypologiesEnum.IN_PROCESS_OF_AUTHORIZATION:
      case TypologiesEnum.BR_COMPLETED:
        return 'text-bg-secondary';
      default:
        return 'text-bg-primary'
    }
  }
}
