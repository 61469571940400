export enum ListEnum {
  /**
   * Input type text
   */
  TEXT,

  /**
   * Single selection selector, specific for typologies
   */
  UNIQUE_TP,

  /**
   * Single selection selector, for any object. (requires defining more information)
   */
  UNIQUE_OBJ,

  /**
   * Date picker
   */
  DATE,

  /**
   * Reading selector (object)
   * Use in edit or make sure default values ​​will be added before sending data
   */
  READ_ONLY,

  /**
   * Input type number
   */
  NUMBER_POSITIVE,
  NUMBER_ALL,
  NUMBER_POSITIVE_DECIMAL,
  NUMBER_ALL_DECIMAL,

  /**
   * Hour and minute selector
   */
  TIME,

  /**
   * input to upload files
   */
  FILE,

  /**
   * a button will be displayed, a modal will be launched and another list will be displayed there
   */
  LIST_WITHIN_LIST,

  /**
   * Single selection selector with restriction (parent or child)
   */
  UNIQUE_TP_WITH_RESTRICTION,

  /**
   * Single selection selector with restriction (parent or child)
   */
  UNIQUE_OBJ_WITH_RESTRICTION,
  /**
   * Use for any number with operation
    */
  ADD,
  /**
   * 
Use for any number with an arithmetic operation (multiplication, addition, subtraction)
    */
  MULTIPLICATION,
  SUBTRACTION,
  /**
   * put the result of the arithmetic operation
    */
  RESULT,
  /**
   * default value for math operation
    */
  NONE,
  /**
   *  
   * Hide input
  */
  HIDDEN
}