<div class="main-container">
  <app-navbar></app-navbar>
  <div class="body-container">
    <app-header title="Crear tipo de proveedor"></app-header>
    <app-body *transloco="let t">
      <app-required></app-required>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            <label for="ptGeneratedCode" class="form-label">
              {{t('pt_generated_code')}}
            </label>
            <span class="text-danger fw-bold ">*</span>
            <input type="text" class="form-control" name="ptGeneratedCode" id="ptGeneratedCode" required >
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            <label for="ptSpace">{{t('pt_space')}}</label>
            <span class="text-danger fw-bold ">*</span>
            <ng-select id="ptSpace" name="ptSpace" class="w-100"
                       [items]="spaces" bindValue="typologyId" bindLabel="description"
                       [searchFn]="searchPtSpace" [compareWith]="compareWith" [(ngModel)]="spaces"
                       [multiple]="false" [searchable]="true" [closeOnSelect]="true" [clearable]="false"
                       [hideSelected]="true" placeholder="{{t('pt_space_placeholder')}}"
            >
              <ng-template ng-label-tmp let-item="item">
                <span>{{t(item.description)}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <span>{{t(item.description)}}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-12 col-lg-12">
          <div class="mb-3">
            <label for="ptName" class="form-label">{{t('pt_name')}}</label>
            <span class="text-danger fw-bold ">*</span>
            <input type="text" class="form-control" id="ptName" name="ptName"
                   placeholder="{{t('pt_name_placeholder')}}">
          </div>
        </div>
      </div>
    </app-body>
  </div>
</div>
