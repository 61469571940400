import {Component, OnInit} from '@angular/core';
import {AdmTypologiesService} from "../../services/core/adm-typologies.service";
import {TranslocoService} from "@ngneat/transloco";
import {TypologiesEnum} from 'src/global/typologies-enum';
import { AdmTypology } from 'src/app/data/models/adm';

@Component({
  selector: 'app-organization-type',
  templateUrl: './organization-type.component.html',
  styleUrls: ['./organization-type.component.scss']
})
export class OrganizationTypeComponent implements OnInit{

  public spaces = [];

  constructor(
    private typologyService:AdmTypologiesService,
    private translocoService: TranslocoService
  ) {
  }

  ngOnInit() {
    this.typologyService.listByParentInternalId(TypologiesEnum.TP_SPACE).subscribe({
      next: (typologies: any) => {
        this.spaces = typologies;
      }, error: _ => {}
    });
  }

  searchPtSpace = (term: string, item: any) => {
    return this.translocoService.translate(item.description).toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  compareWith = (item: AdmTypology, selected: number) => {
    return item.typologyId === selected
  }

}
