import { ListEnum } from "src/global/list-enum";

/**
 * @example
 * ListConfig.fromUniqueTp('tpPersonContactType', false, ListEnum.UNIQUE_TP,
 * 'txt_contact_mean', "txt_contact_mean_placeholder", "description"
 * )
 * @example
 * ListConfig.fromUniqueObj(
 * 'roleId', false, ListEnum.UNIQUE_OBJ, 'txt_role_list',
 * 'txt_role_list_placeholder', 'name', 'roleId'
 * )
 * @example
 * ListConfig.fromDate(
 * 'startDate', false, ListEnum.DATE, 'txt_role_list_start_date',
 * 'txt_role_list_start_date_placeholder', undefined, 2, true
 * )
 * @example
 * ListConfig.fromText(
 * 'contactDetail', false, ListEnum.TEXT, 'txt_contact_mean_contact_detail',
 * "txt_contact_mean_contact_detail_placeholder", 75
 * )
 * @example
 * ListConfig.fromAnyNumber(
 * 'description', false, ListEnum.NUMBER_ALL, 'txt_contact_mean_description',
 * "txt_contact_mean_description_placeholder", 15, '^([0-9]|[1-9][0-9])$', undefined
 * )
 * @example
 * ListConfig.fromTime(
 * 'description', false, ListEnum.TIME, 'txt_contact_mean_description'
 * )
 * @example
 * ListConfig.fromUniqueTp('tpPersonContactType', false, ListEnum.READ_ONLY,
 * 'txt_contact_mean', "txt_contact_mean_placeholder", "description", ""
 * ),
 * @example
 * ListConfig.fromFile(
 * 'description', false, ListEnum.FILE, 'txt_contact_mean_description',
 * "txt_contact_mean_description_placeholder", 'image/*'
 * )
 * @example
 * ListConfig.fromUniqueTPWithRestriction('tpPersonContactType1', false, ListEnum.UNIQUE_TP_WITH_RESTRICTION,
 * 'txt_contact_mean1', "txt_contact_mean_placeholder1", "description", true, false, undefined, []
 * ),
 * ListConfig.fromUniqueTPWithRestriction('tpPersonContactType2', false, ListEnum.UNIQUE_TP_WITH_RESTRICTION,
 * 'txt_contact_mean2', "txt_contact_mean_placeholder2", "description", false, true, 4, []
 * ),
 * @examples
 * ListConfig.fromUniqueObjWithRestriction(
 * 'roleId', false, ListEnum.UNIQUE_OBJ_WITH_RESTRICTION, 'txt_role_list',
 * 'txt_role_list_placeholder', 'name', 'roleId', true, false,
 * undefined, []
 * ),
 * ListConfig.fromUniqueObjWithRestriction(
 * 'roleId', false, ListEnum.UNIQUE_OBJ_WITH_RESTRICTION, 'txt_role_list',
 * 'txt_role_list_placeholder', 'name', 'roleId', false, true,
 * 4, []
 * ),
 */
export class ListConfig {

    private static defaultConstructor(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string
    ) {
        const cls = new ListConfig();
        cls.fieldName = fieldName;
        cls.isOptional = isOptional;
        cls.type = type;
        cls.label = label;
        cls.placeholder = placeholder;
        return cls;
    }

    public static fromText(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, maxlength: number, width: string = '-1'
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.maxlength = maxlength;
        cls.width = width;
        return cls;
    }

    public static fromUniqueTp(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, bindLabel: string
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.bindLabel = bindLabel;
        return cls;
    }

    public static fromUniqueObj(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, bindLabel: string, bindValue: string, groupBy?: string, uniqueValues?: boolean
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.bindLabel = bindLabel;
        cls.bindValue = bindValue;
        cls.uniqueValues = true;
        if (groupBy) {
            cls.groupBy = groupBy;
        }
        return cls;
    }

    public static fromDate(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, minDatePosition: number | undefined,
        maxDatePosition: number | undefined, todayRestriction: boolean = false
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.minDatePosition = minDatePosition;
        cls.maxDatePosition = maxDatePosition;
        cls.todayRestriction = todayRestriction;
        return cls;
    }

    public static fromAnyNumber(
        fieldName: string, isOptional: boolean, type: ListEnum, label: string,
        placeholder: string, maxlength: number, numberIntPattern: string | undefined,
        numberDecimalPattern: string | undefined
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.maxlength = maxlength;
        cls.numberIntPattern = numberIntPattern ?? '^(0|-?[1-9][0-9]*)$';
        cls.numberDecimalPattern = numberDecimalPattern ?? '^(0|-?0.[0-9]+|-?[1-9][0-9]*(.[0-9]+)?)$';
        return cls;
    }
    public static fromAnyNumberWithOperation(
        fieldName: string, isOptional: boolean, type: ListEnum, label: string,
        placeholder: string, maxlength: number, numberIntPattern: string | undefined,
        numberDecimalPattern: string | undefined, position: number | undefined, operationType: ListEnum,
        storageResult: number | undefined, width: string = '-1', value?: any, useDefaultValue?: boolean
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.maxlength = maxlength;
        cls.numberIntPattern = numberIntPattern ?? '^(0|-?[1-9][0-9]*)$';
        cls.numberDecimalPattern = numberDecimalPattern ?? '^(0|-?0.[0-9]+|-?[1-9][0-9]*(.[0-9]+)?)$';
        cls.position = position ?? 0;
        cls.operation = operationType;
        cls.storageResult = storageResult ?? 0;
        cls.width = width;
        cls.value = value??0;
        cls.useDefaultValue = useDefaultValue??false;

        return cls;
    }
    public static fromShowResult(
        fieldName: string, isOptional: boolean, type: ListEnum, label: string,
        position:number
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, "");
        cls.position = position;
        return cls;
    }
    
    public static fromTime(
        fieldName: string, isOptional: boolean, type: ListEnum, label: string
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, '');
        return cls;
    }

    public static fromReadOnly(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, bindLabel: string, bindValue: string
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.bindLabel = bindLabel;
        cls.bindValue = bindValue;
        return cls;
    }

    public static fromFile(
        fieldName: string, isOptional: boolean, type: ListEnum, label: string,
        placeholder: string, acceptedValues: string | undefined
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.acceptedValues = acceptedValues ?? '*/*';
        return cls;
    }

    public static fromUniqueTPWithRestriction(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, bindLabel: string,
        affectChildren: boolean, affectedByParent: boolean, parentPosition: number | undefined,
        auxValues: any[] | undefined
    ) {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.bindLabel = bindLabel;
        cls.affectChildren = affectChildren;
        cls.affectedByParent = affectedByParent;
        if (parentPosition !== undefined) {
            cls.parentPosition = parentPosition;
        }
        if (auxValues !== undefined) {
            cls.auxValues = auxValues;
        }
        return cls;
    }

    public static fromUniqueObjWithRestriction(
        fieldName: string, isOptional: boolean, type: ListEnum,
        label: string, placeholder: string, bindLabel: string, bindValue: string,
        affectChildren: boolean, affectedByParent: boolean, parentPosition: number | undefined,
        auxValues: any[] | undefined, width: string = '-1') {
        const cls = ListConfig.defaultConstructor(fieldName, isOptional, type, label, placeholder);
        cls.bindLabel = bindLabel;
        cls.bindValue = bindValue;
        cls.affectChildren = affectChildren;
        cls.affectedByParent = affectedByParent;
        cls.width = width;
        if (parentPosition !== undefined) {
            cls.parentPosition = parentPosition;
        }
        if (auxValues !== undefined) {
            cls.auxValues = auxValues;
        }
        return cls;
    }

    constructor(

        /**
         * Specific name of the field (database).
         * Not optional.
         */
        public fieldName: string = '',

        /**
         * Indicates if it is optional, if it is it can be eliminated.
         * Not optional.
         */
        public isOptional: boolean = true,

        /**
         * Type of input to display.
         * Not optional.
         */
        public type: ListEnum = ListEnum.TEXT,

        /**
         * Name to identify the input.
         * Not optional.
         */
        public label: string = '',

        /**
         * Placeholder for input.
         * Not optional.
         */
        public placeholder: string = '',

        /**
         * List of selectable values ​​for lists.
         * Not optional.
         */
        public values: any[] = [],

        /**
         * Name of the field with which it will match in
         * the selection list (only for object, in tipology default is description).
         */
        public bindLabel: string | undefined = undefined,

        /**
         * Used in lists, indicates if the values ​​can only be selected once.
         */
        public uniqueValues: boolean | undefined = undefined,

        /**
         * Use for lists with unique values. Name of the field with which the
         * comparisons will be made (it can be the id or the hashId).
         */
        public bindValue: string | undefined = undefined,

        /**
         * Position in configValues[] ​​referencing another date field
         * to bind to as a min date constraint.
         */
        public minDatePosition: number | undefined = undefined,

        /**
         * Position in configValues[] ​​referencing another date field
         * to bind to as a max date constraint.
         */
        public maxDatePosition: number | undefined = undefined,

        /**
         * maximum character size in input
         */
        public maxlength: number = 25,

        /**
         * custom pattern for number input (int or decimal)
         */
        public numberIntPattern: string = '^(0|-?[1-9][0-9]*)$',
        public numberDecimalPattern: string = '^(0|-?0.[0-9]+|-?[1-9][0-9]*(.[0-9]+)?)$',

        /**
         * type of files accepted
         */
        public acceptedValues: string = '*/*',

        /**
         * show button to launch child list
         */
        public showChildList: boolean = false,

        /**
         * configuration list for secondary list
         */
        public configValues: ListConfig[] = [],

        /**
         * button text to launch the modal
         */
        public btnTxtChildList = 'txt_add_items',

        /**
         * changin a value affects all children
         */
        public affectChildren: boolean = false,

        /**
         * values affected by parent
         */
        public affectedByParent: boolean = false,

        /**
         * parent position, detect changes
         */
        public parentPosition: number = 0,

        /**
         * list of auxiliary values to be displayed when parent value changes
         * must be an array of arrays, the position of the parent will be displayed
         */
        public auxValues: any[] = [],

        /**
         * restrict minimum date to today
         */
        public todayRestriction: boolean = false,
        public position: number = 0,
        public operation: ListEnum = ListEnum.NONE,
        public storageResult:number = -1,
        public disabled = false,
        
        /**
         * OPTIONAL - Used in unique_obj, to groupBy value
        */
        public groupBy: string = '',
        /**
         * OPTIONAL - set column width
         */
        public width: string = '-1',
        /**
         * defualt value
         */
        public value: any = 0,
        /**
         * use default value
         */
        public useDefaultValue: boolean = false,
         
    ) { }
}

/**
 * Structure of values ​​processed by the component
 */
export class ValueConfig {
    constructor(
        /**
         * Checks if row can be deleted, if added in component defaults to true.
         */
        public canBeDeleted: boolean,

        /**
         * Fields and added values
         */
        public row: any,

        /**
         * Check if all required values ​​in row are complete, if added in component defaults to false.
         */
        public isComplete: boolean
    ) { }
}


export class ListSendValuesConfig {
    /**
    * Table title
    * Default is txt_list
    */
    public title: string = 'txt_list';

    /**
    * Add button text.
    * Default is txt_add_more
    */
    public btnTxt: string = 'txt_add_more';

    /**
     * List where the added values ​​will be. Receive any type.
     * The names of the attributes of the objects must match the names of the configurations.
     *
     * The data will come back transformed, use the transformation service to get a
     * list with the structure of the objects.
     */
    public values: any[] = [];

    /**
     * List of configurations for information management.
     * Value required for validations and adding new elements.
     * In ListEnum are the valid types.
     */
    public configValues: ListConfig[] = [];

    /**
     * Check if I'm on a create or edit in the parent component.
     */
    public isCreation: boolean = true;

    /**
     * If you get rows and the isCreation field is true then default rows will be added
     * which are required and cannot be removed.
     */
    public defaultRows: number = 0;

    /**
     * Indicates if all rows have been completed
     */
    public completedRows = true;

    /**
     * delete column not shown
     */
    public showDeleteBtns = true;

    /**
     * the buttons to add elements are not shown
     */
    public showAddBtns = true;

    /**
     * limit of items in list. number greater than 0
     */
    public itemLimit: number = 0;
    /**
     * show total
     * default is false
     */ 
    public showTotal = false;
    public total: number = 0;
    public labelTotal = 'txt_total';
    
    /**
     * column with data to sum for total
     */
    public sumColumn: number = -1; 
    /**
     * limit
     */
    public limit : number = -1;
}
