import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterSearch } from 'src/app/app-commons/components/search-filter/filter-util';
import { AdmPageAuthOpDto } from 'src/app/data/models/adm';
import { AdmPageAuthOpService } from 'src/app/services/core/adm-page-auth-op.service';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { PermissionsEnum } from 'src/global/permission-utils';
import { ToasterEnum } from 'src/global/toaster-enum';

@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss']
})
export class ApprovalListComponent implements OnInit {
  filters: FilterSearch = {
    filter_id: '',
    collectionSize: 0,
    max: -1,
    page: 1
  };
  @Input() permission: number = PermissionsEnum.NULL;
  pageAuthOpList: AdmPageAuthOpDto[] = [];

  constructor(
    private authorizationOpService: AdmPageAuthOpService,
    private permissionService: AdmPermissionsService,
    private toastService: ToasterService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.findAll();
  }

  findAll() {
    this.filters = { ...this.filters, parent: this.permission, inReview: true };
    this.authorizationOpService.findAll(this.filters)
      .subscribe({
        next: (response) => {
          this.pageAuthOpList = response.body ?? [];
          this.filters.collectionSize = Number(response.headers.get('X-Count-Total') ?? 0);
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
      });
  }

  goToLink(auth: AdmPageAuthOpDto) {
    if (!this.permissionService.isNavigationAllowed(auth.permissionUrl))
      return this.toastService.show({ type: ToasterEnum.ERROR, message: 'no_permission' });

    const url = this.router.createUrlTree(
      [auth.permissionUrl, 'view', auth.entityHashId],
      { queryParams: { authorization: auth.hashId } }
    );
    setTimeout(() => {
      window.open(`#${url}`, '_blank')
    }, 500);
  }

  onPageChange() {
    this.findAll();
  }
}
