import { Directive, HostListener, ElementRef, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ControlValueAccessor, Validator } from '@angular/forms';

@Directive({
  selector: '[inputTrim][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTrimDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTrimDirective),
      multi: true
    }
  ]
})
export class InputTrimDirective implements ControlValueAccessor, Validator {
  constructor(private el: ElementRef<HTMLInputElement>) { }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    const trimmedValue = value.trim();
    this.el.nativeElement.value = trimmedValue;

    if (!trimmedValue) {
      this.el.nativeElement.setCustomValidity('This field is required.');
    } else {
      this.el.nativeElement.setCustomValidity('');
    }

    this.onChange(trimmedValue);
    this.onTouched();
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.el.nativeElement.value = value == null ? '' : value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Validator method
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!this.el.nativeElement.value.trim() && this.el.nativeElement.required) {
      return { required: true };
    }
    return null;
  }

  private onChange = (value: any) => { };
  private onTouched = () => { };
}
