import { Component, Input } from '@angular/core';
import { ViewField } from 'src/global/view-list-enum';

@Component({
  selector: 'app-view-field',
  templateUrl: './view-field.component.html',
  styleUrls: ['./view-field.component.scss']
})
export class ViewFieldComponent {

  @Input() value: any;
  @Input() label!: string;
  @Input() viewFieldType!: any;
  @Input() rowStyle: string = "col-12 col-lg-6";

  viewFieldEnum = ViewField;


  goToLink(url: string) {
    window.open(url, "_blank");
  }

}





