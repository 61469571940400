<div class="row" *transloco="let t">
  <div class="d-flex gap-2 justify-content-center">
    <p class="fw-bold bd-highlight">{{t('txt_tutor_sign')}}</p>
  </div>
  <div class="d-flex justify-content-center">
    <canvas #signaturePad [height]="height" [width]="width" style="border: 1px solid #000;"
      (click)="startSignPadDrawing($event)" (touchstart)="startSignPadDrawing($event)"></canvas>
  </div>
  <div class="d-flex gap-2 justify-content-center my-2">
    <button type="button" class="btn btn-outline-primary" (click)="cleanSignature()">{{
      t("txt_clean_sign") }}
    </button>
    <button class="btn btn-primary px-4" (click)="saveSignature()">
      {{ t("txt_send_sign") }}
    </button>
  </div>
</div>
