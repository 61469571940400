<div *ngIf="type != footerEnum.NONE" class="footer border-top pt-3 pb-3 mb-3">
  <ng-container *transloco="let t">
    <!-- CREATE -->
    <div *ngIf="type == footerEnum.CREATE" class="d-flex gap-2 justify-content-end">
      <button
        [routerLink]="cancelRoute"
        class="btn btn-outline-primary px-4"
      >
        {{t('cancel')}}
      </button>
      <button
        *ngIf="needsSaveAsDraft"
        (click)="saveAsDraft()"
        class="btn btn-primary"
        [appCheckPermission]="permission"
        [permissionType]="permissionType.CREATE"
      >{{t('save_draft')}}</button>
      <button
        *ngIf="showSaveButton"
        (click)="save()"
        class="btn btn-primary px-5"
        [appCheckPermission]="permission"
        [permissionType]="permissionType.CREATE"
      >
        {{t('create')}}
      </button>
    </div>

    <div *ngIf="type == footerEnum.GENERATE" class="d-flex gap-2 justify-content-end">
      <button
        [routerLink]="cancelRoute"
        class="btn btn-outline-primary px-4"
      >
        {{t('cancel')}}
      </button>

      <button
        *ngIf="generateAction.observers.length"
        (click)="generate()"
        class="btn btn-primary px-5"
        [appCheckPermission]="permission"
        [permissionType]="permissionType.CREATE"
      >
        {{t('generate')}}
      </button>
    </div>

    <!-- CREATE WITH WIZARD -->
    <div *ngIf="type == footerEnum.CREATE_WITH_WIZARD" class="d-flex gap-2 justify-content-between">
      <div *ngIf="!needsCancel">
        <button
          [routerLink]="cancelRoute"
          class="btn btn-outline-primary px-4"
        >
          {{t('cancel')}}
        </button>
      </div>
      <div *ngIf="needsCancel">
        <button
          (click)="cancel()"
          class="btn btn-outline-primary px-4"
        >
          {{t('cancel')}}
        </button>
      </div>

      <div class="d-flex gap-2 justify-content-end">
        <button
          *ngIf="wizardBack"
          class="btn btn-outline-primary px-4"
          (click)="navigate(false)"
        >
          {{t('txt_back')}}
        </button>
        <button
          *ngIf="wizardContinue"
          class="btn btn-primary px-4"
          (click)="navigate(true)"
        >
          {{t('txt_continue')}}
        </button>
        <button
          *ngIf="wizardSave"
          (click)="save()"
          class="btn btn-primary px-5"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.CREATE"
        >
          {{t('create')}}
        </button>
      </div>
    </div>

    <!-- VIEW WITH WIZARD -->
    <div *ngIf="type == footerEnum.VIEW_WITH_WIZARD" class="d-flex gap-2 justify-content-end">
      <button
        *ngIf="wizardBack"
        class="btn btn-outline-primary px-4"
        (click)="navigate(false)"
      >
        {{t('txt_back')}}
      </button>
      <button
        *ngIf="wizardContinue"
        class="btn btn-primary px-4"
        (click)="navigate(true)"
      >
        {{t('txt_continue')}}
      </button>
    </div>

    <!-- EDIT -->
    <div *ngIf="type == footerEnum.EDIT" class="d-flex justify-content-between">
      <div>
        <button
          *ngIf="showDeleteButton"
          class="btn btn-danger px-4"
          (click)="delete()"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.DELETE"
        >
          {{ t('delete') }}
        </button>
      </div>
      <div class="d-flex gap-2 justify-content-end">
        <button
          [routerLink]="cancelRoute"
          class="btn btn-outline-primary px-4"
        >
          {{t('cancel')}}
        </button>
        <button
          *ngIf="needsSaveAsDraft"
          (click)="saveAsDraft()"
          class="btn btn-primary"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.UPDATE"
        >{{t('save_draft')}}</button>
        <button
          *ngIf="showSaveButton"
          (click)="save()"
          class="btn btn-primary px-4"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.UPDATE"
        >
          {{t('save')}}
        </button>
      </div>
    </div>

    <!-- EDIT WITH WIZARD -->
    <div *ngIf="type == footerEnum.EDIT_WITH_WIZARD" class="d-flex justify-content-between">
      <div class="d-flex gap-2 justify-content-start">
        <button
          *ngIf="showDeleteButton"
          class="btn btn-danger px-4"
          (click)="delete()"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.DELETE"
        >
          {{ t('delete') }}
        </button>
        <button
          [routerLink]="cancelRoute"
          class="btn btn-outline-primary px-4"
        >
          {{t('cancel')}}
        </button>
      </div>

      <div class="d-flex gap-2 justify-content-end">
        <button
          *ngIf="wizardBack"
          class="btn btn-outline-primary px-4"
          (click)="navigate(false)"
        >
          {{t('txt_back')}}
        </button>
        <button
          *ngIf="wizardContinue"
          class="btn btn-primary px-4"
          (click)="navigate(true)"
        >
          {{t('txt_continue')}}
        </button>
        <button
          *ngIf="wizardSave"
          (click)="save()"
          class="btn btn-primary px-5"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.UPDATE"
        >
          {{t('save')}}
        </button>
      </div>
    </div>

    <!-- REPORT -->
    <div *ngIf="type == footerEnum.REPORT" class="d-flex justify-content-between">
      <div class="d-flex gap-2 justify-content-start">
        <button
          [routerLink]="cancelRoute"
          class="btn btn-outline-primary px-4"
        >
          {{t('cancel')}}
        </button>
      </div>
      <div class="d-flex gap-2 justify-content-end">
        <button
          *ngIf="showExtraButton"
          class="btn btn-outline-primary px-4"
          (click)="extra()"
        >
          {{t(txtExtraButton)}}
        </button>
        <button
          (click)="report()"
          class="btn btn-primary px-5"
          [appCheckPermission]="permission"
          [permissionType]="permissionType.EXPORT"
        >
        {{t(txtReportButton)}}
        </button>
      </div>
    </div>
    <!-- CREATE PRODUCT TRANSACTION-->
    <div *ngIf="type == footerEnum.CREATE_PRODUCT_TRANSACTION" class="d-flex gap-2 justify-content-end">
      <button
        [routerLink]="cancelRoute"
        class="btn btn-outline-primary px-4"
      >
        {{t('cancel')}}
      </button>

      <button
        *ngIf="saveDraftAction.observers.length"
        (click)="saveDraft()"
        class="btn btn-primary px-5"
        [appCheckPermission]="permission"
        [permissionType]="permissionType.CREATE"
      >
        {{t('save_draft')}}
      </button>

      <button
        *ngIf="saveAction.observers.length"
        (click)="save()"
        class="btn btn-primary px-5"
        [appCheckPermission]="permission"
        [permissionType]="permissionType.CREATE"
      >
        {{t('create')}}
      </button>
    </div>

  </ng-container>
</div>
