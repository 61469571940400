export class AdmToken {
  constructor(
    public authc: string
  ) {
  }
}

export class AdmTypology {
  constructor(
    public typologyId: number,
    public cliOrganizationId: number,
    public ownerOrganizationId: number,
    public description: string,
    public internalId: number,
    public value1: string,
    public value2: string,
    public translatable: boolean,
    public parentTypologyId: number,
  ) {
  }
}

export class AdmUser {
  public userId !: number;
  public cliOrganizationId !: number;
  public ownerOrganizationId !: number;
  public admPerson = new AdmPerson();
  public nickname !: string;
  public hashId !: string;
  public avatarImage !: string;
  public tpTimezone!: AdmTypology;
  public email1 !: string;
  public email2 !: string;
  public password !: string;
  public secretQuestion !: string;
  public secretAnswer !: string;
  public passUpdateDate !: string;
  public requireChangePassword: boolean = true;
  public tpTimeUnit!: AdmTypology;
  public tpUnitNumber!: AdmTypology;
  public tpStatus !: AdmTypology;
  public recoveryToken!: string;
  public entryDate!: string;
  public creatorUserId!: number;
  public providerId!: number;
  public customerId!: number;
  public clientId!: number;
  public externalUser: boolean = false;

  //only for dto
  public memberType !: AdmTypology;
  public roles: AdmRole[] = [];
  public role!: string;
  public internalId!: number;
  public passwordConfirm!: string;
}

export class AdmPerson {
  public personId !: number;
  public cliOrganizationId !: number;
  public ownerOrganizationId !: number;
  public tpPersonTitle !: AdmTypology;
  public firstName !: string;
  public middleName !: string;
  public lastName !: string;
  public secondLastName !: string;
  public marriedName !: string;
  public partnerName !: string;
  public birthday !: string | Date;
  public taxId !: string;
  public personalId !: string;
  public profession !: string;
  public tpReligion !: AdmTypology;
  public tpBloodType !: AdmTypology;
  public tpGenre !: AdmTypology;
  public tpNationality !: AdmTypology;
  public tpMaritalStatus !: AdmTypology;
  public tpCountry !: AdmTypology;
  public annotation !: string;
  public tpStatus !: AdmTypology;
  public tpTaxRegime!: AdmTypology;
  public phoneNumber !: string;
  public admPersonAddress !: AdmPersonAddress[];
  public admPersonContact !: AdmPersonContact[];
  public admPersonAttachments!: AdmPersonAttachment[];
  public fullName !: string;
}

export class AdmPersonAttachment {
  public personAttachmentId!: number;
  public tpAttachmentType!: AdmTypology;
  public url!: string;
  public description!: string;
  public startDate!: string;
  public endDate!: string;
}

export class AdmPersonAttachmentDto {
  public description!: string
  public startDate!: string
  public endDate!: string
  public attachmentTypeDescription!: string
  public personDescription!: string
  public personId!: number
}

export class AdmPersonAddress {
  public personAddressId !: number;
  public tpPersonAddressType !: AdmTypology;
  public title !: string;
  public telephoneNumber!: string;
  public addressLine1 !: string;
  public addressLine2 !: string;
  public zipCode !: string;
  public latitude !: number;
  public longitude !: number;
  public altitude !: number;
  public tpCountry !: AdmTypology;
  public tpState !: AdmTypology;
  public tpCity !: AdmTypology | null;
  public zone !: number;

  //only for dto
  public internalAddressId: number = 1;
}

export class AdmPersonContact {
  public personContactId !: number;
  public tpPersonContactType !: AdmTypology;
  public tpPersonContactPurpose !: AdmTypology;
  public contactDetail !: string;
  public description !: string;

  //only for dto
  public internalContactId: number = 1;
}

export class AdmPermission {
  constructor(
    public permissionId: number
  ) {
  }

  public cliOrganizationId!: number;
  public tpPermissionType!: AdmTypology;
  public name!: string;
  public sref!: string;
  public icon!: string;
  public priority!: number;
  public tpStatus!: AdmTypology;
  public enableConfiguration!: boolean;

  public internalId!:number;
  //only for front
  public admPermissions: AdmPermission[] = [];
  public parentPermissionId!: number;
  public permission!: AdmRolePermission;
  public level!: number;
}

export class AdmRole {
  public roleId!: number;
  public hashId!: string;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public name!: string;
  public description: string = '';
  public home!: string;
  public priority!: number;
  public internalId!: number;
  public tpStatus!: AdmTypology;
  public admRolePermissions: AdmRolePermission[] = [];
  public entryDate!: string;
  public creatorUserId!: number;
}

export class AdmUserRole {
  public userRoleId!: number;
  public userId!: number;
  public roleId!: number;
  public startDate!: Date;
  public endDate!: Date;
  public fromDate!: Date;
  public thruDate!: Date;
}

export class AdmRolePermission {
  public rolePermissionId!: number | undefined;
  public admPermission!: AdmPermission;
  public createPermission: boolean = false;
  public deletePermission: boolean = false;
  public exportPermission: boolean = false;
  public readPermission: boolean = false;
  public updatePermission: boolean = false;
}

export class AdmPermissionDto {
  constructor(
    public permissionId: number,
    public internalId: number,
    public createPermission: boolean,
    public deletePermission: boolean,
    public exportPermission: boolean,
    public readPermission: boolean,
    public updatePermission: boolean,
    public icon: string,
    public name: string,
    public sref: string,
    public priority: number,
    public parentPermissionId: number
  ) {
  }

  public notify!: string;
  public childrenPermissions!: AdmPermissionDto[];
}

export class AdmRoleDto {
  public roleId!: number;
  public hashId!: string;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public description!: string;
  public home!: string;
  public priority!: number;
  public name!: string;
  public tpStatus!: AdmTypology;
  public internalId!: number;
}

export class AdmUserRoleDto {
  public userRoleId!: number;
  public roleId!: number;
  public userId!: number;
  public email1!: string;
  public nickname!: string;
  public firstName!: string;
  public middleName!: string;
  public lastName!: string;
  public fullName!: string;
  public status: boolean = false;
  public roleName!: string;
}

export class AdmRolePermissionDto {
  public admPermission!: AdmPermission;
  public admRolePermission!: AdmRolePermission;
  public numberPermission: number = 0;
  public activeAll: boolean = false;
}

export class AdmUserCredentialDto {
  public oldPassword !: string;
  public password !: string;
  public hashId!: string;
}

export class AdmUserOverrideCredentialDto {
  public newOwnId!: number;
  public fingerprint!: string;
}

export class AdmOrganization {
  public organizationId!: number;
  public organizationCode!: string;
  public hashId!: string;
  public name!: string;
  public businessName!: string;
  public taxId!: string;
  public employeeCount!: number;
  public organizationTypeId!: number;
  public organizationTypeName!: string;
  public anniversary!: string;
  public annotation!: string;
  public tpTaxRegime!: AdmTypology;
  public legalRepresentativePerson!: AdmPerson;
  public tpCountry!: AdmTypology;
  public tpSocietyType!: AdmTypology;
  public felActive!: boolean;
  public ownerPerson!: string;
  public ownerTaxId!: string;
  public website!: string;
  public billingName!: string;
  public category!: AdmCategory;

  public tpStatus!: AdmTypology;
  public admOrganizationType!: AdmOrganizationType;
  public admOrganizationImages!: AdmOrganizationImage[];
  public admOrganizationContacts!: AdmOrganizationContact[];
  public admOrganizationWorkDays!: AdmOrganizationWorkDay[];
  public admOrganizationFranchises!: AdmOrganizationFranchises[];
  public admOrganizationAddresses!: AdmOrganizationAddress[];
  public admOrganizationAttachments!: AdmOrganizationAttachment[];

  public fiscalAddress!: AdmOrganizationAddress;

}

export class AdmOrganizationAttachment {
  public organizationAttachmentId!: number;
  public tpAttachmentType!: AdmTypology;
  public url!: string;
  public description!: string;
  public startDate!: string;
  public endDate!: string;
}

export class AdmOrganizationAttachmentDto {
  public description!: string
  public startDate!: string
  public endDate!: string
  public attachmentTypeDescription!: string
  public organizationDescription!: string
  public organizationId!: number
}

export class AdmOrganizationAddress {
  public organizationAddressId!: number;
  public title!: string;
  public addressLine1!: string;
  public addressLine2!: string;
  public tpOrganizationAddressType!: AdmTypology;
  public zipCode!: string;
  public longitude!: number;
  public latitude!: number;
  public altitude!: number;
  public tpCountry!: AdmTypology;
  public tpState!: AdmTypology;
  public tpCity!: AdmTypology | null;
  public zone!: number;

}

export class AdmOrganizationImage {
  url!: string;
  tpOrganizationImageType!: AdmTypology;
  defaultImage!: string;
}

export class AdmOrganizationContact {
  tpOrganizationContactType!: AdmTypology;
  contactDetail!: string;
  contactDetail2!: string;
  contactPerson!: string;
  contactPersonInformation!: string;
  description!: string;
  tpOrganizationContactPurpose!: AdmTypology;
}

export class AdmOrganizationWorkDay {
  tpFromDay!: AdmTypology;
  tpToDay!: AdmTypology;
  description!: string;
  admOrganizationWorkTimes!: AdmOrganizationWorkTime[];
}

export class AdmOrganizationWorkTime {
  startTime!: string;
  endTime!: string;
  description!: string;
}


export class FileDto {
  constructor(
    public fileDataUri: string,
    public fileUrl: string
  ) {
  }
}

export class AdmOrganizationFranchises {
  public franchise!: Franchises;
  public admOrganization!: AdmOrganization;
}

export class Franchises {
  public franchisesId!: number;
  public code!: string;
  public name!: string;
  public percentage!: number;
  public billingPeriod!: number;
  public tpStatus!: AdmTypology;

  public hashId!: string;

}

export class AdmOrganizationType {

  public organizationTypeId!: number;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public tpOrganizationSpace!: AdmTypology;
  public code!: string;
  public name!: string;
  public internalId!: number;
  public systemValue!: string;
  public tpStatus!: AdmTypology;
  public tpProviderType!: AdmTypology;
  public tpProviderSubType!: AdmTypology;
  public hashId!: string | null;
  public admOrganizationTypeConfigs!: Array<AdmOrganizationTypeConfig>;
}

export class AdmOrganizationTypeConfig {
  public organizationTypeConfigId!: number;
  public organizationTypeId!: number;
  public tpProviderField!: AdmTypology;
  public tpProviderFieldStatus!: AdmTypology;
  public tpProviderPermission!: AdmTypology;
  public visible!:boolean;
  public required!:boolean;
}

export class AdmOrganizationTypeDto {
  public organizationTypeId!: number;
  public tpOrganizationSpace!: AdmTypology;
  public code!: string;
  public name!: string;
  public internalId!: number;
  public systemValue!: string;
  public tpStatus!: AdmTypology;
  public tpProviderType!: AdmTypology;
  public tpProviderSubType!: AdmTypology;
  public hashId!: string
}

export class AdmCoreCorrelative {
  coreCorrelativeId !: number;
  name !: string;
  autoIncrement !: boolean;
  showOrganizationCode !: boolean;
  showYear !: boolean;
  showMonth !: boolean;
  digitNumber !: number;
  incrementNumber !: number;
  initialValue !: number;
  currentValue !: number;
  prefix !: string;
  suffix !: string;
  restartYearly !: boolean;
  restartMonthly !: boolean;
  manual !: boolean;
  tpCodeType !: AdmTypology;
  month !: number;
  year !: number;
  tpStatus !: AdmTypology;
  hashId !: string;
}

export class AdmCoreCorrelativeDto {

  admCoreCorrelative !: AdmCoreCorrelative;
  value !: string;

}


export class AdmCategory {
  public categoryId!: number;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public name!: string;
  public code!: string;
  public tpStatus!: AdmTypology;
  public hashId!: string;
}

export class AdmParameter {
  public parameterId!: number;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public value!: string;
  public tpDataType!: AdmTypology;
  public tpParameterType!: AdmTypology;
  public tpStatus!: AdmTypology;
  public entryDate!: string;
  public creatorUserId!: number;
  public description!: string;
  public hashId!: string | null;
}

export class AdmParameterDto {
  public parameterId!: number;
  public value!: string;
  public tpDataType!: AdmTypology;
  public tpParameterType!: AdmTypology;
  public tpStatus!: AdmTypology;
  public description!: string;
  public hashId!: string;
}

export class AdmLogDto {
  public logId!: number;
  public hashId!: string;
  public namespace!: string;
  public message!: string;
  public entryDate!: string;
  public tpLogType!: AdmTypology;
  public userEmail!: string;
  public userFirstName!: string;
  public userLastName!: string;

  public permissionId!: number;
  public internalPermissionId!: number;
  public sref!: string;
}

export class AdmExportConfiguration {
  public exportConfigurationId!: number;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public userId!: number;
  public permissionInternalId!: number;
  public tpExportFormat!: AdmTypology;
  public configurationName!: string;
  public entryDate!: string;
  public details!: AdmExportConfigurationDetail[]
}

export class AdmExportConfigurationDetail {
  public exportConfigurationDetailId!: number;
  public tpExportFieldType!: AdmTypology;
  public fieldName!: string;
  public fieldValue!: string;
}

export class AdmExportConfigurationDto {
  public exportConfigurationId!: number;
  public permissionInternalId!: number;
  public tpExportFormat!: AdmTypology;
  public configurationName!: string;
}

export class AdmPageConfiguration {
  public hashId!: string;
  public roleId!: number;
  public permissionId!: number;
  public entryDate!: string;
  public admPageField!: AdmPageField[];
}

export class AdmPageField {
  public pageFieldId !: number;
  public pageConfigurationId!: string;
  public tpField!: AdmTypology;
  public isSelected!: boolean;
}

export class AdmPageAuthorization {
  public pageAuthorizationId!: number;
  public hashId!: string;
  public permissionId!: number;
  public cliOrganizationId!: number;
  public ownerOrganizationId!: number;
  public name!: string;
  public description!: string;
  public entryDate!: string;
  public creatorUserId!: number;
  public pageAuthRoles!: AdmPageAuthRole[];
  public pageAuthSteps!: AdmPageAuthStep[];
  public tpPageAuthorizationType!: AdmTypology;
  public tpStatus!: AdmTypology;
}

export class AdmPageAuthRole {
  public pageAuthRoleId!: number;
  public roleId!: number;
}

export class AdmPageAuthStep {
  public pageAuthStepId!: number;
  public stepNumber!: number;
  public roleId!: number;
}

export class AdmPageAuthorizationDto {
  public pageAuthorizationId!: number;
  public hashId!: string;
  public authorizationName!: string;
  public description!: string;
  public entryDate!: string;
  public permissionId!: number;
  public permissionName!: string;
  public permissionUrl!: string;
  public tpPageAuthorizationType!: AdmTypology;
  public tpStatus!: AdmTypology;
}

export class AdmPageAuthOpDto {
  public pageAuthOpId!: number;
  public hashId!: string;
  public permissionAuthName!: string;
  public entryDate!: string;
  public entityHashId!: string;
  public permissionId!: number;
  public permissionName!: string;
  public permissionUrl!: string;
  // AdmUserDto
  public creatorUser!: AdmUser;
  public tpPageAuthorizationType!: AdmTypology;
  public tpAuthorizationStatus!: AdmTypology;

  // only for frontend
  public pageAuthSteps: AdmPageAuthStepOpDto[] = [];
  public pageAuthStepLogsOp: AdmPageAuthStepLogOpDto[] = [];
}

export class AdmPageAuthStepOpDto {
  public pageAuthStepOpId!: number;
  public roleId!: number;
  public roleName!: string;
  public tpAuthorizationStatus!: AdmTypology;
}

export class AdmPageAuthReqDto {
  public pageAuthStepOpId!: number;
  public tpAuthorizationStatus!: number;
  public pageAuthOpHashId!: string;
  public entityHashId!: string;
  public reviewCommentary!: string;
}

export class AdmPageAuthStepLogOpDto {
  public pageAuthStepLogOpId!: number;
  public reviewCommentary!: string;
  public tpAuthorizationStatus!: AdmTypology;
  public entryDate!: string;
  public reviewingUser!: AdmUser;
}
