<div class="container my-3" *transloco="let t">
    <div class="row text-center">
        <div class="col-md-4">
            <div class="btn-group">
                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Previous
                </div>
                <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                    Today
                </div>
                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Next
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale | titlecase}}</h3>
        </div>
        <div class="col-md-4">
            <div class="btn-group">
                <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    {{t('txt_month')}}
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    {{t('txt_week')}}
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    {{t('txt_day')}}
                </div>
            </div>
        </div>
    </div>
    <br />
    <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [locale]="locale" [viewDate]="viewDate"
            [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
            (beforeViewRender)="beforeViewRender($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [locale]="locale" [viewDate]="viewDate"
            [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (beforeViewRender)="beforeViewRender($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [locale]="locale" [viewDate]="viewDate"
            [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (beforeViewRender)="beforeViewRender($event)">
        </mwl-calendar-day-view>
    </div>
</div>