import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { forkJoin, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { AdmToken, AdmUser, AdmUserOverrideCredentialDto } from 'src/app/data/models/adm';
import { AdmOrganizationService } from 'src/app/services/core/adm-organization.service';
import { environment } from 'src/environments/environment';
import { ToasterEnum } from "src/global/toaster-enum";
import { AdmRolesService } from 'src/app/services/core/adm-roles.service';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { AdmUsersService } from 'src/app/services/core/adm-users.service';
import { CurrentUserService } from 'src/app/services/others/current-user.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private fingerprint = 'foo';

  constructor(
    private http: HttpClient,
    private admRoleService: AdmRolesService,
    private admUserService: AdmUsersService,
    private admPermissionService: AdmPermissionsService,
    private currentUserService: CurrentUserService,
    private organizationService: AdmOrganizationService,
    private toaster: ToasterService
  ) {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((res) => (this.fingerprint = res.visitorId));
  }

  doLogin(
    loginRequest: any,
    successCallback: (profile: AdmUser) => void = (_) => console.log('Login successful'),
    errorCallback: (err: any) => void = (err) => {
      this.toaster.show({ message: "msg_error",  type: ToasterEnum.ERROR })
    }
  ): void {
    loginRequest = { ...loginRequest, fingerprint: this.fingerprint };

    this.http.post<AdmToken>(`${environment.authUrl}/authc/ajax`, loginRequest)
      .pipe(
        mergeMap(token => {
          this.storeToken(token.authc);
          return of('');
        }),
        switchMap(_ => this.organizationService.currentOrganization()),
        switchMap(_ => this.organizationService.listMyOrganizations()),
        mergeMap(_ => forkJoin({
          profile: this.admUserService.getMe(),
          roles: this.admRoleService.findMyRoles(),
          permissions: this.admPermissionService.findUserPermissions()
        })),
        mergeMap(result => {
          this.currentUserService.updateCurrentUser(JSON.parse(JSON.stringify(result.profile)));
          const profile = result.profile;
          profile.roles = result.roles;
          return of(profile);
        })
      )
      .subscribe({
        next: profile => successCallback(profile),
        error: err => errorCallback(err),
      });
  }

  overrideCredential(
    newOwnId: number,
    successCallback: (profile: AdmUser) => void = (_) => console.log('successfully changed context!'),
    errorCallback: (err: any) => void = (_) => {
      this.toaster.show({ message: 'msg_error_server', type: ToasterEnum.ERROR });
    }
  ) {
    const credential = new AdmUserOverrideCredentialDto();
    credential.newOwnId = newOwnId;
    credential.fingerprint = this.fingerprint;

    this.http.post<AdmToken>(`${environment.authUrl}/authc/override`, credential)
      .pipe(
        mergeMap(token => {
          this.storeToken(token.authc);
          return of('');
        }),
        switchMap(_ => this.organizationService.currentOrganization()),
        switchMap(_ => this.organizationService.listMyOrganizations()),
        mergeMap(_ => forkJoin({
          profile: this.admUserService.getMe(),
          roles: this.admRoleService.findMyRoles(),
          permissions: this.admPermissionService.findUserPermissions()
        })),
        mergeMap(result => {
          this.currentUserService.updateCurrentUser(JSON.parse(JSON.stringify(result.profile)));
          const profile = result.profile;
          profile.roles = result.roles;
          return of(profile);
        })
      )
      .subscribe({
        next: profile => successCallback(profile),
        error: err => errorCallback(err)
      });
  }

  getToken(): string | null {
    return localStorage.getItem('nibelungo');
  }

  storeToken(token: string): void {
    if (token.startsWith('Bearer ')) {
      token = token.substring('Bearer '.length);
    }
    localStorage.setItem('nibelungo', token);
  }

}
