/**
 * Permissions Types
 */

export enum PermissionTypeEnum {
  READ = "read",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  EXPORT = "export",
  MENU = "menu",
  NONE = "none"
}

/**
 * Permissions Enum
 */
export const PermissionsEnum = {
  /**
   * Default
   */
  NULL: 0,
  /**
   * Default to skip permission validation
   */
  NONE: -1,
  /**
   *  Module permission type
   */
  HOME: 1,
  /**
   *  Module permission type
   */
  ADMINISTRATION: 2,
    ORGANIZATIONS: 43,
    CORRELATIVES: 44,

  /**
   *  Module permission type
   */
  SECURITY: 3,
    ROLES: 4,
    USERS: 5,
    PARAMETERS: 80,
    AUTHORIZATIONS: 6,
    ACTIVITY_REGISTRATIONS: 7,

  /**
   *  Module permission type
   */
  POSTS: 8,
  EVENTS: 9,
  NEWS: 10,
  INTERESTING_PLACES: 11,
  ROUTES: 12
}



