<ng-container *ngIf="config">
    <ng-container *transloco="let t">
        <b *ngIf="config.title" class="mt-3">
            {{t(config.title)}}
        </b>
        <div class="table-main-container d-flex flex-column gap-3 my-3">
            <div class="table-actions d-flex flex-column flex-lg-row justify-content-between gap-2">
                <app-search-filter [(filterConfig)]="filters" [filters]="objectFilter" (onChange)="onFilterChange()"
                    [(columns)]="columns">
                </app-search-filter>

                <ng-container *ngIf="showOptions">
                    <div class="d-flex flex-column flex-lg-row  justify-content-start gap-2 align-items-center"
                        *ngIf="isAnySelected">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="select-all" [checked]="isAllSelected"
                                (change)="onSelectAll($event)">
                            <label class="form-check-label" for="select-all">
                                {{t('select_all')}}
                            </label>
                        </div>
                        <button class="btn btn-sm btn-gray" (click)="onDeselectAll()">
                            {{t('deselect')}}
                        </button>
                        <button class="btn btn-sm btn-danger" (click)="appConfirmAll.openModal('')">
                            {{t('txt_delete')}}
                            <small>({{totalSelected}})</small>
                        </button>
                    </div>
                </ng-container>
            </div>

            <div class="table-container">
                <table class="table sticky-header stycky-actions">
                    <tr>
                        <ng-container *ngFor="let title of config.header ">
                            <th class="{{title.columnName}}-th" [hidden]="checkVisible(title.columnName)">
                                <div [ngClass]="{'byOrder': checkSort(title.columnName,undefined)}">
                                    <div (click)="sort(title.columnName)">{{t(title.label)}}</div>
                                    <app-material-icon
                                        *ngIf="checkSort(title.columnName,true)">arrow_downward</app-material-icon>
                                    <app-material-icon
                                        *ngIf="checkSort(title.columnName,false)">arrow_upward</app-material-icon>
                                </div>
                            </th>

                        </ng-container>

                        <th *ngIf="showActions" class="actions-th" [hidden]="checkVisible('actions')">{{t('txt_actions')}}</th>
                    </tr>
                    <ng-container *ngIf="simpleList">
                        <tr *ngIf="!(simpleList.length>0)" class="p-1">
                            <td [colSpan]="config.header.length+1">
                                <app-empty-panel customPadding="1rem"></app-empty-panel>
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container *ngFor="let row of simpleList ;let i = index;">
                        <tr [class.active-row]="isAnySelected" [class.selected-row]="row.selected">
                            <ng-container *ngFor="let item of config.header;let j = index;">
                                <td *ngIf="item.type === viewListEnum.PRINCIPAL"
                                    [hidden]="checkVisible(item.columnName)">
                                    <div class="d-flex align-items-center gap-2 text-primary-300 fw-semibold">
                                        <input class="form-check-input" type="checkbox" *ngIf="config.selectable"
                                            [(ngModel)]="row.selected" (ngModelChange)="onSelect(row)">
                                        <div class="d-flex gap-2">
                                            <app-material-icon>{{icon}}</app-material-icon>
                                            <ng-container *ngIf="item.bindValueType===viewListValueEnum.TEXT">
                                                <ng-container *ngIf="values[i][item.columnName]">
                                                    {{ values[i][item.columnName] }}
                                                </ng-container>
                                                <ng-container *ngIf="!values[i][item.columnName]">
                                                    {{ t('txt_no_information') }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="item.bindValueType===viewListValueEnum.TP">
                                                {{ t(values[i][item.columnName]['description']) }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="item.bindValueType===viewListValueEnum.OBJECT && item.bindLabel">
                                                <ng-container
                                                    *ngIf="values[i][item.columnName] && values[i][item.columnName][item.bindLabel]; else noObjectP">
                                                    {{ values[i][item.columnName][item.bindLabel] }}
                                                </ng-container>
                                                <ng-template
                                                    #noObjectP>
                                                    {{ t('txt_no_information') }}
                                                </ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="item.bindValueType===viewListValueEnum.OBJECT_SECOND_LEVEL && item.bindLabel && item.bindLabelSecond">
                                                <ng-container
                                                    *ngIf="values[i][item.columnName][item.bindLabel][item.bindLabelSecond]; else noObjectSecondLevelP">
                                                    {{ values[i][item.columnName][item.bindLabel][item.bindLabelSecond]
                                                    }}
                                                </ng-container>
                                                <ng-template
                                                    #noObjectSecondLevelP>
                                                    {{ t('txt_no_information') }}
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngIf="item.bindValueType===viewListValueEnum.BADGE">
                                                <span class="badge rounded-pill bg-primary">
                                                    {{ values[i][item.columnName] }}
                                                </span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="item.bindValueType===viewListValueEnum.TP_STATUS_BADGE">
                                                <app-status-badge [typology]="values[i][item.columnName]">
                                                </app-status-badge>
                                            </ng-container>
                                            <ng-container *ngIf="item.bindValueType===viewListValueEnum.URL">
                                                <a href="{{values[i][item.columnName]}}">
                                                    {{t('link')}}
                                                </a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="item.type === viewListEnum.NORMAL" [hidden]="checkVisible(item.columnName)">
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.TEXT">
                                        <ng-container *ngIf="values[i][item.columnName]">
                                            {{ values[i][item.columnName] }}
                                        </ng-container>
                                        <ng-container *ngIf="!values[i][item.columnName]">
                                            {{ t('txt_no_information') }}
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.TP">
                                        {{ t(values[i][item.columnName]['description']) }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="item.bindValueType===viewListValueEnum.OBJECT && item.bindLabel">
                                        <ng-container
                                            *ngIf="values[i][item.columnName] && values[i][item.columnName][item.bindLabel]; else noObjectS">
                                            {{ values[i][item.columnName][item.bindLabel] }}
                                        </ng-container>
                                        <ng-template #noObjectS>
                                            {{ t('txt_no_information') }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="item.bindValueType===viewListValueEnum.OBJECT_SECOND_LEVEL && item.bindLabel && item.bindLabelSecond">
                                        <ng-container
                                            *ngIf="values[i][item.columnName] && values[i][item.columnName][item.bindLabel] && values[i][item.columnName][item.bindLabel][item.bindLabelSecond]; else noObjectSecondLevel">
                                            {{ values[i][item.columnName][item.bindLabel][item.bindLabelSecond] }}
                                        </ng-container>
                                        <ng-template #noObjectSecondLevel>
                                            {{ t('txt_no_information') }}
                                        </ng-template >
                                    </ng-container>
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.BADGE">
                                        <span class="badge rounded-pill bg-primary">
                                            {{ values[i][item.columnName] }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.TP_STATUS_BADGE">
                                        <app-status-badge [typology]="values[i][item.columnName]">
                                        </app-status-badge>
                                    </ng-container>
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.TP_BADGE">
                                        <ng-container *ngIf="values[i][item.columnName]; else noTPBADGE">
                                            <app-status-badge [typology]="values[i][item.columnName]">
                                            </app-status-badge>
                                        </ng-container>
                                        <ng-template #noTPBADGE>
                                            {{ t('txt_no_information') }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="item.bindValueType===viewListValueEnum.TP_BADGE_SECOND_LEVEL && item.bindLabel">
                                        <ng-container
                                            *ngIf="values[i] && values[i][item.columnName] && values[i][item.columnName][item.bindLabel]; else noBadgeSecondLevel">
                                            <app-status-badge [typology]="values[i][item.columnName][item.bindLabel]">
                                            </app-status-badge>
                                        </ng-container>
                                        <ng-template #noBadgeSecondLevel>
                                            {{ t('txt_no_information') }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="item.bindValueType===viewListValueEnum.TP_BADGE_THIRD_LEVEL && item.bindLabel && item.bindLabelSecond">
                                        <ng-container
                                            *ngIf="(values[i] && values[i][item.columnName] && values[i][item.columnName][item.bindLabel] && values[i][item.columnName][item.bindLabel][item.bindLabelSecond]) ; else noBadgeThirdLevel">
                                            <app-status-badge
                                                [typology]="values[i][item.columnName][item.bindLabel][item.bindLabelSecond]">
                                            </app-status-badge>
                                        </ng-container>
                                        <ng-template #noBadgeThirdLevel>
                                            {{ t('txt_no_information') }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngIf="item.bindValueType===viewListValueEnum.URL">
                                        <a href="{{values[i][item.columnName]}}">
                                            {{t('link')}}
                                        </a>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <td class=" d-flex justify-content-end" *ngIf="showActions"
                                [hidden]="checkVisible('actions')">
                                <div ngbDropdown container="body" class="text-color-gray-800" placement="bottom-right">
                                    <button type="button" class="btn btn-action" id="dropdownActions" ngbDropdownToggle>
                                        <div class="d-flex align-items-center  ">
                                            <app-material-icon>more_vert</app-material-icon>
                                        </div>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownActions" class="dropdownMenuActions"
                                        *transloco="let t">
                                        <button ngbDropdownItem class="text-color-gray-600" (click)="onEditAction(values[i]['hashId'])">{{
                                            t('edit') }}</button>
                                        <button ngbDropdownItem class="text-color-gray-600"
                                            (click)="appConfirm.openModal(values[i]['hashId'])">{{ t('delete')
                                            }}</button>
                                        <ng-content>
                                        </ng-content>
                                    </div>
                                </div>

                            </td>
                        </tr>

                    </ng-container>
                </table>

            </div>
            <app-pagination [(paginationConfig)]="this.filters" [currentCollectionSize]="this.simpleList.length"
                (onChange)="onFilterChange()">
            </app-pagination>
        </div>
    </ng-container>
</ng-container>

<app-confirm #appConfirm [modalCentered]="true" bodyTxt="" (actionConfirm)="delete($event)"></app-confirm>
<app-confirm #appConfirmAll (actionConfirm)="onDeleteSelected()" [modalCentered]="true" bodyTxt=""></app-confirm>