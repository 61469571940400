<app-navbar>
</app-navbar>
<div class="container py-5" *transloco="let t">
  <div class="row">
    <h1 class="text-center h2 text-primary fw-bold">{{t('txt_hello')}} {{name}}</h1>
    <p class="text-center">{{t('txt_select_option_to_continue')}}</p>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div *ngIf="menuItems.length==0" class="my-5">

            <h1 class="text-gray-400 text-center">{{t('txt_menu_options_not_available')}}</h1>
          </div>
          <div *ngFor="let menuItem of menuItems" class="col-6 col-lg-3 my-5">
            <a routerLink="{{menuItem.sref}}" class="menu-item d-flex flex-column align-items-center gap-2 text-decoration-none">
              <div class="bg-white p-4 rounded d-flex justify-content-center align-items-center text-primary-200">
                <app-material-icon [size]="36">{{menuItem.icon}}</app-material-icon>
              </div>
              <p class="h6 fw-semibold text-gray-500 text-center">{{t(menuItem.name)}}</p>
            </a>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
