<ng-template #content let-modal *transloco="let t">
    <div class="modal-header">
        <h5 class="modal-title">{{t(modalTitle)}}</h5>
        <button (click)="cancel()" class="close btn  btn-gray">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button (click)="cancel()" *ngIf="showCancelButton" class="btn btn-outline-primary">{{t(cancelBtnTxt)}}</button>
        <button (click)="extraAction()" *ngIf="showExtraButton" class="btn btn-danger">{{t(extraBtnTxt)}}</button>
        <button (click)="accept()" *ngIf="showAcceptButton" class="btn btn-primary">{{t(acceptBtnText)}}</button>
    </div>
</ng-template>
