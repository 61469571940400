<div *transloco="let t">
    <b>
        {{title}}
    </b>
    <button class="btn btn-sm btn-gray" *ngIf="showAddButton" (click)="newNodeAction()">
        <app-material-icon>add</app-material-icon>
        {{btnAddTxt}}
    </button>
    <ng-template #tmplNode let-node="node">
        <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id">
            <div [ngClass]="!allowDragAndDrop ? 'node-title' : 'node-title-grab'">
                <div class="title-text" (click)="node.isExpanded=!node.isExpanded; node.entity.isExpanded=!node.isExpanded">
                    <ng-container *ngIf="node.entity[childrenPropertyName].length > 0 ">
                        <app-material-icon *ngIf="!node.isExpanded"
                            class="btn text-primary btn-sm d-inline-flex type-toggle">
                            expand_more
                        </app-material-icon>
                        <app-material-icon *ngIf="node.isExpanded "
                            class="btn text-primary btn-sm d-inline-flex type-toggle">
                            expand_less
                        </app-material-icon>
                    </ng-container>
                    {{ translateTextPropertyName ? t(node.text) : node.text }}
                </div>
                <div class="actions">
                    <app-material-icon *ngIf="showAddSonButton"
                        class="btn text-primary btn-sm d-inline-flex type-toggle" (click)="sonNodeAction(node)">
                        add_circle
                    </app-material-icon>
                    <app-material-icon *ngIf="showEditSonButton"
                        class="btn text-primary btn-sm d-inline-flex type-toggle" (click)="editSonAction(node)">
                        edit
                    </app-material-icon>
                    <app-material-icon *ngIf="showRemoveSonButton"
                        class="btn text-primary btn-sm d-inline-flex type-toggle" (click)="removeAction(node)">
                        delete
                    </app-material-icon>
                </div>
            </div>

            <div *ngIf="node.isExpanded && node.entity[childrenPropertyName].length" class="node-children" cdkDropList
                [cdkDropListData]="node.entity[childrenPropertyName]" [id]="node.id" [cdkDropListConnectedTo]="dropTargetIds"
                (cdkDropListDropped)="drop($event)" [cdkDropListSortingDisabled]="true">
                <div *ngFor="let child of node.entity[childrenPropertyName]" cdkDrag [cdkDragDisabled]="!allowDragAndDrop" [cdkDragData]="child.id"
                    (cdkDragMoved)="dragMoved($event)">
                    <ng-container *ngTemplateOutlet="tmplNode,context:{node:child}"></ng-container>
                </div>

            </div>

        </div>
    </ng-template>

    <div cdkDropList [cdkDropListData]="nodes" [id]="'main'" [cdkDropListConnectedTo]="dropTargetIds"
        (cdkDropListDropped)="drop($event)" [cdkDropListSortingDisabled]="true">

        <div *ngFor="let node of nodes" cdkDrag [cdkDragDisabled]="!allowDragAndDrop" [cdkDragData]="node.id" (cdkDragMoved)="dragMoved($event)">
            <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>
        </div>
    </div>

</div>
