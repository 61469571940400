import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdmUser } from 'src/app/data/models/adm';
import { AdmUsersService } from 'src/app/services/core/adm-users.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from "src/global/toaster-enum";

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  private preventMultiCalls$ = new Subject();
  private dataSource = new BehaviorSubject<AdmUser | undefined>(undefined);
  currentData = this.dataSource.asObservable();

  constructor(
    private router: Router,
    private admUserService: AdmUsersService,
    private toaster: ToasterService
  ) {
    this.logoutMultiCall();
    let jsonData = localStorage.getItem('profile');
    if (jsonData) {
      let data = JSON.parse(jsonData);
      this.dataSource.next(data);
    } else {
      this.dataSource.next(undefined);
    }
  }

  updateCurrentUser(userData: AdmUser | undefined) {
    if (!userData) {
      this.admUserService.getMe().subscribe({
        next: (user) => {
          this.dataSource.next(user);
          localStorage.setItem('profile', JSON.stringify(user));
        }, error: () => {
          this.logoutWithError();
        }
      });
    } else {
      this.dataSource.next(userData);
      localStorage.setItem('profile', JSON.stringify(userData));
    }
  }

  isAuthenticated(): boolean {
    const authToken = localStorage.getItem('nibelungo');
    if (!authToken !== undefined && authToken !== null) {
      const helper = new JwtHelperService();
      if (helper.isTokenExpired(authToken)) {
        localStorage.clear();
        return false;
      }
      return true;
    }
    return false;
  }

  logoutWithMessage(message: string, header: string) {
    this.toaster.show({ message: message, header: header, type: ToasterEnum.ERROR });
    this.logout();
  }

  logoutWithError() {
    this.toaster.show({ message: "Error en el servidor, intente mas tarde",  type: ToasterEnum.ERROR });
    this.logout();
  }

  logout() {
    this.preventMultiCalls$.next(new Date());
  }

  logoutMultiCall() {
    this.preventMultiCalls$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe({
      next: () => {
        this.clearToken();
        this.dataSource.next(new AdmUser());
        this.router.navigate(['/']);
      }
    });
  }

  clearToken() {
    localStorage.clear();
  }

  getInitials(user: AdmUser | undefined) {
    if (!user) {
      return 'Me';
    }

    const names = (user.admPerson.fullName ?? "").split(" ");
    if (names.length == 1) {
      return `${names[0].substring(0, 2)}`.toUpperCase();
    } else if (names.length) {
      return `${names[0].charAt(0)}${names[1].charAt(0)}`.toUpperCase();
    } else {
      return 'Me';
    }
  }

  getMyCli() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      return dataToken.cli;
    } catch (error) {
      return undefined;
    }
  }

  getUser() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      return dataToken.usr;
    } catch (error) {
      return undefined;
    }
  }

  getProvider() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      return dataToken.provider;
    } catch (error) {
      return undefined;
    }
  }

  getClient() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      return dataToken.client;
    } catch (error) {
      return undefined;
    }
  }

  getCustomer() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      return dataToken.customer;
    } catch (error) {
      return undefined;
    }
  }


  isOrganization() {
    let authToken = localStorage.getItem('nibelungo') + '';
    try {
      let dataToken: any = jwt_decode(authToken);
      if(dataToken.isOrganization === undefined)
        return undefined;
      return dataToken.isOrganization === 'true';
    } catch (error) {
      return undefined;
    }
  }

}
