<div class="main-container" *transloco="let t">
  <app-navbar [view]="permissionsEnum.NONE"></app-navbar>

  <div class="body-container">
    <!-- TODO: check this -->
    <app-header title="Vista de lista demo" [type]="headerEnum.LIST"></app-header>
    <app-body>
      <div class="table-main-container d-flex flex-column gap-3">
        <div class="form-check map-checkbox d-flex justify-content-start align-items-center gap-2">
          <input class="form-check-input my-0" type="checkbox" [(ngModel)]="showMap">
          <label class="form-check-label fs-6 fw-semibold my-0">
            {{t('txt_show_in_map')}}
          </label>
        </div>
        <div class="table-actions d-flex flex-column flex-lg-row justify-content-between gap-2">
          <div class="d-flex flex-column flex-lg-row  justify-content-start gap-2 ">
            <button class="btn btn-sm btn-gray">
              <app-material-icon>add</app-material-icon>
              Agregar filtro
            </button>
            <div class="vr d-none d-lg-block"></div>
            <button class="btn btn-sm btn-gray">
              <app-material-icon>swap_vert</app-material-icon>
              Ordenar por
            </button>
            <div class="vr d-none d-lg-block"></div>
            <button class="btn btn-sm btn-gray">
              <app-material-icon>view_column</app-material-icon>
              Columnas
            </button>
          </div>
          <!-- TODO: check this -->
          <app-export></app-export>
          <div *ngIf="isRowSelected" class="d-flex flex-column flex-lg-row  justify-content-start gap-2 align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="select-all" [checked]="isAllSelected" (change)="onSelectAll($event)">
              <label class="form-check-label" for="select-all">
                {{t('select_all')}} {{ admRoleList.length }}
              </label>
            </div>
            <button class="btn btn-sm btn-gray" (click)="onDeselectedAll()">
              {{t('deselect')}}
            </button>
            <button class="btn btn-sm btn-danger" (click)="onDelete()">
              eliminiar {{ totalSelected }}
            </button>
          </div>
        </div>
          <div class="table-container">
            <table class="table sticky-header sticky-actions" *ngIf="!showMap; else appMap">
            <tr>
              <th class="">
                <div [ngClass]="{'byOrder': checkSort('name',undefined)}">
                  <div (click)="sort('name')">Nombre</div>
                  <app-material-icon *ngIf="checkSort('name',true)">arrow_downward</app-material-icon>
                  <app-material-icon *ngIf="checkSort('name',false)">arrow_upward</app-material-icon>
                </div>
              </th>
              <th *ngFor="let i of [1,1,1,1,1,1,1,1]" class="">
                <div [ngClass]="{'byOrder': checkSort('description',undefined)}">
                  <div (click)="sort('description')">Descripción</div>
                  <app-material-icon *ngIf="checkSort('description',true)">arrow_downward</app-material-icon>
                  <app-material-icon *ngIf="checkSort('description',false)">arrow_upward</app-material-icon>
                </div>
              </th>
              <th class="">
                <div [ngClass]="{'byOrder': checkSort('tpStatus',undefined)}">
                  <div (click)="sort('tpStatus')">Estado</div>
                  <app-material-icon *ngIf="checkSort('tpStatus',true)">arrow_downward</app-material-icon>
                  <app-material-icon *ngIf="checkSort('tpStatus',false)">arrow_upward</app-material-icon>
                </div>
              </th>
              <th class="text-end">Acciones</th>
            </tr>
              <ng-container *ngFor="let a of admRoleList">

            <tr [class.active-row]="isRowSelected" [class.selected-row]="a.selected">
              <td>
                <div  class="d-flex align-items-center gap-2 text-primary-300 fw-semibold">
                  <input [(ngModel)]="a.selected" class="form-check-input" type="checkbox" >
                  <app-material-icon>shield</app-material-icon>
                  {{ a.admRole.name }}
                </div>
              </td>
              <td *ngFor="let i of [1,1,1,1,1,1,1,1]" class="text-truncate" >Rol con control total</td>
              <td>
                <span class="badge rounded-pill text-bg-success">Activo</span>
              </td>
              <td>
                <div ngbDropdown container="body" class=" p-0" placement="bottom-right">
                  <button type="button" class="btn btn-action " id="dropdownActions" ngbDropdownToggle>
                    <div class="text-end fw-bold ">
                      <app-material-icon>more_vert</app-material-icon>
                    </div>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownActions" class="dropdownMenuActions">
                    <button ngbDropdownItem class="text-gray-600">Ver</button>
                    <button ngbDropdownItem class="text-gray-600">Editar</button>
                    <button ngbDropdownItem class="text-danger-500">{{t('txt_delete')}}</button>
                  </div>
                </div>
              </td>
            </tr>
              </ng-container>
          </table>

        </div>

        <!--TODO: implement pagination -->
      </div>
    </app-body>

  </div>
</div>

<ng-template #appMap>
 <app-map
    [showMap]="showMap"
    [mapInfo]="demoMapInfo"
    (onMapClick)="onMapClick($event)"
  >
  </app-map>
</ng-template>
