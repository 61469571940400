import { Component, OnInit } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { AdmOrganization } from 'src/app/data/models/adm';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AdmOrganizationService } from 'src/app/services/core/adm-organization.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  context_change = 'context_change';
  organizations: AdmOrganization[] = [];
  current!: AdmOrganization;
  activeLanguage!: string;

  constructor(
    private organizationService: AdmOrganizationService,
    private authService: AuthService,
    private toasterService: ToasterService,
    private translocoService: TranslocoService,
    private toastService: ToasterService
  ) {
  }

  ngOnInit(): void {
    this.organizationService.currentOrganizationLS()
      .pipe(
        tap((organization) => this.current = organization),
        switchMap(_ => this.organizationService.listMyOrganizationsLS())
      )
      .subscribe({
        next: (organizations: AdmOrganization[]) => {
          this.organizations = organizations.filter(org => org.organizationId != this.current.organizationId);

          const hasChanged = localStorage.getItem(this.context_change);
          if (hasChanged) {
            this.toasterService.show({ message: 'context_successfully_changed', type: ToasterEnum.SUCCESS });
            localStorage.removeItem(this.context_change);
          }
        },
        error: _ => this.toasterService.show({ message: 'msg_error_server', type: ToasterEnum.ERROR })
      });
    this.updateActiveLanguage();
  }

  onChangeContext(newOwnerId: number) {
    if (this.current && this.current.organizationId == newOwnerId) {
      return;
    }

    this.organizationService.listMyOrganizations()
      .subscribe({
        next: (organizations) => {
          const allowed = organizations.some(org => org.organizationId == newOwnerId && this.current.organizationId !== newOwnerId);
          if (!allowed)
            return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' });

          this.authService.overrideCredential(
            newOwnerId,
            profile => {
              localStorage.setItem(this.context_change, this.context_change);
              window.location.reload();
            }
          );
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
      });
  }

  changeLanguage(lang: string){
    localStorage.setItem("systemLanguage", lang);
    this.updateActiveLanguage();
  }

  updateActiveLanguage(){
    if(
      localStorage.getItem("systemLanguage") !== null &&
      localStorage.getItem("systemLanguage") !== ""
    ){
      this.translocoService.setActiveLang(localStorage.getItem("systemLanguage")!);
    }
    if(this.translocoService.getActiveLang() === "en"){
      this.activeLanguage = "txt_english";
    }else if(this.translocoService.getActiveLang() === "es"){
      this.activeLanguage = "txt_spanish";
    }
  }
}
