import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdmPageAuthReqDto, AdmRole } from 'src/app/data/models/adm';
import { PermissionsEnum } from 'src/global/permission-utils';

const baseUrl = environment.coreUrl + '/roles';

@Injectable({
  providedIn: 'root'
})
export class AdmRolesService {

  constructor(
    private http: HttpClient
  ) { }

  private getQueryParams(queryParams: any): HttpParams {
    let params = new HttpParams();
    if (queryParams['name'] != undefined)
      params = params.append('name', queryParams['name']);
    if (queryParams['description'] != undefined)
      params = params.append('description', queryParams['description']);
    if (queryParams['status'] != undefined)
      params = params.append('status', queryParams['status']);
    if (queryParams['page'] !== undefined)
      params = params.append('page', queryParams['page']);
    if (queryParams['max'] !== undefined)
      params = params.append('max', queryParams['max']);
    if (queryParams['exclude'] !== undefined)
      params = params.append('exclude', queryParams['exclude']);
    if (queryParams['asc'] !== undefined)
      params = params.append('asc', queryParams['asc']);
    if (queryParams['columnOrder'] !== undefined)
      params = params.append('columnOrder', queryParams['columnOrder']);
    return params;
  }

  listAll(queryParams: any): Observable<HttpResponse<any>> {
    const params = this.getQueryParams(queryParams);
    return this.http.get<HttpResponse<any>>(`${baseUrl}/all`, { params: params, observe: 'response' });
  }

  getEntity(hashId: string): Observable<AdmRole> {
    return this.http.get<AdmRole>(`${baseUrl}/${hashId}`)
  }

  save(role: AdmRole): Observable<AdmRole | unknown> {
    if (role.roleId) {
      return this.http.put<unknown>(`${baseUrl}/${role.hashId}`, role);
    }
    return this.http.post<AdmRole>(baseUrl, role);
  }

  softDelete(hashId: string): Observable<unknown> {
    return this.http.delete<unknown>(`${baseUrl}/${hashId}`);
  }

  findMyRoles(): Observable<AdmRole[]> {
    return this.http.get<AdmRole[]>(`${baseUrl}/my`);
  }

  deleteRolesByHashId(roles: string[]) {
    return this.http.put<unknown>(`${baseUrl}/delete-roles`, roles);
  }

  export(format: number, queryParams: any, columns: string) {
    const params = this.getQueryParams(queryParams)
      .set('columns', columns);
    return this.http.get(`${baseUrl}/export/${format}`, { params: params, responseType: 'blob' as 'json' });
  }

  authorize(authReqDto: AdmPageAuthReqDto) {
    return this.http.put(`${baseUrl}/authorization`, authReqDto);
  }
}
