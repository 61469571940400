import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';

@Injectable()
export class DbLoggableInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method == 'GET') {
      return next.handle(request);
    }

    const headers = request.headers.set('Origin1', btoa(this.router.url));
    const loggableRequest = request.clone({ headers: headers });
    return next.handle(loggableRequest);
  }
}
