
<div class="top-bar d-flex justify-content-between align-items-center py-1 px-3  px-lg-5" *transloco="let t">
  <div ngbDropdown class="d-inline-block">
    <div *ngIf="current && organizations.length">
      <button type="button" class="btn btn-sm text-white"  ngbDropdownToggle>
        <app-material-icon>apartment</app-material-icon>
        <p class="m-0">{{ current.name }}</p>
        <app-material-icon class="d-none d-md-block">expand_more</app-material-icon>
      </button>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let organization of organizations;">
          <button (click)="onChangeContext(organization.organizationId)" ngbDropdownItem>{{ organization.name }}</button>
        </ng-container>
      </div>
    </div>
    <div *ngIf="current && !organizations.length">
      <div class="btn btn-sm text-white">
        <app-material-icon>apartment</app-material-icon>
        <p class="m-0">{{ current.name }}</p>
      </div>
    </div>

  </div>
  <div class="d-flex justify-content-between justify-content-md-end  gap-3 align-items-center">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="px-0 btn btn-sm text-white"  ngbDropdownToggle>
        <app-material-icon>language</app-material-icon>
        <p class="m-0 d-none d-md-block">{{t(activeLanguage)}}</p>
        <app-material-icon class="d-none d-md-block">expand_more</app-material-icon>
      </button>
      <div ngbDropdownMenu >
        <button ngbDropdownItem (click)="changeLanguage('en')">{{t('txt_english')}}</button>
        <button ngbDropdownItem (click)="changeLanguage('es')">{{t('txt_spanish')}}</button>
      </div>
    </div>
  </div>
</div>
