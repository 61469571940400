import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDto } from 'src/app/data/models/adm';
import { environment } from 'src/environments/environment';

const baseUrl = environment.coreUrl + '/utils';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpClient
  ) { }

  persistImage(base64: string): Observable<FileDto> {
    const req = new FileDto(base64, '');
    return this.http.post<FileDto>(`${baseUrl}/persist-image`, req);
  }

  persistDocument(base64: string): Observable<FileDto> {
    const req = new FileDto(base64, '');
    return this.http.post<FileDto>(`${baseUrl}/persist-document`, req);
  }

  ping(): Observable<any> {
    return this.http.get<any>(`${baseUrl}/ping`);
  }

}
