<button
  class="btn btn-outline-primary"
  *transloco="let t"
  (click)="open()"
  [appCheckPermission]="permission"
  [permissionType]="permissionType"
>
  <span>{{ t("export") }}</span>
  <app-material-icon>download</app-material-icon>
</button>

<ng-template #content *transloco="let t">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title fs-2 fw-semibold">{{ t('export') }}</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="offcanvas-body">
    <form class="h-100 mx-2 d-flex flex-column justify-content-between">
      <div id="config-area">
        <div class="mb-3" *ngIf="!saveConf">
          <label class="form-label">{{ t('config') }}</label>
          <ng-select
            [(ngModel)]="currentConf"
            name="configurations"
            [multiple]="false"
            [placeholder]="t('select_a_config')"
            [clearable]="true"
            [notFoundText]="t('no_export_configuration_found')"
            (change)="onExportConfigurationChange(currentConf)"
          >
            <ng-option *ngFor="let conf of configurations" [value]="conf">{{ conf.configurationName }}</ng-option>
          </ng-select>
        </div>

        <div class="mb-3">
          <label class="form-label">
            <span class="text-danger fw-semibold">* </span>
            {{ t('format') }}
          </label>
          <ng-select
            [(ngModel)]="currentFormat"
            name="format"
            [multiple]="false"
            [placeholder]="t('select_a_format')"
            [clearable]="true"
          >
            <ng-option
              *ngFor="let typology of tpExportFormats"
              [value]="typology"
            >
              {{ t(typology.description) }}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div id="display-area" class="d-flex justify-content-center gap-1 w-100">
        <!-- filters -->
        <div id="filters" class="w-50">
          <div>
            <h1 class="h6 text-primary text-center">{{ t('filters') }}</h1>
          </div>
          <ng-container *ngFor="let filter of currentFilters">
            <div class="btn btn-sm btn-gray my-2 d-flex justify-content-start">
              <app-material-icon [size]="16">
                manage_search
              </app-material-icon>
                <span role="button">
                  <span class="fw-bold">{{ t(filter.name) }}</span>
                  <ng-container *ngIf="filter.type == FilterEnum.STRING && filter.value != undefined && filter.value != ''">
                    <span>: {{ filter.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="filter.type == FilterEnum.NUMBER && filter.value != undefined">
                    <span>: {{ filter.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="filter.type == FilterEnum.DATE && filter.value">
                    <span>: {{ filterService.validateDateInput(filter) }}</span>
                  </ng-container>
                  <ng-container *ngIf="filter.type == FilterEnum.LIST && filter.selectedValue">
                    <span>: {{ t(filter.selectedValue.description) }}</span>
                  </ng-container>
                  <ng-container *ngIf="filter.type == FilterEnum.MULTIPLE_LIST && filter.selectedValues">
                    <span>: {{ filterService.validateMultipleInput(filter.selectedValues) }}</span>
                  </ng-container>
                </span>
            </div>
          </ng-container>
        </div>

        <!-- columns -->
        <div id="columns" class="w-50">
          <div>
            <h1 class="h6 text-primary text-center">{{ t('columns') }}</h1>
          </div>
          <ng-container *ngFor="let col of currentColumns">
            <div class="btn btn-sm btn-gray my-1 d-flex justify-content-start">
              <app-material-icon [size]="16">
                view_column
              </app-material-icon>
              <span role="button">
                {{ t(col.name) }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <div id="extras-area">
        <div class="my-5" id="save-area" *ngIf="!currentConf">
          <div class="form-check mb-3">
            <input [(ngModel)]="saveConf" name="save-conf" type="checkbox" class="form-check-input">
            <label class="form-check-label fw-semibold mx-2">{{ t('save_config') }}</label>
          </div>

          <div *ngIf="saveConf" class="mb-3">
            <label class="form-label">
              <span class="text-danger fw-semibold">* </span>
              {{ t('name_of_the_config') }}
            </label>
            <input [(ngModel)]="confName" name="conf-name" type="text" class="form-control p-2 my-1" placeholder="Ej. Reporte de ventas"/>
          </div>
        </div>

        <div id="buttons-area" class="d-grid gap-2">
          <button
            type="button"
            class="btn btn-primary py-2"
            [appCheckPermission]="permission"
            [permissionType]="permissionType"
            (click)="generateReport()"
          >
            {{ t('generate_report') }}
          </button>
          <button
            *ngIf="currentConf"
            type="button"
            class="btn btn-danger py-2"
            (click)="appConfirmDelete.openModal(currentConf.exportConfigurationId)"
          >
            {{ t('delete') }}
          </button>
          <button ngbAutofocus type="button" class="btn btn-outline-primary py-2" (click)="close()">
            {{ t('cancel') }}
          </button>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<app-confirm #appConfirmDelete (actionConfirm)="deleteExportConfiguration($event)" [modalCentered]="true" bodyTxt=""></app-confirm>
