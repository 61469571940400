import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class SecureInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get('Authorization')) {
    }
    const token = this.auth.getToken();
    if (token == null || token === undefined || token === 'null' || token.length === 0) {
      return next.handle(request);
    }
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    const secureRequest = request.clone({ headers });
    return next.handle(secureRequest);
  }

}
