export enum ErrorMessageEnum {
  GETTING_INFORMATION = 'error_getting_information',
  PROCESSING_INFORMATION = 'error_processing_information',
  INTERNAL_SERVER_ERROR = 'msg_error_server',
  COMPLETE_ALL_STEPS = 'msg_error_complete_all_steps',
  COMPLETE_ALL_FIELDS= 'msg_error_complete_all_fields',
  NAME_ALREADY_EXISTS = 'name_already_exists',
  CODE_ALREADY_EXISTS = 'code_already_exists',
  NUMBER_ALREADY_EXISTS = 'number_already_exists',
  MAKE_SURE_THE_DATA_IS_VALID = 'make_sure_the_data_is_valid',
  PARAMETER_TYPE_IS_ALREADY_IN_USE = 'parameter_type_is_already_in_use'
}

export enum SuccessMessageEnum {
  ELEMENT_CREATED = 'msg_success_element_created',
  CHANGES_SAVED = 'msg_success_changes_saved',
  ELEMENT_DELETED = 'msg_success_element_deleted',
  ELEMENTS_DELETED = 'msg_success_elements_deleted',
}
