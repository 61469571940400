import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbPopover, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Options } from "@popperjs/core";
import { ColumnConfig, FilterSearch, ObjectFilter } from "src/app/app-commons/components/search-filter/filter-util";
import { FilterService } from "src/app/services/others/filter/filter.service";
import { FilterEnum } from "src/global/filter-enum";

/**
 * Component to handle filters. Example usage:
 *
 * @Example
 * ```html
 * <app-search-filter
 *  [(filterConfig)]="myFilters"
 *  [filters]="myFilterObject"
 *  (onChange)="onFilterChange()"
 *  [(columns)]="myColumnsConfig"
 * >
 * </app-search-filter>
 * ```
 */
@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  FilterEnum = FilterEnum; // to use in html template
  searchColumns: string = '';

  time: NgbTimeStruct = { hour: 13, minute: 30, second: 30 };

  popperOptions = (options: Partial<Options>) => {
    const opt = options.modifiers || [];
    const offsetOpt = opt.find(o => o.name === 'offset' && o.options);
    if (offsetOpt && offsetOpt.options) {
      offsetOpt.options.offset = () => [90, 20];
    }

    options.onFirstUpdate = (state) => {
      if (state.elements?.arrow) {
        state.elements.arrow.style.display = 'none';
      }
    };

    return options;
  }

  /**
   * event that notifies of any change in any filter
   */
  @Output() onChange = new EventEmitter();

  /**
   * input that receives the configuration of the filters to show
   * @param {ObjectFilter} filter
   */
  @Input() set filters(filter: ObjectFilter) {
    this.objectFilter = filter;
    // this.objectFilter = { id: filter.id, filters: [], currentFilters: [] };
    // Object.assign(this.objectFilter, filter);
  }

  /**
   * output that is emitted when any property of the filters have changed
   */
  @Output() filterConfigChange = new EventEmitter<FilterSearch>();

  /**
   * input that receives the current filters configuration
   * @param {FilterSearch} filterConfig
   */
  @Input() set filterConfig(filterConfig: FilterSearch) {
    this._filterConfig = filterConfig;
  }

  /**
   * output that is emitter when any property of the columns configuration has changed
   */
  @Output() columnsChange = new EventEmitter<ColumnConfig>();


  @Input() displayFiltersDropDown?: boolean = true;

  @Input() displayInputFilters?: boolean = false;


  /**
   * input that receives the current configuration of the columns
   * @param {ColumnConfig} columns
   */
  @Input() set columns(columns: ColumnConfig) {
    if (!columns) {
      return;
    }

    this._columns = [];
    this._originalColumns = { id: columns.id, config: {} }
    for (let key in columns.config) {
      const column = { id: key, ...columns.config[key] };
      column.selected = column.default || column.selected;
      column.display = column.display === undefined ? true : column.display;

      this._columns.push(column);
      this._originalColumns.config[key] = {
        name: column.name,
        default: column.default,
        selected: column.selected,
        display: column.display
      };
    }
  }

  _columns: any[] = [];
  _originalColumns!: ColumnConfig;

  objectFilter!: ObjectFilter;
  _filterConfig!: FilterSearch;
  filterValues: { [id: string]: any } = {};

  constructor(
    public formatter: NgbDateParserFormatter,
    public filterService: FilterService
  ) {
  }

  onClickColumns(index: number) {
    if (this._columns[index].default) {
      return;
    }

    this._columns[index].selected = !this._columns[index].selected;
    this._columns.forEach(column => {
      this._originalColumns.config[column.id] = {
        name: column.name,
        default: column.default,
        selected: column.selected,
        display: column.display,
        keyAttribute: column.keyAttribute,
        widthPercentage: column.widthPercentage,
        withOrder: column.withOrder,
        withTranslation: column.withTranslation
      };
    });

    localStorage.setItem(this._originalColumns.id, JSON.stringify(this._originalColumns));
    this.columnsChange.emit(this._originalColumns);
  }

  onSearchColumns(event: KeyboardEvent) {
    const currentValue = this.searchColumns.toUpperCase();
    for (const column of this._columns) {
      column.display = column.name.toUpperCase().indexOf(currentValue) > -1;
    }
  }

  onNgModelChange(popover: NgbPopover, execute: boolean | undefined) {
    if (execute) {
      popover.close();
    }
  }

  onSelect(index: number) {
    const filter = this.objectFilter.filters[index];
    filter.selected = true;
    // add filter
    this.objectFilter.currentFilters.push(filter);

    setTimeout(() => {
      const span = document.querySelector(`span#${filter.id}`) as HTMLElement;
      span.click();
    }, 100);
  }

  onRemoveNotVisibleFilter(index: number) {
    this.onRemoveVisibleFilter(index);
    this.objectFilter.currentFilters.splice(index, 1);

    this.onEmit();
  }

  onRemoveVisibleFilter(index: number) {
    const filter = this.objectFilter.currentFilters[index];
    filter.selected = false;
    switch (filter.type) {
      case FilterEnum.STRING:
        filter.value = undefined;
        break;
      case FilterEnum.DATE:
        filter.value = undefined;
        break;
      case FilterEnum.LIST:
        filter.selectedValue = undefined;
        break;
      case FilterEnum.MULTIPLE_LIST:
        filter.selectedValues = [];
        break;
      case FilterEnum.NUMBER:
        filter.value = undefined;
        break;
      case FilterEnum.DATE_RANGE:
        filter.startRangeValue = undefined;
        filter.endRangeValue = undefined;
        this.filterValues[filter.startRangeFilterId] = undefined
        this.filterValues[filter.endRangeFilterId] = undefined
    }

    this.filterValues[filter.id] = undefined;
  }

  onKeyUp(event: KeyboardEvent, popover: NgbPopover) {
    if (event.code !== 'Enter') {
      return;
    }

    popover.close();
  }

  onSearch() {
    let index = 0;
    for (const filter of this.objectFilter.filters) {
      if (filter.showAsStaticInput) {
        this.filterValues[filter.id] = filter.staticInputValue === undefined || filter.staticInputValue === ''
          ? undefined : filter.staticInputValue
        this.filterService.onProcessFilter(this.objectFilter, this.filterValues, index);
        index++;
      }
    }
    this.onEmit();
  }

  onHidden(index: number) {
    this.filterService.onProcessFilter(this.objectFilter, this.filterValues, index);
    this.onEmit();
  }

  private onEmit() {
    Object.assign(this._filterConfig, this.filterValues);

    // save on localstorage
    localStorage.setItem(this.objectFilter.id, JSON.stringify(this.objectFilter));
    localStorage.setItem(this._filterConfig.filter_id, JSON.stringify(this._filterConfig));

    // emit changes
    this._filterConfig = JSON.parse(JSON.stringify(this._filterConfig));
    this.filterConfigChange.emit(this._filterConfig);
    this.onChange.emit();
  }

  getMinDate(minDate: NgbDate | undefined) {
    if (!minDate) {
      return { year: 1900, month: 1, day: 1 };
    }
    return minDate;
  }

  getMaxDate(maxDate: NgbDate | undefined) {
    if (!maxDate) {
      return { year: 3000, month: 1, day: 1 };
    }
    return maxDate;
  }
}
