import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordRecoveryService } from 'src/app/services/core/password-recovery.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  changePasswordRequest = { newPassword: '', confirmNewPassword: '' };
  showPass = false;
  showPassConfirm = false;
  token = '';
  userId = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private passwordRecoveryService: PasswordRecoveryService,
    private toaster: ToasterService
  ) {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.token = this.activatedRoute.snapshot.params.token;
    this.passwordRecoveryService.verificateToken(this.token).subscribe(
      response => { }, error => {
        this.toaster.show({ message: "No se pudo validar el token",  type: ToasterEnum.ERROR })
        this.router.navigate(['']);
      }
    );

  }

  changePassword() {
    let passwordRecovery = {
      userId: this.userId,
      token: this.token,
      newPassword: this.changePasswordRequest.newPassword
    };
    this.passwordRecoveryService.changePassword(passwordRecovery).subscribe(
      response => {
        this.toaster.show({ message:"Cambio de contraseña exitoso", header: "Exito", type: ToasterEnum.SUCCESS })
        this.router.navigate(['']);
       }, error => {
        this.toaster.show({ message: "No se pudo cambiar la contraseña, intente mas tarde",  type: ToasterEnum.ERROR })
      }
    );
  }

  isValid(): boolean {
    return this.changePasswordRequest.newPassword.length >= 8 &&
      this.changePasswordRequest.newPassword.length <= 20 &&
      this.changePasswordRequest.newPassword === this.changePasswordRequest.confirmNewPassword
  }


  togglePassNew() {
    this.showPass = !this.showPass;
  }

  togglePassConfirm() {
    this.showPassConfirm = !this.showPassConfirm;
  }

}
