import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { iif, of, zip } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import {
  ColumnConfig,
  filter,
  FilterSearch,
  ObjectFilter,
  StringFilter
} from "src/app/app-commons/components/search-filter/filter-util";
import {
  AdmExportConfiguration,
  AdmExportConfigurationDetail,
  AdmExportConfigurationDto,
  AdmTypology
} from "src/app/data/models/adm";
import { ExportConfiguration } from "src/app/data/others/export-configuration";
import { AdmExportConfigurationService } from "src/app/services/core/adm-export-configuration.service";
import { AdmTypologiesService } from "src/app/services/core/adm-typologies.service";
import { FilterService } from "src/app/services/others/filter/filter.service";
import { ToasterService } from "src/app/services/others/toaster/toaster.service";
import { FilterEnum } from "src/global/filter-enum";
import { PermissionsEnum, PermissionTypeEnum } from "src/global/permission-utils";
import { ToasterEnum } from "src/global/toaster-enum";
import { TypologiesEnum } from "src/global/typologies-enum";

/**
 * Component to manage the export of tables/reports, currently used in {@link ToSelectComponent}
 *
 * @Example
 * ```html
 *  <app-export
 *    *ngIf="_showExportComponent"
 *    [permission]="permission"
 *    [objectFilter]="objectFilter"
 *    [filters]="filters"
 *    [columns]="columns"
 *    (exportEvent)="exportEvent.emit($event)"
 *  >
 *  </app-export>
 * ```
 */
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {
  @ViewChild('content') content!: ElementRef
  canvasRef!: NgbOffcanvasRef;
  typologyEnum = TypologiesEnum;
  FilterEnum = FilterEnum;

  used = false;
  saveConf = false;
  confName!: string;

  tpExportFormats: AdmTypology[] = [];
  configurations: AdmExportConfigurationDto[] = [];
  currentFormat!: AdmTypology | undefined;
  tpEFTColumn!: AdmTypology;
  tpEFTFilter!: AdmTypology;

  /**
   * Current ExportConfigurations and its details
   */
  currentConf!: AdmExportConfigurationDto | undefined;
  currentConfDetails: AdmExportConfigurationDetail[] = [];

  /**
   * Filters and columns applied for any user
   */
  _filters: { [property: string]: any } = {};
  _objectFilter: ObjectFilter = { id: '', filters: [], currentFilters: [] };
  _columns: any[] = [];

  /**
   * Filters and columns obtained from any existing user configurations
   */
  __filters: { [property: string]: any } = {};
  __objectFilter: ObjectFilter = { id: '', filters: [], currentFilters: [] };
  __columns: any[] = [];

  /**
   * Current configuration to display
   */
  currentFilters: filter[] = [];
  currentColumns: any[] = [];

  /**
   * All original columns
   */
  originalColumns!: ColumnConfig;

  /**
   * Filters applied for any user
   * @param {FilterSearch} filters
   */
  @Input() set filters(filters: FilterSearch) {
    this._filters = Object.keys(filters)
      .reduce((currentFilters: { [property: string]: any }, key: string) => {
        if (key != 'pag' && key != 'max' && filters[key] != undefined) {
          currentFilters[key] = filters[key];
        }

        return currentFilters;
      }, {});

    // set max = 0, to get all the collection (without pagination)
    this._filters['max'] = 0;
    this._objectFilter = this.searchFilterToObjectFilter(this._filters, this._objectFilter);
  }

  /**
   * Object that defines the filters of any view
   * @param {ObjectFilter} objectFilter
   */
  @Input() set objectFilter(objectFilter: ObjectFilter) {
    this._objectFilter = this.newObjectFilter(objectFilter);
  }

  /**
   * Columns
   * @param {ColumnConfig} columns
   */
  @Input() set columns(columns: ColumnConfig) {
    // original
    this.originalColumns = columns;

    this._columns = [];
    // only default and/or selected columns
    this._columns = Object.keys(columns.config)
      .reduce((currentCols: any[], col: string) => {
        if (col != 'actions' && (columns.config[col].default || columns.config[col].selected)) {
          currentCols.push({ id: col, ...columns.config[col] });
        }
        return currentCols;
      }, []);
  }

  /**
   * Output that is emitted to generate a new report
   * See {@link ExportConfiguration}
   */
  @Output() exportEvent = new EventEmitter<ExportConfiguration>();

  /**
   * Permission
   */
  @Input() permission: number = PermissionsEnum.NONE;
  permissionType = PermissionTypeEnum.EXPORT;

  /**
   * Input to allow pdf export in format selector
   */
  @Input() isPdfAvailable: boolean = false;


  constructor(
    private exportConfService: AdmExportConfigurationService,
    private canvasService: NgbOffcanvas,
    private typologyService: AdmTypologiesService,
    private toastService: ToasterService,
    public filterService: FilterService,
    private formatter: NgbDateParserFormatter,
    private i18nService: TranslocoService
  ) {
  }

  open() {
    /* restart view */
    this.currentConf = undefined;
    this.currentFormat = undefined;
    this.saveConf = false;
    this.confName = '';

    this.currentColumns = [...this._columns];
    this.currentFilters = [...this._objectFilter.currentFilters];

    /**
     * Load configurations according to the permission, and export format typologies
     */
    this.exportConfService.findAll({
      permissionInternalId: this.permission,
    })
      .pipe(
        tap({
          next: (response: AdmExportConfigurationDto[]) => this.configurations = response,
          error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: "msg_error_server", })
        }),
        switchMap(
          _ => iif(
            () => !this.used, // load only the first time
            zip(
              this.typologyService.listByParentInternalId(TypologiesEnum.EXPORT_FORMAT),
              this.typologyService.listByParentInternalId(TypologiesEnum.EXPORT_FIELD_TYPE)
            )
              .pipe(
                tap({
                  next: (response: [AdmTypology[], AdmTypology[]]) => {
                    this.used = true;
                    if (this.isPdfAvailable) {
                      this.tpExportFormats = response[0]
                    } else {
                      this.tpExportFormats = response[0]
                        // TODO: remove this when pdf export is available
                        .filter(f => f.internalId !== TypologiesEnum.EF_PDF);
                    }

                    response[1].forEach(t => {
                      if (t.internalId == TypologiesEnum.EFT_COLUMN) {
                        this.tpEFTColumn = t;
                      } else if (t.internalId == TypologiesEnum.EFT_FILTER) {
                        this.tpEFTFilter = t;
                      }
                    });
                  },
                  error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: "msg_error_server", })
                })
              ),
            of(1)
          )
        )
      )
      .subscribe({
        next: _ => {
          setTimeout(() => {
            this.canvasRef = this.canvasService.open(this.content, { position: 'end' });
          }, 250);
        }
      });
  }

  generateReport() {
    if (!this.currentConf && !this.currentFormat) {
      this.toastService.show({ message: "msg_error_complete_all_fields", type: ToasterEnum.ERROR });
      return;
    }

    if (this.currentFormat && this.saveConf) {
      /**
       * Save a new configuration and generate report
       */
      if (!this.confName || !this.confName.trim() || this.confName.trim().length > 75) {
        this.toastService.show({ message: "msg_error_complete_all_fields", type: ToasterEnum.ERROR });
        return;
      }

      const exportConf = new AdmExportConfiguration();
      exportConf.permissionInternalId = this.permission;
      exportConf.tpExportFormat = this.currentFormat;
      exportConf.configurationName = this.confName.trim();
      exportConf.details = [...this.filtersToExportConfDetails(this._filters), ...this.columnsToExportConfDetails(this._columns)];

      this.exportEvent.emit({
        format: this.currentFormat.internalId,
        filters: this._filters,
        columns: this.strColumns(this._columns),
        config: exportConf
      })

    } else if (this.currentConf) {
      /**
       * Export with existing configuration and allow to change the format
       */
      this.exportEvent.emit({
        format: this.currentFormat?.internalId ?? this.currentConf.tpExportFormat.internalId,
        filters: this.__filters,
        columns: this.strColumns(this.__columns)
      });
    } else if (this.currentFormat) {
      /**
       * Only generate report
       */
      this.exportEvent.emit({
        format: this.currentFormat.internalId,
        filters: this._filters,
        columns: this.strColumns(this._columns)
      });
    }
  }

  /**
   * Delete any existing configuration
   * @param exportConfigurationId
   */
  deleteExportConfiguration(exportConfigurationId: number) {
    this.exportConfService.deleteById(exportConfigurationId)
      .pipe(
        tap({
          next: _ => {
            this.toastService.show({ type: ToasterEnum.SUCCESS, message: 'msg_success_changes_saved' });
            setTimeout(() => {
              this.currentConf = undefined;
              this.currentFilters = [...this._objectFilter.currentFilters];
              this.currentColumns = [...this._columns];
            }, 500);
          },
          error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: "msg_error_server", })
        }),
        switchMap(
          _ => this.exportConfService.findAll({
            permissionInternalId: this.permission,
          })
        ))
      .subscribe({
        next: (response: AdmExportConfigurationDto[]) => {
          this.configurations = response;
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: "msg_error_server", })
      });
  }

  /**
   * Reload configuration details
   * @param {AdmExportConfiguration} conf
   */
  onExportConfigurationChange(conf: AdmExportConfigurationDto | undefined) {
    /* clean configuration from db */
    this.__columns = [];
    this.__objectFilter.currentFilters = [];
    this.__filters = {}

    if (!conf) {
      /* show users default configuration */
      this.currentColumns = [...this._columns];
      this.currentFilters = [...this._objectFilter.currentFilters];
      this.currentFormat = undefined;

      return;
    }

    // set format according to the current export configuration
    this.currentFormat = this.tpExportFormats.find(t => t.internalId == conf.tpExportFormat.internalId);

    // get details
    this.exportConfService.findDetailsByExportConfId(conf.exportConfigurationId)
      .subscribe({
        next: (response) => {
          this.currentConfDetails = response;

          /**
           * AdmExportConfigurationDetail to columns
           */
          this.__columns = [...this.exportConfDetailsToColumns(this.currentConfDetails)];

          /**
           * AdmExportConfigurationDetail to ObjectFilter
           */
          this.__filters = { ...this.exportConfDetailsToFilters(this.currentConfDetails), max: 0 };// important max: 0, to export all the items

          this.__objectFilter.id = this._objectFilter.id;
          this.__objectFilter.filters = Object.assign([], JSON.parse(JSON.stringify(this._objectFilter.filters)));
          this.__objectFilter.currentFilters = [];
          this.__objectFilter = this.searchFilterToObjectFilter(this.__filters, this.__objectFilter);

          /**
           * Set the database user configuration to the current configuration
           */
          this.currentColumns = [...this.__columns];
          this.currentFilters = [...this.__objectFilter.currentFilters];
        },
        error: _ => {
          this.toastService.show({ type: ToasterEnum.ERROR, message: "msg_error_server", });
          this.currentConfDetails = [];

          // user default configuration
          this.currentColumns = [...this._columns];
          this.currentFilters = [...this._objectFilter.currentFilters];
          this.currentConf = undefined;
          this.currentFormat = undefined;
        }
      });
  }

  /**
   *
   * @param {AdmExportConfigurationDetail} details
   * @private
   */
  private exportConfDetailsToFilters(details: AdmExportConfigurationDetail[]) {
    return details
      .filter(detail => detail.tpExportFieldType.internalId == TypologiesEnum.EFT_FILTER)
      .reduce((filters: { [id: string]: any }, detail: AdmExportConfigurationDetail) => {
        filters[detail.fieldName] = detail.fieldValue;
        return filters;
      }, {});
  }

  /**
   *
   * @param {AdmExportConfigurationDetail} details
   * @private
   */
  private exportConfDetailsToColumns(details: AdmExportConfigurationDetail[]): any[] {
    const columnsDetails = details
      .reduce((conf: { [id: string]: any }, detail: AdmExportConfigurationDetail) => {
        if (detail.tpExportFieldType.internalId == TypologiesEnum.EFT_COLUMN) {
          conf[detail.fieldValue] = detail;
        }
        return conf;
      }, {});

    return Object.keys(this.originalColumns.config)
      .reduce((columns: any[], key: string) => {
        const conf = columnsDetails[key];
        if (conf) {
          // set default or selected as true
          columns.push({ id: key, ...this.originalColumns.config[key], default: true, selected: true });
        }
        return columns;
      }, []);
  }

  private filtersToExportConfDetails(filters: { [property: string]: any }): AdmExportConfigurationDetail[] {
    return Object.keys(filters)
      .map(key => {
        const detail = new AdmExportConfigurationDetail();
        detail.tpExportFieldType = this.tpEFTFilter;
        detail.fieldName = key;
        detail.fieldValue = filters[key];
        return detail;
      });
  }

  private columnsToExportConfDetails(columns: any[]): AdmExportConfigurationDetail[] {
    return columns
      .filter(config =>
        config.name != 'actions'
        && (config.default || config.selected)
      )
      .map(config => {
        const detail = new AdmExportConfigurationDetail();
        detail.tpExportFieldType = this.tpEFTColumn;
        detail.fieldName = config.id;
        detail.fieldValue = config.dto ?? config.id;
        return detail;
      });
  }

  private searchFilterToObjectFilter(_filterValues: { [id: string]: any }, filters: ObjectFilter) {
    const tmpFilter: ObjectFilter = this.newObjectFilter(filters);
    const otherFilters: filter[] = [];

    for (const key in _filterValues) {
      if (_filterValues[key] == undefined || _filterValues[key] == '') {
        continue;
      }

      const filter = tmpFilter.filters.find(f => f.id == key);
      if (!filter) {
        if (key == 'asc' || key == 'columnOrder') {
          const name = this.i18nService.translate(key);
          const value = this.i18nService.translate(_filterValues[key]);
          const orderFilter: StringFilter = {
            id: key,
            name: name,
            selected: true,
            value: value,
            type: FilterEnum.STRING
          };

          otherFilters.push(orderFilter);
          continue;
        }

        // skip max and page
        continue;
      }

      switch (filter.type) {
        case FilterEnum.STRING:
        case FilterEnum.NUMBER:
          filter.value = _filterValues[key];
          filter.selected = true;
          tmpFilter.currentFilters.push(filter);
          break;
        case FilterEnum.DATE:
          const value = _filterValues[key];
          if (this.formatter.parse(value)) {
            const dateStruct = this.formatter.parse(value)
            const date = NgbDate.from(dateStruct);
            filter.value = date ?? undefined;
            filter.selected = true;
            tmpFilter.currentFilters.push(filter);
          } else {
            filter.value = undefined;
            filter.selected = false;
          }
          break;
        case FilterEnum.LIST:
          const selectedValue = filter.values.find(value => value.value == _filterValues[key]);
          if (selectedValue) {
            filter.selectedValue = selectedValue;
            filter.selected = true;
            tmpFilter.currentFilters.push(filter);
          }
          break;
        case FilterEnum.MULTIPLE_LIST:
          // search in filter values
          filter.selectedValues = [];
          const values = `${_filterValues[key]}`.split(/,/);
          values.forEach(value => {
            const originalValue = filter.values.find(filterValue => filterValue.value == value);
            if (originalValue && filter.selectedValues) {
              filter.selectedValues.push(originalValue);
            }
          });

          if (filter.selectedValues.length) {
            filter.selected = true;
            tmpFilter.currentFilters.push(filter);
          }
          break;
      }
    }

    tmpFilter.currentFilters.push(...otherFilters);
    return tmpFilter;
  }

  private strColumns(columns: any[]): string {
    if (!columns.length) {
      return '';
    }

    return columns.reduce((prev: string, current: any) => {
      if (current.name == 'actions')
        return prev;

      const str = current.dto ?? current.id
      return prev ? prev.concat(',').concat(str) : str;
    }, '');
  }

  private newObjectFilter(objectFilter: ObjectFilter) {
    const tmpFilter: ObjectFilter = { id: objectFilter.id, filters: [], currentFilters: [] };
    tmpFilter.filters = objectFilter.filters
      .reduce((filters: filter[], current: filter) => {
        const newFilter: filter = Object.assign({}, (JSON.parse(JSON.stringify(current))));
        newFilter.selected = false;
        switch (newFilter.type) {
          case FilterEnum.STRING:
          case FilterEnum.NUMBER:
          case FilterEnum.DATE:
            newFilter.value = undefined;
            break;
          case FilterEnum.LIST:
            newFilter.selectedValue = undefined;
            break;
          case FilterEnum.MULTIPLE_LIST:
            newFilter.selectedValues = undefined;
            break;
        }

        filters.push(newFilter);
        return filters;
      }, []);

    return tmpFilter;
  }

  close() {
    this.canvasRef.close('close');
  }
}
