import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { switchMap, tap } from "rxjs/operators";
import { AdmUser } from 'src/app/data/models/adm';
import { AdmRolesService } from "src/app/services/core/adm-roles.service";
import { AdmUsersService } from 'src/app/services/core/adm-users.service';
import { CurrentUserService } from 'src/app/services/others/current-user.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { HeaderEnum } from 'src/global/header-enum';
import { ToasterEnum } from "src/global/toaster-enum";

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit {

  private admUserServiceSubscription: Subscription | undefined;
  user!: AdmUser;
  roles = '';
  headerEnum = HeaderEnum;
  initials = '';

  constructor(
    private admUserService: AdmUsersService,
    private currentAdmUser: CurrentUserService,
    private roleService: AdmRolesService,
    private toaster: ToasterService
  ) {
  }

  ngOnInit(): void {
    this.getMeProfile();
  }

  ngOnDestroy(): void {
    this.admUserServiceSubscription?.unsubscribe();
  }

  getMeProfile() {
    let profile = localStorage.getItem('profile');
    if (profile != null) {
      let user = JSON.parse(profile);

      this.admUserService.getEntity(user.hashId)
        .pipe(
          tap({
            next: (user) => {
              this.user = user;
              this.initials = this.currentAdmUser.getInitials(this.user);
            }
          }),
          switchMap(
            _ => this.roleService.findMyRoles()
          )
        )
        .subscribe({
          next: (roles) => {
            this.roles = roles
              .reduce((prev, current) => prev.concat(', ').concat(current.name), '')
              .replace(/^, /, '');
          },
          error: _ => {
            this.toaster.show({
              message: "msg_error",
              
              type: ToasterEnum.ERROR
            });
          }
        })
    }
  }
}
