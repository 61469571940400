<ng-template #content *transloco="let t">
    <div class="modal-header">
        <h5 class="modal-title">{{t(pageTitle)}}
        </h5>
        <button class="close btn btn-outline-primary" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bg-body p-0">
        <div >
            <app-body class="w-100" style="height: 500px; width: 100%">
                <ng-container *ngTemplateOutlet="licenceContent">
                </ng-container>
            </app-body>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="close()"
            data-dismiss="modal">{{t('cancel')}}</button>
        <button type="button" class="btn btn-primary" (click)="confirmBtn()">
            {{t('save')}}
        </button>
    </div>
</ng-template>

<ng-template #licenceContent>
    <ng-container *transloco="let t">
        <ng-container *ngIf="apiLoaded">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <button ngbNavLink>{{t('txt_address')}}</button>
                    <ng-template ngbNavContent>
                        <div class="col-12 col-lg-4">
                            <div class="mb-3">
                                <input #mapSearchField type="text" class="form-control my-search-box"
                                    placeholder="{{t('txt_search_address')}}" id="map-search-field" />
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <button ngbNavLink>{{t('txt_coordinates')}}</button>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <div class="mb-3">
                                    <input #coordinatesField="ngModel" type="text" class="form-control my-search-box"
                                        placeholder="{{t('txt_coordinates_placeholder')}}" id="coordinates-search-field"
                                        [(ngModel)]="coordinates" maxlength="150"
                                        [ngClass]="{'is-invalid': !verifyCoordinates() && coordinatesField.touched}">
                                    <div class="invalid-feedback">
                                        {{t('msg_error_format_coordinate_field')}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="mb-3">
                                    <button type="button" class="btn btn-primary" (click)="searchByCoordinates()">
                                        {{t('txt_search')}}
                                    </button>
                                </div>
                            </div>
                        </div>
    
                    </ng-template>
                </li>
            </ul>    
            <div [ngbNavOutlet]="nav" class="mt-2"></div>    
            <google-map [center]="center" height="300" [options]="options" class="align-self-center" #googleMap (mapClick)="click($event)">
                <map-info-window></map-info-window>
            </google-map>
        </ng-container>
    
    </ng-container>
</ng-template>