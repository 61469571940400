import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapComponent } from 'src/app/app-commons/components/map/map.component';
import { NotFoundComponent } from 'src/app/app-commons/components/not-found/not-found.component';
import { AllowNavigationGuard } from 'src/app/security/allow-navigation.guard';
import { AuthGuardOutsideService } from 'src/app/security/auth-guard-outside.service';
import { OrganizationTypeComponent } from 'src/app/views/organization-type/organization-type.component';
import { ChangePasswordComponent } from './views/other/base/change-password/change-password.component';
import { HomeComponent } from './views/other/base/home/home.component';
import { LoginComponent } from './views/other/base/login/login.component';
import { PasswordRecoveryComponent } from './views/other/base/password-recovery/password-recovery.component';
import { EditProfileComponent } from './views/other/base/profile/edit-profile/edit-profile.component';
import { ViewProfileComponent } from './views/other/base/profile/view-profile/view-profile.component';
import { ListComponent } from './views/other/demo/list/list.component';
import { CalendarComponent } from './views/other/demo/calendar/calendar.component';
import { ConfigurationComponent } from './features/security/views/roles/configuration/configuration.component';


const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuardOutsideService] },
  { path: 'password-recovery', component: PasswordRecoveryComponent },
  { path: 'change-password/:id/:token', component: ChangePasswordComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'profile',
    children: [
      { path: '', component: ViewProfileComponent },
      { path: 'edit', component: EditProfileComponent },
    ],
  },
  {
    path: 'demo', children: [
      { path: 'base', component: ListComponent },
      { path: 'calendar', component: CalendarComponent },
    ]
  },
  // TODO: check this two routes
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'organization-type', component: OrganizationTypeComponent },
  // modules
  { path: 'administration', loadChildren: () => import('./features/administration/administration.module').then(m => m.AdministrationModule) },
  { path: 'security', loadChildren: () => import('./features/security/security.module').then(m => m.SecurityModule) },
  { path: 'posts', loadChildren: () => import('./features/posts/posts.module').then(m => m.PostsModule)},
  { path: 'interesting-places', loadChildren: () => import('./features/interesting-places/interesting-places.module').then(m => m.InterestingPlacesModule)},
  { path: 'routes', loadChildren: () => import('./features/routes/routes.module').then(m => m.RoutesModule)},
  // not found page or under construction component
  { path: '**', component: NotFoundComponent, data: { '404': true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
