<div
  ngbDropdown
  container="body"
  class="text-color-gray-800"
  placement="bottom-right"
  *ngIf="isShown"
  (openChange) = "changeDropdown($event)"
>
  <button type="button" class="btn btn-action" id="dropdownActions" ngbDropdownToggle>
    <div class="d-flex align-items-center  ">
      <app-material-icon>more_vert</app-material-icon>
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownActions" class="dropdownMenuActions" *transloco="let t">
    <ng-container *ngFor="let route of routes; let i = index;">
      <button
        ngbDropdownItem
        [routerLink]="route.commands"
        [appCheckPermission]="permission"
        [permissionType]="route.permissionType ? route.permissionType : permissionType.NONE"
      >
        {{ t(route.name) }}
      </button>
    </ng-container>

    <!-- view --->
    <button
      *ngIf="viewRoute !== undefined"
      ngbDropdownItem
      [routerLink]="viewRoute"
      [appCheckPermission]="permission"
      [permissionType]="permissionType.READ"
    >
      {{ t('view') }}
    </button>

    <!-- edit --->
    <button
      *ngIf="showEditButton"
      ngbDropdownItem
      [routerLink]="editRoute"
      [appCheckPermission]="permission"
      [permissionType]="permissionType.UPDATE"
    >
      {{ t('edit') }}
    </button>

    <!-- delete -->
    <button
      *ngIf="showDeleteButton"
      ngbDropdownItem
      (click)="onDeleteAction()"
      [appCheckPermission]="permission"
      [permissionType]="permissionType.DELETE"
    >{{ t('delete') }}
    </button>
    <ng-content>
    </ng-content>
  </div>
</div>
