import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AdmPermissionsService } from 'src/app/services/core/adm-permissions.service';
import { CurrentUserService } from 'src/app/services/others/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardOutsideService {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private permissionService: AdmPermissionsService
  ) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    if (this.currentUserService.isAuthenticated()) {
      if (this.permissionService.isNavigationAllowed('/home')) {
        let defaultPath = this.permissionService.getMyDefaultPathToNavigate();
        this.router.navigate([defaultPath]);
      } else {
        this.currentUserService.logout();
      }
    }
    return true;
  }
}
