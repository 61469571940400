import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas, NgbOffcanvasOptions, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, zip } from 'rxjs';
import {
  AdmPageAuthOpDto,
  AdmPageAuthReqDto,
  AdmPageAuthStepLogOpDto,
  AdmPageAuthStepOpDto
} from 'src/app/data/models/adm';
import { AdmPageAuthOpService } from 'src/app/services/core/adm-page-auth-op.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';
import { TypologiesEnum } from 'src/global/typologies-enum';

@Component({
  selector: 'app-approval-view',
  templateUrl: './approval-view.component.html',
  styleUrls: ['./approval-view.component.scss']
})
export class ApprovalViewComponent {
  @ViewChild('approvalView') content!: ElementRef;
  canvasRef!: NgbOffcanvasRef;

  @Input() position: NgbOffcanvasOptions['position'] = 'end';

  /**
   * hashId of the entity
   */
  @Input() set entityHashId(entityHashId: string | null) {
    if (!entityHashId) {
      return;
    }

    this._entityHashId = entityHashId;
    if (!this._hashId) {
      this.findAll();
    }
  }

  _entityHashId!: string | null;

  /**
   * Internal permission id (view or entity)
   */
  @Input() permissionId!: number | undefined;

  /**
   * hashId of the AdmPageAuthOp
   * @param hashId
   */
  @Input() set hashId(hashId: string | null) {
    if (!hashId) {
      return;
    }
    this._hashId = hashId;
    this.findAll();
  }

  _hashId!: string | null;

  /**
   * Observable that contains the http request to authorize/reject an authorization process
   * @param observable
   */
  @Input() set observable(observable: Observable<any>) {
    if (!observable)
      return;

    this.goToNextAuthorizationStep(observable);
  }

  /**
   * Output to be emitted with the information of any page authorization request and build the observable
   * for the http request to authorize/reject any authorization process
   */
  @Output() pageAuthReqEmitter = new EventEmitter<AdmPageAuthReqDto>();

  typologyEnum = TypologiesEnum;
  pageAuthOp!: AdmPageAuthOpDto;
  pageAuthOps: AdmPageAuthOpDto[] = [];

  reviewCommentary: string = '';

  constructor(
    private canvasService: NgbOffcanvas,
    private pageAuthOpService: AdmPageAuthOpService,
    private toastService: ToasterService,
    private router: Router
  ) {
  }

  open() {
    if (!this._hashId && (!this._entityHashId || !this.permissionId))
      return;

    this.canvasRef = this.canvasService.open(this.content, { position: this.position });
  }

  findAll() {
    if (this._hashId) {
      this.findMainPageAuthOp();
    } else if (this._entityHashId && this.permissionId) {
      this.findAllPageAuthOps();
    }
  }

  private findMainPageAuthOp() {
    this.pageAuthOpService.findAll({
      max: -1,
      page: 1,
      hashId: this._hashId,
      entityHashId: this._entityHashId,
      permission: this.permissionId,
      inReview: false
    })
      .subscribe({
        next: (response: HttpResponse<AdmPageAuthOpDto[]>) => {
          const authOp = response.body ?? [];
          if (authOp.length == 0) {
            return;
          }

          this.pageAuthOp = authOp[0];
          this.findPageAuthOpStepsAndLogs(
            this.pageAuthOp.hashId,
            this._entityHashId
          )
            .subscribe({
              next: (response: [HttpResponse<AdmPageAuthStepOpDto[]>, HttpResponse<AdmPageAuthStepLogOpDto[]>]) => {
                this.pageAuthOp.pageAuthSteps = response[0].body ?? [];
                this.pageAuthOp.pageAuthStepLogsOp = response[1].body ?? [];
              },
              error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
            });
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
      });
  }

  private findAllPageAuthOps() {
    this.pageAuthOpService.findAll({
      max: -1,
      page: 1,
      entityHashId: this._entityHashId,
      permission: this.permissionId,
      inReview: false,
      columnOrder: 'entryDate',
      asc: false
    })
      .subscribe({
        next: (response: HttpResponse<AdmPageAuthOpDto[]>) => {
          this.pageAuthOps = response.body ?? [];
          this.pageAuthOps.forEach((authOp: AdmPageAuthOpDto) => {
            authOp.pageAuthSteps = [];
            authOp.pageAuthStepLogsOp = [];
          });
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
      });
  }

  private findPageAuthOpStepsAndLogs(
    pageAuthHashId: string,
    entityHashId: string | null,
  ) {
    return zip(
      this.pageAuthOpService.findAllSteps({
        hashId: pageAuthHashId,
        entityHashId: entityHashId,
        tpAuthorizationStatus: TypologiesEnum.AOS_IN_REVIEW,
        pending: false,
        max: -1
      }),
      this.pageAuthOpService.findAllStepLogs({
        hashId: pageAuthHashId,
        entityHashId: entityHashId,
        max: -1
      })
    );
  }

  close() {
    this.canvasRef.close('close');
  }

  onAuthorize(tpAuthOpStatusIntId: number) {
    if (!this._entityHashId || !this._hashId) {
      return;
    }

    const step = this.pageAuthOp.pageAuthSteps.find(stp => stp.tpAuthorizationStatus.internalId == TypologiesEnum.AOS_IN_REVIEW);
    if (!step) {
      return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_no_authorization_step_pending' });
    }

    const authReqDto = new AdmPageAuthReqDto();
    authReqDto.pageAuthStepOpId = step.pageAuthStepOpId;
    authReqDto.tpAuthorizationStatus = tpAuthOpStatusIntId;
    authReqDto.pageAuthOpHashId = this._hashId;
    authReqDto.entityHashId = this._entityHashId;

    if (this.reviewCommentary && this.reviewCommentary.trim()) {
      authReqDto.reviewCommentary = this.reviewCommentary;
    }

    this.pageAuthReqEmitter.emit(authReqDto);
  }

  private goToNextAuthorizationStep(observable: Observable<any>) {
    observable.subscribe({
      next: _ => {
        this.toastService.show({ type: ToasterEnum.SUCCESS, message: 'txt_successful_authorization' });
        void this.router.navigate([this.pageAuthOp.permissionUrl]);
        setTimeout(() => {
          this.close();
        }, 1000);
      },
      error: (e: HttpErrorResponse) => {
        if (e.status == 404 && e.error == 'entity_not_found')
          return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_entity_for_authorize_not_found' });
        if (e.status == 404 && e.error == 'the_step_does_not_exist')
          return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_authorization_step_not_found' });
        if (e.status == 400 && e.error == 'invalid_authorization_request')
          return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_invalid_authorization_request' });
        if (e.status == 400 && e.error == 'invalid_step')
          return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_invalid_step' });
        if (e.status == 403 && e.error == 'no_permission_to_authorize')
          return this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_no_permission_to_authorize' });

        this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' });
      }
    });
  }

  get showAuthorizationArea(): boolean {
    return !!this._hashId
      && this.pageAuthOp
      && this.pageAuthOp.tpAuthorizationStatus.internalId == TypologiesEnum.AOS_IN_REVIEW;
  }

  onShowItem(pageAuthOp: AdmPageAuthOpDto) {
    if (pageAuthOp.pageAuthStepLogsOp.length || pageAuthOp.pageAuthSteps.length) {
      return;
    }

    this.findPageAuthOpStepsAndLogs(pageAuthOp.hashId, pageAuthOp.entityHashId)
      .subscribe({
        next: (response: [HttpResponse<AdmPageAuthStepOpDto[]>, HttpResponse<AdmPageAuthStepLogOpDto[]>]) => {
          pageAuthOp.pageAuthSteps = response[0].body ?? [];
          pageAuthOp.pageAuthStepLogsOp = response[1].body ?? [];
        },
        error: _ => this.toastService.show({ type: ToasterEnum.ERROR, message: 'msg_error_server' })
      });
  }
}
