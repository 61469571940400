import { Component, Output, EventEmitter, Input } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent {

  // toggle webcam on/off
  @Input() webcamToggle: boolean = false;

  @Input() allowCameraSwitch: boolean = true;
  @Input() width: number = 500;
  @Input() height: number = 500;
  @Input() showImgChooser: boolean = true;

  @Input() takePictureBtnTxt = "txt_take_picture"
  @Input() uploadBtnTxt = "txt_upload"
  @Input() takeAgainPictureTxt = "txt_take_picture_again"

  @Input() takePictureBtnIcon = "photo_camera"
  @Input() uploadBtnIcon = "upload"
  @Input() takeAgainBtnIcon = "restart_alt"

  public errors: WebcamInitError[] = [];
  private trigger: Subject<void> = new Subject<void>();
  public multipleWebcamsAvailable = false;


  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  public webcamImage!: WebcamImage | undefined;

  public isPictureTaken: boolean = false;

  public imageSrc!: string | undefined | ArrayBuffer;



  constructor(
    private toasterService: ToasterService) {

  }


  @Output() pictureTaken = new EventEmitter<WebcamImage>();
  @Output() webCamError = new EventEmitter<WebcamInitError>();
  @Output() galeryPicture = new EventEmitter<string | ArrayBuffer>();

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }


  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    this.webCamError.emit(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.isPictureTaken = true;
    this.webcamToggle = false;
    this.imageSrc = webcamImage.imageAsDataUrl;
    this.pictureTaken.emit(webcamImage);
  }

  public resetCamera() {
    this.isPictureTaken = false;
    this.webcamImage = undefined;
    this.webcamToggle = true;
    this.imageSrc = undefined;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  prepareUploadedImage(event: any) {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      var allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        this.toasterService.show({
          message: "msg_error_incorrect_extension_file",
          params: { "allowedExtensions": "JPEG, JPG, PNG" },
          type: ToasterEnum.ERROR
        });
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result;
        if (base64) {
          this.isPictureTaken = true;
          this.webcamToggle = false;
          this.imageSrc = base64
          this.galeryPicture.emit(this.imageSrc);
        } else {
          this.toasterService.show({
            message: "msg_error_converting_file",
            type: ToasterEnum.ERROR
          });
        }
      };
    }
  }

}
